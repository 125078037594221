const { triggerSave } = require("tinymce");

function getStatistics() {

    const initialDate = $("#filters #inputInitialDate").val();
    const finalDate = $("#filters #inputFinalDate").val();

    return new Promise((resolve, reject) => {
        window.callStatistics = $.ajax({
            method: 'POST',
            url: '/calls/statistics?initialDate=' + (initialDate != "" ? initialDate : false) + "&finalDate=" + (finalDate != "" ? finalDate : false),
            contentType: 'json',
            data: JSON.stringify(queryData),
            success: (response) => {
                const data = JSON.parse(response);

                const container = $('#numberedMetrics');
                container.find('#missedCalls .value').text(data.unanswered);
                container.find('#maxWaitTime .value').text(data.max_wait);
                container.find('#averageWaitTime .value').text(data.average_ring);
                container.find('#followupPending .value').text(data.followup_pending);
                container.find('#answeredAndReturned .value').text(data.answered_followup);
                container.find('#answeredAndReturned .answered').text(data.answered);
                container.find('#answeredAndReturned .followups').text(data.followup);
                container.find('#averageTalkTime .value').text(data.average_call);
                container.find('#maxTalkTime .value').text(data.max_call);

                resolve(true);
            },
            error: (jqXHR, status, error) => {
                reject(error.message);
            },
        });
    });
}

function getmonthlyWaitTimeInfo() {

    const initialDate = $("#filters #inputInitialDate").val();
    const finalDate = $("#filters #inputFinalDate").val();

    return new Promise((resolve, reject) => {
        window.monthlyWaitTimeInfoAjax = $.ajax({
            method: 'POST',
            url: '/calls/get_monthly_wait_time_info?initialDate=' + (initialDate != "" ? initialDate : false) + "&finalDate=" + (finalDate != "" ? finalDate : false),
            contentType: 'json',
            data: JSON.stringify(queryData),
            success: (response) => {
                const chartData = JSON.parse(response);

                window.monthlyWaitTimeInfoChart.data = {
                    labels: chartData.labels,
                    datasets: [
                        {
                            label: $("#labels #maxMonthlyWaitTime").text(),
                            data: chartData.max,
                            backgroundColor: 'rgba(43, 132, 99, 0.2)',
                            borderColor: 'rgba(43, 132, 99, 0.2)',
                            borderWidth: 1
                        },
                        {
                            label: $("#labels #averageMonthlyWaitTime").text(),
                            data: chartData.avg,
                            backgroundColor: 'rgba(43, 34, 200, 0.2)',
                            borderColor: 'rgba(43, 34, 200, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                        {
                            label: $("#labels #weightedAverageMonthlyWaitTime").text(),
                            data: chartData.wavg,
                            backgroundColor: 'rgba(200, 34, 43, 0.2)',
                            borderColor: 'rgba(200, 34, 43, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                    ],
                };

                window.monthlyWaitTimeInfoChart.update();
                resolve(true);
            },
            error: (jqXHR, status, error) => {
                reject(error.message);
            },
        });
    });
}

function getMonthlyCallVolume() {

    const initialDate = $("#filters #inputInitialDate").val();
    const finalDate = $("#filters #inputFinalDate").val();

    return new Promise((resolve, reject) => {
        window.monthlyCallNumberAjax = $.ajax({
            method: 'POST',
            url: '/calls/get_monthly_call_number_info?initialDate=' + (initialDate != "" ? initialDate : false) + "&finalDate=" + (finalDate != "" ? finalDate : false),
            contentType: 'json',
            data: JSON.stringify(queryData),
            success: (response) => {
                const chartData = JSON.parse(response);

                window.monthlyCallNumberInfoChart.data = {
                    labels: chartData.labels,
                    datasets: [
                        {
                            label: $("#labels #monthlyTotalCalls").text(),
                            data: chartData.total,
                            backgroundColor: 'rgba(43, 132, 99, 0.2)',
                            borderColor: 'rgba(43, 132, 99, 0.2)',
                            borderWidth: 1
                        },
                        {
                            label: $("#labels #monthlyFrontOfficeCalls").text(),
                            data: chartData.frontOffice,
                            backgroundColor: 'rgba(43, 34, 200, 0.2)',
                            borderColor: 'rgba(43, 34, 200, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                        {
                            label: $("#labels #monthlyGenericCalls").text(),
                            data: chartData.generic,
                            backgroundColor: 'rgba(200, 34, 43, 0.2)',
                            borderColor: 'rgba(200, 34, 43, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                        {
                            label: $("#labels #monthlyInternalCalls").text(),
                            data: chartData.internal,
                            backgroundColor: 'rgba(200, 34, 140, 0.2)',
                            borderColor: 'rgba(200, 34, 140, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                    ],
                };
                window.monthlyCallNumberInfoChart.update();

                window.monthlyLostCallNumberInfoChart.data = {
                    labels: chartData.labels,
                    datasets: [
                        {
                            label: $("#labels #monthlyTotalLostCalls").text(),
                            data: chartData.totalLost,
                            backgroundColor: 'rgba(43, 132, 99, 0.2)',
                            borderColor: 'rgba(43, 132, 99, 0.2)',
                            borderWidth: 1
                        },
                        {
                            label: $("#labels #monthlyFrontOfficeLostCalls").text(),
                            data: chartData.frontOfficeLost,
                            backgroundColor: 'rgba(43, 34, 200, 0.2)',
                            borderColor: 'rgba(43, 34, 200, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                        {
                            label: $("#labels #monthlyGenericLostCalls").text(),
                            data: chartData.genericLost,
                            backgroundColor: 'rgba(200, 34, 43, 0.2)',
                            borderColor: 'rgba(200, 34, 43, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                        {
                            label: $("#labels #monthlyInternalLostCalls").text(),
                            data: chartData.internalLost,
                            backgroundColor: 'rgba(200, 34, 140, 0.2)',
                            borderColor: 'rgba(200, 34, 140, 1)',
                            borderWidth: 1,
                            type: 'line',
                            fill: false,
                        },
                    ],
                };
                window.monthlyLostCallNumberInfoChart.update();
                resolve(true);
            },
            error: (jqXHR, status, error) => {
                reject(error.message);
            },
        });
    });
}

$(() => {

    if ($('#calls-pbx-create').length > 0) {
        $('#calls-pbx-create .show-password').on('mousedown mouseup', (evt) => {
            if ($(evt.target).parent('a').siblings('input').attr('type') === 'password') {
                $(evt.target).parent('a').siblings('input').attr('type', 'text');
            } else {
                $(evt.target).parent('a').siblings('input').attr('type', 'password');
            }
        });
    }

    if($("#calls-dashboard").length > 0) {
        let monthlyWaitTimeInfoCtx = $("#monthlyWaitTimeInfo")[0].getContext('2d');
        let monthlyCallNumberInfoCtx = $("#monthlyCallNumberInfo")[0].getContext('2d');
        let monthlyLostCallNumberInfoCtx = $("#monthlyLostCallNumberInfo")[0].getContext('2d');
        queryData = {
            inbound: false,
            dates: false,
        }

        window.monthlyWaitTimeInfoChart = new Chart(monthlyWaitTimeInfoCtx, {
            type: 'bar',
            options: {
                title: {
                    display: true,
                    text: $("#titles #minMaxExternalMonthlyWaitTime").text(),
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: (item) => {
                                return decimalSecondsToTimeValue(item);
                            }
                        }
                    }]
                }
            }
        });

        window.monthlyCallNumberInfoChart = new Chart(monthlyCallNumberInfoCtx, {
            type: 'bar',
            options: {
                title: {
                    display: true,
                    text: $("#titles #totalCallsByTypeAndMonthExcludeLost").text(),
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            // userCallback: (item) => {
                            //     return decimalSecondsToTimeValue(item);
                            // }
                        }
                    }]
                }
            }
        });

        window.monthlyLostCallNumberInfoChart = new Chart(monthlyLostCallNumberInfoCtx, {
            type: 'bar',
            options: {
                title: {
                    display: true,
                    text: $("#titles #totalLostCallsByTypeAndMonth").text(),
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            // userCallback: (item) => {
                            //     return decimalSecondsToTimeValue(item);
                            // }
                        }
                    }]
                }
            }
        });

        getStatistics().then(() => {
            //
        }).catch((msg) => {
            // alert(msg);
        });
        getMonthlyCallVolume().catch((msg) => {
            // alert(msg);
        });
        getmonthlyWaitTimeInfo().catch((msg) => {
            // alert(msg);
        });




        // $('#export a').on('click', function() {
        //     $.ajax({
        //         method: 'GET',
        //         url: $(this).attr('href'),
        //         dataType: 'json',
        //         success: function(result, status, xhr) {

        //             var disposition = xhr.getResponseHeader('content-disposition');
        //             var matches = /"([^"]*)"/.exec(disposition);
        //             var filename = (matches != null && matches[1] ? matches[1] : xhr.getResponseHeader('X-ewater-filename'));

        //             // The actual download
        //             var blob = new Blob([result], {
        //                 type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //             });
        //             var link = document.createElement('a');
        //             link.href = window.URL.createObjectURL(blob);
        //             link.download = filename;

        //             document.body.appendChild(link);

        //             link.click();
        //             document.body.removeChild(link);
        //             $('#modalSpinner').modal('hide');
        //         }
        //     });
        // });

        // Removed Refresh call Button - 20210527
        // $('#refetchCallData').on('click', (evt) => {
        //     if (!$(evt.currentTarget).attr('data-disabled')) {
        //         $.ajax({
        //             url: '/calls/refetch',
        //             success: (response) => {
        //                 response = JSON.parse(response);
        //                 if (response.status === 200) {
        //                     window.datatable_calls.draw();
        //                     getmonthlyWaitTimeInfo().catch((msg) => {
        //                         alert(msg);
        //                     });
        //                     getMonthlyCallVolume().catch((msg) => {
        //                         alert(msg);
        //                     });
        //                 } else {
        //                     alert(response.message);
        //                 }
        //                 $('#modalSpinner').modal('hide');
        //             },
        //             error: (err) => {
        //                 console.log(err);
        //                 alert(err.responseText);
        //             }
        //         });
        //     }
        // });

        $('#clearDate').on('click', (evt) => {
            // console.log(evt.currentTarget);
            if ($(evt.currentTarget).siblings('input').val() != "") {
                $(evt.currentTarget).tooltip({
                    html: true,
                });
                $(evt.currentTarget).siblings('input').val("").trigger('change');
            }
        });

        $("#filters .clear-fields").on('click', () => {
            $("#filters").find('#inputInitialDate, #inputFinalDate').val('').trigger('change');
            getStatistics().catch((msg) => {
                // alert(msg);
            });

            getmonthlyWaitTimeInfo().catch((msg) => {
                // alert(msg);
            });

            getMonthlyCallVolume().catch((msg) => {
                // alert(msg);
            });
        })

        $('#filters #send').on('click', delay((evt) => {
            value = $(evt.target).val();

            if($(evt.target).is($("#filters input#inputInitialDate"))) {
                $('#exportForm input[name="start_date"]').val(value);
            } else if ($(evt.target).is($("#filters input#inputFinalDate"))) {
                $('#exportForm input[name="end_date"]').val(value);
            }
            if (window.clearCacheAjax && window.clearCacheAjax.readyState !== 4) {
                window.clearCacheAjax.abort();
            }

            window.clearCacheAjax = $.ajax({
                url: '/calls/clear-cache',
                method: 'POST',
                success: (response) => {
                    console.log(response);

                    $("#datatable-calls").DataTable().draw();

                    getStatistics().then(() => {
                        //
                    }).catch((msg) => {
                        // alert(msg);
                    });

                    getMonthlyCallVolume().catch((msg) => {
                        // alert(msg);
                    });

                    getmonthlyWaitTimeInfo().catch((msg) => {
                        // alert(msg);
                    });

                },
            })
        }, 0));

        // Removed Call refresh state check - 20210527
        // let updating = false;

        // const cdri = setInterval(() => {
        //     // console.log(updating);
        //     $.ajax({
        //         url: 'check-call-record-update-state',
        //         method: 'GET',
        //         dataType: 'json',
        //         headers: {
        //             'Access-Control-Allow-Origin': 'localhost'
        //         },
        //         success: (response) => {
        //             // console.log(response);
        //             if (parseInt(response.updating) === 1) {
        //                 $('#refetchCallData').addClass('spining').attr('data-disabled', true)
        //                 if (parseInt(response.current) !== 0) {
        //                     $('#cdrUpdateState').removeClass('d-none').addClass('d-inline');
        //                     $('#cdrUpdateState .current').text(response.current);
        //                     $('#cdrUpdateState .total').text(response.total);
        //                 }

        //                 updating = true;
        //             } else {
        //                 $('#cdrUpdateState').addClass('d-none').removeClass('d-inline');
        //                 $('#refetchCallData').removeClass('disabled spining').removeAttr('data-disabled').tooltip('dispose');

        //                 if (updating) {
        //                     window.datatable_calls.draw();
        //                     getmonthlyWaitTimeInfo().catch((msg) => {
        //                         alert(msg);
        //                     });
        //                     getMonthlyCallVolume().catch((msg) => {
        //                         alert(msg);
        //                     });
        //                     updating = false;
        //                 }
        //             }
        //         },
        //         error: (error) => {
        //             clearInterval(cdri)
        //         }
        //     });
        // }, 2000);

    }
});
