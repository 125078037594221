$( () => {

    if($('#sender').length > 0){

        const newtoken = document.getElementById('newToken')
        const token = document.getElementById('token')
        const tokenfieald = document.getElementById('tokenfieald')

        $('#newTokenckeked').on('change', function(event){
            event.preventDefault()
            if(this.checked) {
                if(tokenfieald.style.display == "block"){
                    tokenfieald.style.display = "none"
                    token.value = ""
                }
                $('#newToken').removeAttr('disabled');
                $("#newToken").attr("required", "true");
            }

            if(!this.checked) {
                if(tokenfieald.style.display == "none"){
                    tokenfieald.style.display = "block"
                }
                $('#newToken').attr('disabled','disabled');
            }
        })


        $('#register').on('click', function(event){

            //const dataForm = new FormData()
            const dataForm = $('#registersender').serializeArray();

            var payload = {};
            event.preventDefault()

            $.each(dataForm, function() {
                payload[this.name] = this.value;
            });

            const data = {
                senderDesignation: payload.senderDesignation,
                token: payload.token,
                newTokenckeked: payload.newTokenckeked,
                newToken: payload.newToken,
                team: payload.team
            };

            $.ajax({
                url: '/sms/addsender',
                type: "POST",
                data: data,

                success: function(success){
                    //console.log(success)
                },
                error(){

                }
            })
        })
    }
})
