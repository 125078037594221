$(() => {
    if ($("#datatable-revisions").length > 0) {
        window.datatable_revisions = $("#datatable-revisions").DataTable({
            responsive: true,
            searching: true,
            order: [[ 2, "desc" ]],
            columnDefs: [
                {
                    targets: 'sorting-disabled',
                    orderable: false,
                },
                {
                    targets: 'text-center',
                    className: "text-center",
                },
                {
                    targets: 'limit-w-35',
                    width: '35%',
                },
                {
                    targets: 'limit-w-45',
                    width: '45%',
                },
                {
                    targets: 'limit-w-15',
                    width: '15%',
                },
                {
                    targets: 'invisible',
                    visible: false,
                },
            ],
            lengthChange: true,
            language: {
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                },
                sProcessing: loadingHTML,
                sEmptyTable: "No Records",
                url: "/config/dataTables/lang/" + window.lang + ".json"
            },
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: $("#datatable-revisions").data('url'),
            // lengthMenu: [[10, 50, 100], [10, 50, 100]],
            // displayLength: 10,
            // pagingType: 'simple',
            columns: [
                {data: 'actions', name: 'actions', class: 'actions text-center px-0 sorting-disabled', searchable: false, sortable: false},
                {data: 'created_by', name: 'created_by', searchable: true},
                {data: 'created_at', name: 'created_at', searchable: true},
            ],
            drawCallback: function(settings){
                var data = this.api().ajax.json();
            },
        });
    }

    $('#modalRevisionData').on('show.bs.modal', (event) => {
        let dataId = '';

        console.log($(event.relatedTarget).data('id'));

        if (typeof $(event.relatedTarget).data('id') !== 'undefined') {
            dataId = $(event.relatedTarget).data('id');
        }

        $(event.target).find('#content .body').html("");
        $(event.target).find('#modal-spinner').removeClass('d-none');

        $currAjax = $.ajax({
            method: 'POST',
            url: $(event.target).data('url'),
            data: JSON.stringify({ id: dataId }),
            contentType: 'json',
            success: (response) => {
                response = JSON.parse(response);
                $(event.target).find('#content .body').html(response.content);
                $(event.target).find('#modal-spinner').addClass('d-none');
            },
            error: (jqXHR, status, error) => {
                $(event.target).find('#modal-spinner').addClass('d-none');
                alert(error);
            },
            complete: () => {
                $(event.target).find('#modal-spinner').addClass('d-none');
            },
        });
    });
});
