$( () => {
    if($('#materialForms').length > 0){
        let aux = 0;

        const materialsID = new Array();
        const arrayForms = new Array();
        const functions = require('./functions');

        function handleWithSubvalues(){
            const vehicleValues = document.querySelectorAll('[name="inputMaterialValue[]"]');
            const subtotalValue = document.getElementById('materialSubTotalvalue')

            let total = 0;
            vehicleValues.forEach((input) => {
                if(Number(input.value)){
                    total += Number(input.value)
                }
            })

            subtotalValue.innerHTML = total
            const materialSutotal = document.getElementById('inputMaterialSutotal')
            materialSutotal.value = total.toFixed(2)
        }


        $('#inputMaterialsDescription').on('change', (event) => {
            event.preventDefault();
            aux += 1

            const materialForm = new Array();

            const description = $('#inputMaterialsDescription option:selected').text()
            const descriptionID = document.getElementById('inputMaterialsDescription').value;

            $.ajax({
                url:`/budgets/subitem/${descriptionID}`,
                type: 'GET',
                enctype: 'json',
                success: (success) => {
                    if(success){
                            const template = document.getElementById('materialsTemplate').innerHTML;

                            const iconRemove = `<div class="col-md-12 mb-1 mt-2"><span class="float-right text-danger" title="Remover"><i class="fas fa-minus-circle removeMaterial" id="${aux}" style="cursor:pointer"></i></span></div>`

                            const inputDescription = `  <div class="col-md-12 mb-3">
                                                            <input type="text" class="form-control" value="${description}" disabled>
                                                            <input hidden type="number" name="inputMaterialID[]" value="${descriptionID}" id="inputMaterialID${aux}" class="form-control">
                                                        </div>
                                                    `

                        $('#materialTitle').innerHTML = inputDescription

                        const idIsIncluded = materialsID.includes(descriptionID);

                        idIsIncluded == false ? materialsID.push(descriptionID) : ''

                        idIsIncluded == true ? materialForm : materialForm.push(`<div class="row newWitnessForm mb-3" id="materialForm${aux}">${iconRemove + inputDescription + template}</div>`)
                        idIsIncluded == true ? arrayForms : arrayForms.push(`<div class="row newWitnessForm mb-3" id="materialForm${aux}">${iconRemove + inputDescription + template}</div>`)

                        $('#materiaSelected').append(materialForm)

                        $("#inputMaterialsDescription").val("");

                        $(`#materialForm${aux} .col-md-3 #inputMaterialQuantity`).val('1')
                        $(`#materialForm${aux} .col-md-3 #inputMaterialPrice`).val(success.subitem_amount)
                        $(`#materialForm${aux} .col-md-3 #materialPrice`).val(success.subitem_amount)

                        const percent = $(`#materialForm${aux} .col-md-3 #materialMargin`).val()
                        const margin = success.subitem_amount * (percent/100)
                        const totalValue =  success.subitem_amount + margin

                        $(`#materialForm${aux} .col-md-3 #inputMaterialMargin`).val(margin.toFixed(2))
                        $(`#materialForm${aux} .col-md-3 #inputMaterialValue`).val(totalValue.toFixed(2))

                        $(`#materialForm${aux} .col-md-12 #subitemMaterial`).val(Number(success.id))
                        $(`#materialForm${aux} .col-md-3 #disabledMaterialMargin`).val(margin.toFixed(2))
                        $(`#materialForm${aux} .col-md-3 #materialValue`).val(margin.toFixed(2))

                        handleWithSubvalues();
                        functions.handleWithTotalValues();

                        $("#inputDescriptionLoss").val("");

                        document.getElementById('NoMateriaSelected').style.display = 'none';
                    }
                },
                error: (xhr) => { }
            })
        })


        document.addEventListener('click', (event) => {
            if(event.target.matches('.removeMaterial')){
                const formItemID = event.target.id
                const descriptionIdToRemove = document.getElementById(`inputMaterialID${formItemID}`).value

                var indexToRemove = materialsID.indexOf(descriptionIdToRemove);
                if (indexToRemove !== -1) {
                    materialsID.splice(indexToRemove, 1);
                }

                document.getElementById(`materialForm${formItemID}`).remove()
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        })


        //UPDATE MATERIALS SUBVALUE MATERIAL QUALTITY WHEN INPUT MATERIALS QUANTITY IS CHANGING
        document.addEventListener('change', (event) => {
        if (event.target.matches('#inputMaterialQuantity')) {
                const materialQuantity = event.target.value;
                const rowID = event.target.parentElement.parentElement.id;

                const unitPrice = $(`#${rowID} .col-md-3 #inputMaterialPrice`).val()

                const percentMargin = $(`#${rowID} .col-md-3 #materialMargin`).val()
                const margin =  (Number(materialQuantity) * Number(unitPrice)) * (Number(percentMargin)/100)

                const setInputValue = (Number(materialQuantity) * Number(unitPrice)) + Number(margin)
                $(`#${rowID} .col-md-3 #inputMaterialValue`).val(setInputValue.toFixed(2))

                $(`#${rowID} .col-md-3 #inputMaterialMargin`).val(Number(margin.toFixed(2)))

                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        });
    }
})
