//const { search } = require("fontawesome");

$(() => {
    if($('#customerData').length > 0){
        $('#load').click((event) => {

            event.preventDefault()

            const formData = new FormData()

            const customerData =  document.getElementById('customerdatafile');
            const feedback = document.getElementById('feedback');
            const loading = document.getElementById('loading');
            const newClientData = document.getElementById("newUploadFile")
            const loadClientData = document.getElementById("load")
            var getSuccess = ""

            loading.style.display = "block"
            formData.append('customerdatafile', customerData.files[0])

            loadClientData.disabled = true;

            $.ajax({
                url: '/sms/loadcustomerdata',
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                success: function(success){

                    getSuccess = success
                    customerData.value=''

                    if(success[0] == "Total Success" && feedback.style.display === "none"){
            /*             if(feedback.classList.contains('alert-success'))
                            feedback.classList.toggle('alert-danger') */
                        feedback.style.display = "block"
                        loading.style.display = "none"
                        $('#successeToregisted').html(success[1])
                        $('#iconFeedback').html('<i class="fas fa-times-circle"></i>')
                        newClientData.style.display = "block"
                    }

                    if(success[0] == 0 && feedback.style.display === "none"){
                        if(feedback.classList.contains('alert-success'))
                            feedback.classList.toggle('alert-danger')
                        feedback.style.display = "block"
                        loading.style.display = "none"
                        $('#successeToregisted').html(success[1])
                        $('#iconFeedback').html('<i class="fas fa-times-circle"></i>')
                        newClientData.style.display = "block"
                    }

                    if(feedback.style.display === "none"){
                        if(feedback.classList.contains('alert-success'))
                            feedback.classList.toggle('alert-warning')
                        feedback.style.display = "block"
                        loading.style.display = "none"
                        $('#successeToregisted').html(success)
                        $('#iconFeedback').html('<i class="fas fa-exclamation-triangle"></i>')
                        newClientData.style.display = "block"
                    }
                },
                error: function(xhr, status, error){

                    if(JSON.parse(xhr.responseText).message && feedback.style.display === "none"){
                        if(feedback.classList.contains('alert-success'))
                            feedback.classList.toggle('alert-danger')
                        feedback.style.display = "block"
                        loading.style.display = "none"
                        //$('#successeToregisted').html(xhr);
                        $('#successeToregisted').html(JSON.parse(xhr.responseText).message)
                        $('#iconFeedback').html('<i class="fas fa-times-circle"></i>')
                        newClientData.style.display = "block"

                    }
                }
            })

        /*     $('#closeAlert').click( (event) =>{
                location.reload()
            }) */

            $("#yes_Send").click((event) =>{
                event.preventDefault();
                location.reload()
            })

            $("#not_send").click((event) =>{
                event.preventDefault();
                window.location.href = "/sms/listsms";
            })
        })
    }
})
