$(()=>{
    $("#assignementStart").on('click', function (e) {
        e.preventDefault()

        vehiclID = document.getElementById('vehicleID');

        alert(vehicleID.value)

        $.ajax({
            url: `/fleets/drivers/activities`,
            type: 'POST',
            data: { vehicleID: vehicleID.value },
            enctype: 'JSON',
            success: (data) => {

                if(data == 'success'){
                    location.reload()
                    console.log(data);
                }

            },
            error: (xhr) => {
                console.log(xhr);
            }
        })
    })

    $(document).on('click', '#assignementEnd', function(event){
        event.preventDefault();
        alert('Teste')

        $.ajax({
            url: `/fleets/drivers/activities`,
            type: 'POST',
            success: (data) => {
                location.reload()
            },
            error: (xhr) => {
                console.log(xhr);
            }
        })
    })
})
