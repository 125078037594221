$( () => {
    if($('#sinisterForm').length > 0){

        drivers = document.getElementById('inputDriver');

        $('#inputVehicle').on('change', (event) => {
            event.preventDefault();

            vehicle = document.getElementById('inputVehicle');
            sinisterDate = document.getElementById('inputSinisterDate');

            $.ajax({
                url: '/fleets/vehicleDriver/vehicleDrivers',
                data: { vehicleID: vehicle.value/* , givenDate: sinisterDate.value  */},
                type: 'GET',
                contentType: 'json',
                success: (data) => {

                    var options = '<option value="">Selecionar</option>';

                    data.length > 1 || data.length < 1 ? data.push({id: '', name: 'Não encontrado' }) : data

                    data.forEach( (item) => {
                        options += '<option value="' + item.id + '">' + item.name + '</option>';
                    })

                    $('#inputUserID').html(options);
                },
                error: (xhr) => {
                    //console.log(xhr);
                }
            })
        })
    }


    if($('#editSinister').length > 0 || $('#sinisterForm').length > 0){

        $("#inputSinisterLost").on('change', function(event){

            event.preventDefault()

            const vehicleID = $('#vehicleID option:selected').val()
            const registration = $('#vehicleID option:selected').text()
            const totalyLost = $('#inputSinisterLost option:selected').text()

            if(totalyLost == 'Sim'){
                $.ajax({
                    url: `/fleets/vehicle/getvehicleByID/${vehicleID}`,
                    type: 'GET',
                    success:function(data){

                        const alert = data.length > 0 ?  true : false
                        if(alert){
                            if(data[0].gps_removed == 0 && data[0].gps == 1){
                                var alertcontent = `O Veículo com a matrícula <strong> ${ registration } </strong> tem dispositivo de geolocalização instalado. Tem de ser removido antes do abate</strong>.`
                                $('#contentBody').html(alertcontent)
                                $("#alertGPDModal").modal("show");
                            }
                        }
                    },
                    error: function(xhr){
                        //
                    }
                })
            }
        })
    }
})
