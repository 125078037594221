$( () => {
    if($('#vehicleFuelcardt').length > 0){
        $('#showLessFuelCards').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessFuelCards').style.display = 'none';
                document.getElementById('showMoreFuelCards').style.display = 'block';
                document.getElementById('fuelCardsContent').style.display = 'none';
            }, 100)
        })

        $('#showMoreFuelCards').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessFuelCards').style.display = 'block';
                document.getElementById('showMoreFuelCards').style.display = 'none';
                document.getElementById('fuelCardsContent').style.display = 'block';
            }, 100)
        })
    }


    if($('#historic').length > 0){
        if($('#showFuelcardHistory').is(':checked')){
            setTimeout( () => {
                document.getElementById('fuelcardHistory').style.display = 'block';
            }, 100)
        }
    }

    $('#showFuelcardHistory').on('change', (event) => {
        event.preventDefault()
        if($('#showFuelcardHistory').is(':checked')){
            document.getElementById('fuelcardHistory').style.display = 'block';
        } else{
            document.getElementById('fuelcardHistory').style.display = 'none';
        }
    })
})
