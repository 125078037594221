$(document).ready(function () {
    datatable_invoices = $('#datatable-invoices').DataTable({

        responsive: true,
        order: [[3, 'desc']],
        searching: false,
        language: {
            paginate: {
                previous: '<i class="fa fa-angle-left"></i>',
                next: '<i class="fa fa-angle-right"></i>'
            },
            sProcessing: loadingHTML,
            sEmptyTable: "No Records",
            url: "/config/dataTables/lang/" + window.lang + ".json"
        } ,
        autoWidth: false,
        processing: true,
        serverSide: true,
        ajax: {
            url: '/sms/listsms',
            columns: [
                { data: null, class: 'actions text-center px-0 sorting_disabled', searchable: false, orderable: true},
                { data: 'code_contract', name: 'code_contract', searchable: false, sortable: false },
                { data: 'recipient_phone', name: 'recipient_phone', sortable: false },
                { data: 'shipping_status', name: 'shipping_status', searchable: false, sortable: false },
                { data: 'sender', name: 'sender', searchable: true },
                { data: 'send_date', name: 'send_date', searchable: true, sortable: false}
            ],
            columnDefs: [ {
                targets: 0,
                data: null,
                defaultContent: '<span><a class="text-info edit" href=""><i class="fas fa-edit"></i></a></span>'
              }]
        },
    });

    $('#code_contract').on('input', function () {
        datatable_invoices.ajax.url('/sms/listsms?code_contract=' + this.value).load();
    });

    $('#recipient_phone').on('input', function () {
        datatable_invoices.ajax.url('/sms/listsms?recipient_phone=' + this.value).load();
    });

    $('#shipping_status').on('change', function () {
        datatable_invoices.ajax.url('/sms/listsms?shipping_status=' + this.value).load();
    });
/*
    $('#statusmimo').on('change', function () {
        datatable_invoices.ajax.url('/sms/listsms?statusmimo=' + this.value).load();
    }); */

    $('#sender').on('change', function () {
        datatable_invoices.ajax.url('/sms/listsms?sender=' + this.value).load();
    });

    $('#send_date').on('change', function () {
        datatable_invoices.ajax.url('/sms/listsms?send_date=' + this.value).load();
    });
});

