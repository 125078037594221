const { map } = require("jquery");

function getanalysisQA(supplyzone_id) {
    return new Promise((resolve, reject) => {
        window.monthlyCallNumberAjax = $.ajax({
            method: 'POST',
            url: '/interfaceqapublico/' + supplyzone_id,
            contentType: 'json',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: JSON.stringify(queryData),
            success: (response) => {
                chartData = JSON.parse(response);
                const datasets = [];
                const colors = [];
                const labels = chartData.labels;
                const totals = chartData.total;
                delete(chartData.labels)
                delete(chartData.total)


                console.log('Chart Data: ', chartData);
                for (team in chartData) {
                    let color = null;

                    do {
                        color = getRandomColor();
                    } while(colors.includes(color));

                    colors.push(color);

                    datasets.push({
                        fill: false,
                        type: 'line',
                        borderWidth: 1,
                        backgroundColor: color,
                        borderColor: color,
                        label: team,
                        data: chartData[team],
                    });
                }

                color = getRandomColor();
                datasets.push({
                    fill: true,
                    type: 'line',
                    borderWidth: 1,
                    backgroundColor: 'rgba(60, 60, 60, 0.2)',
                    borderColor: 'rgba(60, 60, 60, 1)',
                    label: 'Total por mês',
                    data: totals,
                });


                window.analysisQAChart.data = {
                    labels: labels,
                    datasets: datasets
                };
                window.analysisQAChart.update();
                resolve(true);
            },
            error: (jqXHR, status, error) => {
                reject(error);
            },
        });
    });
}

$(() => {
    let loadingHTML = '<div class="loading-cpage">'+
                          '<svg id="load" x="0px" y="0px" viewBox="0 0 150 150">'+
                              '<circle id="loading-inner" cx="75" cy="75" r="60"/>'+
                          '</svg>'+
                      '</div>';

    if ($("#datatable-waterqualitylog-list").length > 0) {
        window.datatable_waterqualitylog_list = $("#datatable-waterqualitylog-list").DataTable({
            responsive: true,
            searching: true,
            order: [[ 1, "desc" ]],
            columnDefs: [
                {
                    targets: 'sorting-disabled',
                    orderable: false,
                },
                {
                    targets: 'text-center',
                    className: "text-center",
                },
                {
                    targets: 'limit-w-35',
                    width: '35%',
                },
                {
                    targets: 'limit-w-45',
                    width: '45%',
                },
                {
                    targets: 'limit-w-15',
                    width: '15%',
                },
                {
                    targets: 'invisible',
                    visible: false,
                },
            ],
            lengthChange: true,
            language: {
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                },
                sProcessing: loadingHTML,
                sEmptyTable: "No Records",
                url: "/config/dataTables/lang/" + window.lang + ".json"
            },
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: window.location.pathname,
            // lengthMenu: [[10, 50, 100], [10, 50, 100]],
            // displayLength: 10,
            // pagingType: 'simple',
            columns: [
                {data: 'actions',name: 'actions', class: 'actions text-center px-0 sorting-disabled', searchable: false, sortable: false},
                {data: 'id',name: 'id', searchable: true},
                {data: 'date', name: 'date', searchable: true},
                {data: 'comment', name: 'comment', searchable: true},
                {data: 'scheduled', name: 'scheduled', searchable: false, class: 'sorting-disabled text-center'},
            ],
            drawCallback: function(settings){
                var data = this.api().ajax.json();
            },
            fnRowCallback: ( nRow, aData, iDisplayIndex, iDisplayIndexFull ) => {
                if (aData.trashed) {
                    $('td', nRow).css('opacity', '0.8').css('background-color', '#ff717136');
                }
            },
        });
    }

   /*  //draw the analysisQA chart

    if ($('#analysis-dashboard').length > 0) {
        let analysisQACtx = $("#analysisQA")[0].getContext('2d');
        let supplyzone_id = $("#inputWaterSupplyZone").val();

        queryData = {
            inbound: false,
            dates: false,
        }

        window.analysisQAChart = new Chart(analysisQACtx, {
            type: 'line',
            options: {
                title: {
                    display: true,
                    text: $("#titles #analysisQA").text(),
                },
            }
        });

        getanalysisQA(supplyzone_id).catch((msg) => {
            alert(msg);
        });
    } */

    if($("#water-quality-log-create").length > 0){
        $("#inputDelegation").on("change", function () {
            let delegation_id = $(this).val();

            $.ajax({
                url: "/delegations/supply-zones/" + delegation_id,
                method: "get",
                success: function (data) {
                    $("#inputWaterSupplyZone").html(data.content);

                }
            })

        })
    }

    if($("#watersupplyzone_create").length > 0){
        $("#inputDelegation").on("change", function () {
            let delegation_id = $(this).val();

            $.ajax({
                url: "/delegations/supply-zones/" + delegation_id,
                method: "get",
                success: function (data) {
                    $("#inputWaterSupplyZone").html(data.content);

                }
            })

            $.ajax({
                url: "/delegations/localities/" + delegation_id,
                method: "get",
                success: function (data) {
                    $("#inputLocalities").html(data.content);

                }
            })
        })
    }

    if($("#water-quality-interface-qa").length > 0){
        $("#inputDelegation").on("change", function () {

            let delegation_id = $(this).val();

            $.ajax({
                url: "/delegations/localities/" + delegation_id,
                method: "get",
                success: function (data) {
                    $("#inputLocalities").html(data.content);
                }
            })
        })
    }

    if($("#cmbraganca-analysis-dashboard").length > 0){
        $("#drawNoticesURLs").on("load", function () {

            let watersupplyzone_id = $("#inputWaterSupplyZone").val();

            $.ajax({
                url: "cmbraganca/getnotices/" + watersupplyzone_id,
                method: "get",
                success: function (data) {
                    $("#drawNoticesURLs").html(data);
                }
            })
        })
        $("#inputWaterSupplyZone").on("change", function () {

            let watersupplyzone_id = $(this).val();

            $.ajax({
                url: "cmbraganca/getnotices/" + watersupplyzone_id,
                method: "get",
                success: function (data) {
                    $("#drawNoticesURLs").html(data);
                    mymap.flyTo(new L.LatLng($('#wsz_lat').html(),$('#wsz_long').html()), 13);
                }
            })
        })
    }

    if($("#vinhais-analysis-dashboard").length > 0){
        $("#drawNoticesURLs").on("load", function () {

            let watersupplyzone_id = $("#inputWaterSupplyZone").val();

            $.ajax({
                url: "/vinhais/getnotices/" + watersupplyzone_id,
                method: "get",
                success: function (data) {
                    $("#drawNoticesURLs").html(data);
                }
            })
        })
        $("#inputWaterSupplyZone").on("change", function () {

            let watersupplyzone_id = $(this).val();

            $.ajax({
                url: "/vinhais/getnotices/" + watersupplyzone_id,
                method: "get",
                success: function (data) {
                    $("#drawNoticesURLs").html(data);
                    mymap.flyTo(new L.LatLng($('#wsz_lat').html(),$('#wsz_long').html()), 13);
                }
            })
        })
    }

    if($("#updatesupplyzones").length > 0){



        $("#inputDelegation").on("change", function () {

            let delegation_id = $(this).val();

            $.ajax({
                url: "/delegations/localities/" + delegation_id,
                method: "get",
                success: function (data) {
                    $("#inputLocalities").find('option').remove();
                    $("#inputLocalities").html(data['content']);

                    // mymap.flyTo(new L.LatLng($('#wsz_lat').html(),$('#wsz_long').html()), 13);
                }
            })
        });
    }

    if ($("#dragndrop").length > 0) {
        // // If you are using JavaScript/ECMAScript modules:
        // import Dropzone from "dropzone";

        // // If you are using CommonJS modules:
        // const { Dropzone } = require("dropzone");

        // If you are using an older version than Dropzone 6.0.0,
        // then you need to disabled the autoDiscover behaviour here:
        //Dropzone.autoDiscover = false;

        let myDropzone = new Dropzone("#dropzone");
        myDropzone.on("addedfile", file => {
        console.log(`File added: ${file.name}`);
        });
    };

});

