const { data } = require("jquery");

$( () => {
    $.fn.dataTable.ext.errMode = 'none';
    $('#tdatatable-fleet-alerts').on('error.dt', function(e, settings, techNote, message) { console.log( 'An error occurred: ', message); });
    $('#datatable-fleet-alerts').DataTable({
        autoWidth: true,
        responsive: true,
        // order: [[3, 'asc']],
        searching: true,
        language: {
            paginate: {
                previous: '<i class="fa fa-angle-left"></i>',
                next: '<i class="fa fa-angle-right"></i>'
            },
            sProcessing: loadingHTML,
            sEmptyTable: "No Records",
            url: "/config/dataTables/lang/" + window.lang + ".json"
        } ,
        autoWidth: false,
        processing: true,
        serverSide: true,
        ajax: {
            url: "fleets/alerts",
            //dataType: "json",

            // success: function(response) {
            //     var tableHeaders;
            //     $.each(response.dynamic_columns, function(i, val){
            //         tableHeaders += "<th>" + val + "</th>";
            //     });

            //     //$("#datatable-maintenance-costs").empty();
            //     //$("#datatable-maintenance-costs").append('<table id="teste" class="display" cellspacing="0" width="100%"><thead><tr>' + tableHeaders + '</tr></thead></table>');
            //     //$("#tableDiv").find("table thead tr").append(tableHeaders);

            //     //$('#datatable-maintenance-costs').dataTable(response);
            //     //console.log(response.dynamic_columns);
            // },

        }
    });
    $('#entities_columns').on('change', function() {
        //alert('ola');
        var aac = $("#entities_columns").val();
        var bac = $("#report_columns").val();
        //alert(bac);
        // var tableHeaders;


        //$("#datatable-maintenance-costs").empty();
        //$("#datatable-maintenance-costs").append('<table id="teste" class="display" cellspacing="0" width="100%"><thead><tr>' + tableHeaders + '</tr></thead></table>');
        //$("#tableDiv").find("table thead tr").empty();
        document.getElementById("datatable-maintenance-costs").deleteTHead();
        var tableHeaders = document.getElementById("datatable-maintenance-costs").createTHead();
        var headerRow = tableHeaders.insertRow(0);
        headerRow.classList.add("thead-light");
        var columns = "";

        var a = document.getElementById("entities_columns");
        $.each(aac, function(i, val){
            // var cell = headerRow.insertCell(0);
            // cell.innerHTML = "" + a.options[val].text;
            columns = columns + "<th class=\"sorting\" tabindex=\"0\" aria-controls=\"datatable-maintenance-costs\" rowspan=\"1\" colspan=\"1\" aria-sort=\"ascending\" aria-label=\": Ordenar colunas de forma descendente\">" + a.options[val].text + "</th>";
        });

        var e = document.getElementById("report_columns");
        $.each(bac, function(i, val){
            //var cell = headerRow.insertCell(0);
            //cell.classname = 'sorting';
            //cell.innerHTML = "" + e.options[val].text;
            columns = columns + "<th class=\"sorting\" tabindex=\"0\" aria-controls=\"datatable-maintenance-costs\" rowspan=\"1\" colspan=\"1\" aria-sort=\"ascending\" aria-label=\": Ordenar colunas de forma descendente\">" + e.options[val].text + "</th>";
        });

        headerRow.outerHTML = columns;



        $("#tableDiv").find("table thead tr").append(tableHeaders);
        $('#datatable-maintenance-costs').DataTable().draw();
    });

    $('#report_columns').on('change', function() {
        //alert('ola');
        var aac = $("#entities_columns").val();
        var bac = $("#report_columns").val();
        //alert(bac);
        // var tableHeaders;


        //$("#datatable-maintenance-costs").empty();
        //$("#datatable-maintenance-costs").append('<table id="teste" class="display" cellspacing="0" width="100%"><thead><tr>' + tableHeaders + '</tr></thead></table>');
        //$("#tableDiv").find("table thead tr").empty();
        document.getElementById("datatable-maintenance-costs").deleteTHead();
        var tableHeaders = document.getElementById("datatable-maintenance-costs").createTHead();
        var headerRow = tableHeaders.insertRow(0);
        headerRow.classList.add("thead-light");
        var columns = "";

        var a = document.getElementById("entities_columns");
        $.each(aac, function(i, val){
            // var cell = headerRow.insertCell(0);
            // cell.innerHTML = "" + a.options[val].text;
            columns = columns + "<th class=\"sorting\" tabindex=\"0\" aria-controls=\"datatable-maintenance-costs\" rowspan=\"1\" colspan=\"1\" aria-sort=\"ascending\" aria-label=\": Ordenar colunas de forma descendente\">" + a.options[val].text + "</th>";
        });

        var e = document.getElementById("report_columns");
        $.each(bac, function(i, val){
            //var cell = headerRow.insertCell(0);
            //cell.classname = 'sorting';
            //cell.innerHTML = "" + e.options[val].text;
            columns = columns + "<th class=\"sorting\" tabindex=\"0\" aria-controls=\"datatable-maintenance-costs\" rowspan=\"1\" colspan=\"1\" aria-sort=\"ascending\" aria-label=\": Ordenar colunas de forma descendente\">" + e.options[val].text + "</th>";
        });

        headerRow.outerHTML = columns;

        $("#tableDiv").find("table thead tr").append(tableHeaders);
        $('#datatable-maintenance-costs').DataTable().draw();
    });

    $('#datatable-maintenance-costs').DataTable({
        autoWidth: true,
        responsive: true,
        // order: [[3, 'asc']],
        searching: true,
        language: {
            paginate: {
                previous: '<i class="fa fa-angle-left"></i>',
                next: '<i class="fa fa-angle-right"></i>'
            },
            sProcessing: loadingHTML,
            sEmptyTable: "No Records",
            url: "/config/dataTables/lang/" + window.lang + ".json"
        } ,
        autoWidth: false,
        processing: true,
        serverSide: true,
        ajax: {
            url: document.URL,
            data: {
                report_columns: function(){
                    var aac = $("#entities_columns").val();
                    var bac = $("#report_columns").val();
                    var columns = [];

                    a = document.getElementById("entities_columns");
                    $.each(aac, function(i, option){
                        columns.push(a.options[option].text);
                    });

                    e = document.getElementById("report_columns");
                    $.each(bac, function(i, option){
                        columns.push(e.options[option].text);
                    });
                    console.log(columns);
                    return JSON.stringify(columns);
                },
                initial_date: function(){
                    var e = $("#initial_date").val();
                    return e;
                },
                final_date: function(){
                    var e = $("#final_date").val();
                    return e;
                }
              },
            //dataType: "json",

            // success: function(response) {
            //     var tableHeaders;
            //     $.each(response.dynamic_columns, function(i, val){
            //         tableHeaders += "<th>" + val + "</th>";
            //     });

            //     //$("#datatable-maintenance-costs").empty();
            //     //$("#datatable-maintenance-costs").append('<table id="teste" class="display" cellspacing="0" width="100%"><thead><tr>' + tableHeaders + '</tr></thead></table>');
            //     //$("#tableDiv").find("table thead tr").append(tableHeaders);

            //     //$('#datatable-maintenance-costs').dataTable(response);
            //     //console.log(response.dynamic_columns);
            // },

        }
    });

    $('div[id="datepicker"]').datepicker({
        format: "yyyy-mm",
        viewMode: "months",
        minViewMode: "months",
        startDate: "today",
    });

})





