
$( () => {
    if($('#formIntervanor').length > 0){
        $('#inputFulname').on('blur', (event) => {
            event.preventDefault()
            const intervenorName = document.getElementById('inputFulname').value

            $.ajax({
                url: '/ilicits/intervenors/getByname/',
                type: 'GET',
                data: {intervenorName: intervenorName},
                enctype: 'json',
                success: (success) => {
                    console.log(success);
                   if(success){
                        document.getElementById('inputFulnameError').innerHTML = `O interveniente já existe.`
                        document.getElementById('saveIntervanor').disabled = true
                    } else {
                        document.getElementById('inputFulnameError').innerHTML = ''
                        document.getElementById('saveIntervanor').disabled = false
                    }
                },
                error: (xhr) => {

                }
            })

        })
    }


    $(document).on("click", '#intervenorForm', function(event){
        event.preventDefault()
        $.ajax({
            url:`/ilicits/intervenors/getAll`,
            type: 'GET',
            enctype: 'json',
            success: (success) => {
                if (success) {
                    let option = "<option value=''>Selecionar</option>";
                    Object.values(success).forEach((element) => {
                        option += `<option value=${element.id}>${element.fullname}</option>`
                    })

                    $('#intervenorsList').html(option)

                } else {
                    //...
                }
            },
            error: (xhr) => {

            }
        })
    })


    let intervenorID = 0;

    $(document).on("click", '.intervenorToRemoveFromAuto', function(event){
        event.preventDefault()
        intervenorID = this.id;
        $('#removeIntervenorModal').modal('show')
    })


    $(document).on("click", '#btnRemoveIntervanor', function(event){
        event.preventDefault()

        const occurrenceID = document.getElementById('intervanorOccurrence').value;

        $.ajax({
            url:`/ilicits/intervenors/removeFromAuto`,
            data: { intervenorID: intervenorID, occurrenceID: occurrenceID},
            type: 'GET',
            enctype: 'json',
            success: (success) => {
                if(success == 'fail'){
                    document.getElementById('intervanorRemovalAlert').innerHTML = 'Não é possível efetuar esta ação.'
                } else {
                    $('#removeIntervenorModal').modal('hide')
                    location.reload();
                }
            },
            error: (xhr) => {
                //
            }
        })
    })
})
