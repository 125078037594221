$( () => {

    //HANDLE WITH USER COMMUNICATION INPUT
    $(document).on("change", '#inputFormCommunication', function(event) {
        
        event.preventDefault()
        const formCommunication = document.getElementById('inputFormCommunication')
        const formErrorMessage = document.getElementById('formErrorMessage')
        const local =  document.getElementById('communicationLocal')
        const reference = document.getElementById('communicationReference')
        const phoneNumber = document.getElementById('communicationPhone')

        formCommunication.value == 'Verbal presencial' ? local.style.display = 'block' : local.style.display = 'none'
        formCommunication.value == 'Carta' ? reference.style.display = 'block' : reference.style.display = 'none'
        formCommunication.value == 'Verbal por telefone' ? phoneNumber.style.display = 'block' : phoneNumber.style.display = 'none'
        formCommunication.value == '' ? formErrorMessage.innerHTML = 'Este campo é obrigatório' : formErrorMessage.innerHTML = ''

        if(formCommunication == ''){
            document.getElementById('checkErrors').style.display = 'block'
            document.getElementById('validate').style.display = 'none'
        } else{
            document.getElementById('checkErrors').style.display = 'none'
            document.getElementById('validate').style.display = 'block'
        }
    })

})
