$( () => {
    if($('#vehicleInterventions').length > 0){
        $('#showLessinterventions').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessinterventions').style.display = 'none';
                document.getElementById('showMoreinterventions').style.display = 'block';
                document.getElementById('contentInterventions').style.display = 'none';
            }, 100)
        })

        $('#showMoreinterventions').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessinterventions').style.display = 'block';
                document.getElementById('showMoreinterventions').style.display = 'none';
                document.getElementById('contentInterventions').style.display = 'block';
            }, 100)
        })

        if($('#showInterventionHistory').is(':checked')){
            setTimeout( () => {
                document.getElementById('interventionHistoryContent').style.display = 'block';
            }, 100)
        }


        $('#showInterventionHistory').on('change', (event) => {
            event.preventDefault()
            if($('#showInterventionHistory').is(':checked')){
                document.getElementById('interventionHistoryContent').style.display = 'block';
            } else{
                document.getElementById('interventionHistoryContent').style.display = 'none';
            }
        })
    }
})
