$( () => {
    if($('#noticeDate').length > 0){

        const noticeDateCtx = document.getElementById('noticeDate').getContext('2d');

        $.ajax({
            crossDomain: true,
            url:'/waterql/notices/dashboard',
            type: 'GET',
            contentType: 'json',
            success: function(success){

                const chart = new Chart(noticeDateCtx, {
                    type: 'line',
                    data: {
                        labels: success[0],
                        datasets: [{
                            label: 'Editais emitidos',
                            backgroundColor: 'rgba(43, 132, 99, 0.2)',
                            borderColor: 'rgba(43, 132, 99, 1)',
                            borderWidth: 1,
                            data: success[1],
                        }]
                    },
                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text: 'Editais Qualidade da água.'
                            }
                        },
                        scales: {
                            x: {
                              position: 'bottom'
                            }
                        }
                    }
                })
            },
            error(xhr, error){
                console.log(xhr);
                console.log(error);
            }
        })
    }
})
