$( () => {

    if($('#datatable-intervetion-solved').length > 0){
        const url = window.location.pathname;
        if(url == "/fleets/interventions"){
            const table = $('#datatable-intervetion-solved').DataTable({
                ajax: {
                    url: "/fleets/interventions/interventionsSolved",
                    data: function(d) {
                      d.param = $('#datatable-intervetion-solved').val();
                    }
                  }
            });
            table.ajax.reload();
        }
    }

})
