$( () => {

    $("#inputDelegation").on('change', function(event){
        event.preventDefault()

        const delegation = document.getElementById('inputDelegation').value;
        const alertCostCenter = document.getElementById('alertCostCenter')

        const alertNoUsers = document.getElementById('alertNoUsers')
        const alertNoCostCenter = document.getElementById('alertNoCostCenter')
        const alertAllCostCenterAccountable = document.getElementById('alertAllCostCenterAccountable')

        const delegationDesignation =  $('#inputDelegation option:selected').text()

        if($('#costCenterAccountable').length > 0){
            alertCostCenter.style.display == 'block' ? alertCostCenter.style.display = 'none' : ''

            if(alertNoUsers){
                alertNoUsers.style.display == 'inline-block' ?  alertNoUsers.style.display == 'none' : ''
            }

            if(alertNoCostCenter){
                alertNoCostCenter.style.display == 'inline-block' ?  alertNoCostCenter.style.display == 'none' : ''
            }

            if(alertAllCostCenterAccountable){
                alertAllCostCenterAccountable.style.display == 'inline-block' ?  alertAllCostCenterAccountable.style.display == 'none' : ''
            }
        }

        $.ajax({
            url: `/delegations/usersDelegation/${delegation}`,
            type: 'GET',
            success:function(data){

                var options = '<option value ""> Selecionar</option>'

                if(data[0].length > 0){
                    data[0].forEach( (item) => {
                        options += '<option value="' + item.id + '">' + item.name + '</option>';
                    })
                }else{

                    alertCostCenter ? alertCostCenter.style.display = 'block' : ''
                    alertNoUsers ? alertNoUsers.style.display = 'inline-block' : ''

                    $('#delegationName').html(delegationDesignation)
                }

                $('#optionsDlegationUsers').html(options);
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    })

})
