$( () => {

    let intervenorSelected = new Array()
    let intervenorIDSelected = new Array()

    $(document).on('change', '#inputIntervenors, #intervenorsList', (event) => {
        event.preventDefault()

        const intervenor = document.getElementById('inputIntervenors') ?? document.getElementById('intervenorsList');
        const noItems = document.getElementById("noItems");

        const intervenorName = intervenor.options[intervenor.selectedIndex].innerHTML;

        const data = { id: intervenor.value, intervenor: intervenorName}

        let card = `<div id="cardItem">${intervenorName}<span class="removeItem" title="Remover" id="${intervenorName}"> <i class="fas fa-times-circle removeItem"></i></span></div>`
        let input = `<input hidden type="number" class="form-control datepicker inputIntervanors"  id="${intervenorName}" name="intervenorsSelected[]" value="${data.id}">`

        intervenorSelected.includes(card) ?  intervenorSelected: intervenorSelected.push(card)
        intervenorIDSelected.includes(input) ?  intervenorIDSelected: intervenorIDSelected.push(input)

        intervenorSelected.length > 0 ? noItems.style.display = 'none' : noItems.style.display = 'block'

        if($('#intervenorModal').hasClass('show')){
            $('#IDsSelectedOnModal').html(intervenorSelected.flat())
            $('#itemSelectedOnModal').html(intervenorIDSelected)
        } else{
            $('#itemSelected').html(intervenorSelected.flat())
            $('#IDsSelected').html(intervenorIDSelected)
        }
    })




    document.addEventListener('click', function(event) {

        if (event.target.matches('.removeItem')){
          var intervenorName = event.target.parentNode.id;

          const noItems = document.getElementById("noItems");
          const inputItem = document.getElementById(`${intervenorName}`).value;

          const card = `<div id="cardItem">${intervenorName}<span class="removeItem" title="Remover" id="${intervenorName}"> <i class="fas fa-times-circle removeItem"></i></span></div>`
          const input = `<input hidden type="number" class="form-control datepicker inputIntervanors"  id="${intervenorName}" name="intervenorsSelected[]" value="${inputItem}">`

            const filterCard = intervenorSelected.indexOf(card);
            if (filterCard !== -1) {
                intervenorSelected.splice(filterCard, 1);
            }

            const filterInput = intervenorIDSelected.indexOf(input);
            if (filterInput !== -1) {
                intervenorIDSelected.splice(filterInput, 1);
            }

            intervenorSelected.length > 0 ? noItems.style.display = 'none' : noItems.style.display = 'block'

        if($('#intervenorModal').hasClass('show')){
            $('#IDsSelectedOnModal').html(intervenorSelected.flat())
            $('#itemSelectedOnModal').html(intervenorIDSelected)
        } else{
            $('#itemSelected').html(intervenorSelected.flat())
            $('#IDsSelected').html(intervenorIDSelected)
        }
        }
    });







    $('#inputDelegations').on('change', (event) => {
        event.preventDefault()
        const delegation = document.getElementById('inputDelegations').value

        $.ajax({
            url: `/delegations/delegationHR/${delegation}`,
            type: 'GET',
            contentType: 'json',
            success: (success) => {

                var optionAcontable = ""
                var optionUser = ""

                var accountable = new Array()
                accountable = success[0]

                if(typeof(accountable) == 'object'){
                    var result = Object.keys(accountable).map((key) => [key, accountable[key]]);
                    result.forEach( (item) => {
                       optionAcontable += '<option value="' + item[1].id + '">' + item[1].name + '</option>';
                    })
                } else{
                    accountable.unshift({id:"", name: 'Selecionar'});
                    accountable.forEach( (item) => {
                        optionAcontable += '<option value="' + item.id + '">' + item.name + '</option>';
                    })
                }

                success[1].unshift({id:"", name: 'Selecionar'});
                success[1].forEach( (item) => {
                    optionUser += '<option value="' + item.id + '">' + item.name + '</option>';
                })

                $('#inputAccountable').html(optionAcontable);
                $('#inputIntervenors').html(optionUser);
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    })




    $('#inputInstalationNumber').on('blur', (event) => {
        event.preventDefault()
        const instalation = document.getElementById('inputInstalationNumber').value

        if(Number(instalation) > 0){
            $.ajax({
                url: `/ilicits/occurrences/checkcrime`,
                type: 'GET',
                data: { instalation: instalation },
                contentType: 'json',
                success: (success) => {

                    if (success != 0) {

                        $('#recurrence').prop('checked', true);
                        $('#AlertRecorrence').modal('show')

                        success.forEach((item) =>{
                            document.getElementById("inputRecurrence").value = 1;
                            document.getElementById("authorID").value = item.id
                            document.getElementById("inputActorName").value = item.author_fullname
                            document.getElementById("inputNIF").value = item.author_NIF
                            document.getElementById("inputPolice").value = item.author_policy
                            document.getElementById("authorcomment").value = item.additional_informations

                        })
                    } else {
                        $('#recurrence').prop('checked', false);
                        document.getElementById("inputRecurrence").value = "";
                        document.getElementById("authorID").value = "";
                        document.getElementById("inputActorName").value = ""
                        document.getElementById("inputNIF").value = ""
                        document.getElementById("inputPolice").value = ""
                        document.getElementById("authorcomment").value = ""
                    }
                },
                error: function(xhr){
                    $('#recurrence').prop('checked', false);
                }
            })
        }
    })




    $('#inputNIF').on('blur', (event) => {
        event.preventDefault()
        const instalation = document.getElementById('inputInstalationNumber').value
        const nif = document.getElementById('inputNIF').value

        if(Number(instalation) < 1 && Number(nif) > 0 ){
            $.ajax({
                url: `/ilicits/occurrences/checkcrime`,
                type: 'GET',
                data: { nif: nif },
                contentType: 'json',
                success: (success) => {
                    if (success != 0) {

                        $('#recurrence').prop('checked', true);
                        $('#AlertRecorrence').modal('show')

                        success.forEach((item) =>{
                            $('#authorID').html(item.id);
                            document.getElementById("inputActorName").value = item.author_fullname
                            document.getElementById("inputNIF").value = item.author_NIF
                            document.getElementById("inputPolice").value = item.author_policy
                            document.getElementById("authorcomment").value = item.additional_informations
                            document.getElementById("inputInstalationNumber").value = item.instalation_number

                        })
                    } else {
                        $('#recurrence').prop('checked', false);
                        document.getElementById("inputActorName").value = ""
                        document.getElementById("inputPolice").value = ""
                        document.getElementById("authorcomment").value = ""
                    }
                },
                error: function(xhr){
                    $('#recurrence').prop('checked', false);
                }
            })
        }
    })


    $('#inputPolice').on('blur', (event) => {
        event.preventDefault()
        const policy = document.getElementById('inputPolice').value

        if((!document.getElementById('recurrence').checked)){
            $.ajax({
                url: `/ilicits/occurrences/checkcrime`,
                type: 'GET',
                data: { policy: policy },
                contentType: 'json',
                success: (success) => {
                    if (success != 0) {

                        $('#recurrence').prop('checked', true);
                        $('#AlertRecorrence').modal('show')

                        success.forEach((item) =>{
                            $('#authorID').html(item.id);
                            document.getElementById("inputActorName").value = item.author_fullname
                            document.getElementById("inputNIF").value = item.author_NIF
                            document.getElementById("authorcomment").value = item.additional_informations
                            document.getElementById("inputInstalationNumber").value = item.instalation_number

                        })
                    } else {
                        $('#recurrence').prop('checked', false);
                        document.getElementById("inputActorName").value = ""
                        document.getElementById("authorcomment").value = ""
                        document.getElementById("inputNIF").value = ""
                    }
                },
                error: function(xhr){
                    $('#recurrence').prop('checked', false);
                }
            })
        }
    })


    $('#noRecorrence').on('click', (event) => {
        event.preventDefault()
        $('#recurrence').prop('checked', false);
    })



    var _html = new Array()
    var count = 0
    $('#add_form').on('click', (event) => {
        event.preventDefault()
        const witnessForm = document.getElementById("witnessForm");
        document.getElementById("noWitness").style.display = "none";

        count = count + 1
        const rowForm = `<div class="row newWitnessForm mb-3" id="rowForm${count+1}">${witnessForm.innerHTML}</div>`
        $('#htmlForm').append(rowForm);
    })


    $('#htmlForm').on('click', '#remove_form', function() {
        var formId = $(this).closest('.newWitnessForm').attr('id');
        $('#' + formId).remove();
    });


    $('#accountableParticipation').on('change', function(event){
        event.preventDefault()
        if(this.checked){
            document.getElementById("accountable").style.display = "block"
            document.getElementById("informationDate").style.display = "block"
        }

         if(!this.checked) {
            document.getElementById("accountable").style.display = "none"
            document.getElementById("informationDate").style.display = "none"
        }
    })



    $('#additionalAuthorInfo').on('change', function(event){
        event.preventDefault()
        if(this.checked){
            document.getElementById("authorpolice").style.display = "block"
            document.getElementById("authornif").style.display = "block"
            document.getElementById("authorcomment").style.display = "block"
        }

         if(!this.checked) {
            document.getElementById("authorpolice").style.display = "none"
            document.getElementById("authornif").style.display = "none"
            document.getElementById("authorcomment").style.display = "none"
        }
    })

    if($('ilicitForm').length > 0){
        $('form').on('submit', function(event){
            event.preventDefault()
            document.getElementById('saveOccurence').disabled = true;
            this.submit();
        })
    }

})
