$(() => {
    if ($("#datatable-categories").length > 0) {
        window.datatable_assets = $("#datatable-categories").DataTable({
            responsive: true,
            searching: true,
            order: [[ 1, "desc" ]],
            bFilter: false,
            columnDefs: [
                {
                    targets: 'sorting-disabled',
                    orderable: false,
                },
                {
                    targets: 'text-center',
                    className: "text-center",
                },
            ],
            lengthChange: true,
            language: {
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                },
                sProcessing: loadingHTML,
                sEmptyTable: "No Records",
                url: "/config/dataTables/lang/" + window.lang + ".json"
            },
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: {
                url: window.location.pathname
            },
            columns: [
                {data: 'actions', name: 'actions', class: 'actions text-center px-0 sorting_disabled', searchable: false, sortable: false},
                {data: 'name', name: 'name', searchable: false, orderable: false},
                {data: 'slug', name: 'slug', searchable: true},
            ],
            language: {
                url: "/config/dataTables/lang/" + window.lang + ".json"
            }
        });
    }
});