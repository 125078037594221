$( () => {
    if($('#waterLossForms').length > 0){
        let aux = 0;

        const waterLossID = new Array();
        const arrayForms = new Array();
        const functions = require('./functions');

        function handleWithSubvalues(){
            const vehicleValues = document.querySelectorAll('[name="inputWaterLossValue[]"]');
            const subtotalValue = document.getElementById('lossSubTotalvalue')

            let total = 0;
            vehicleValues.forEach((input) => {
                if(Number(input.value)){
                    total += Number(input.value)
                }
            })

            subtotalValue.innerHTML = total
            const waterLossSutotal = document.getElementById('inputLosesSubTotal')
            waterLossSutotal.value = total
        }



        $('#inputDescriptionLoss').on('change', (event) => {
            event.preventDefault();
            aux += 1

            const waterForm = new Array();
            const descriptionID = document.getElementById('inputDescriptionLoss').value;

            $.ajax({
                url:`/budgets/subitem/${descriptionID}`,
                type: 'GET',
                enctype: 'json',
                success: (success) => {
                    if(success){

                        const description = success.subitem_description

                        const template = document.getElementById('waterLossTemplate').innerHTML;

                        const iconRemove = `<div class="col-md-12 mb-1 mt-2"><span class="float-right text-danger" title="Remover"><i class="fas fa-minus-circle removeWaterLoss" id="${aux}" style="cursor:pointer"></i></span></div>`

                        const inputDescription = `  <div class="col-md-12 mb-3">
                                                        <input type="text" class="form-control" value="${description}" disabled>
                                                        <input hidden type="number" name="inputWaterLossID[]" value="${descriptionID}" class="form-control" id= "inputWaterLossID${aux}">
                                                    </div>
                                            `

                        $('#waterLossTitle').innerHTML = inputDescription

                        const idIsIncluded = waterLossID.includes(descriptionID);

                        idIsIncluded == false ? waterLossID.push(descriptionID) : ''

                        idIsIncluded == true ? waterForm : waterForm.push(`<div class="row newWitnessForm mb-3" id="waterForm${aux}">${iconRemove + inputDescription + template}</div>`)
                        idIsIncluded == true ? arrayForms : arrayForms.push(`<div class="row newWitnessForm mb-3" id="waterForm${aux}">${iconRemove + inputDescription + template}</div>`)

                        $('#losesSelected').append(waterForm)

                        const quantity = 0
                        $(`#waterForm${aux} .col-md-4 #inputChargePercentage`).val(quantity)
                        $(`#waterForm${aux} .col-md-4 #inputWaterLossPrice`).val(success.subitem_amount)
                        $(`#waterForm${aux} .col-md-4 #inputWaterLossValue`).val(success.subitem_amount * quantity)
                        $(`#waterForm${aux} .col-md-12 #subitemWaterLoss`).val(Number(success.id))

                        $(`#waterForm${aux} .col-md-4 #waterLossValue`).val(success.subitem_amount * quantity)
                        $(`#waterForm${aux} .col-md-4 #waterLossPrice`).val(success.subitem_amount)

                        handleWithSubvalues();
                        functions.handleWithTotalValues()

                        $("#inputDescriptionLoss").val("");
                        document.getElementById('NoLossSelected').style.display = 'none';
                    }
                },
                error: (xhr) => { }
            })
        })



        //UPDATE WATER LOSS SUBVALUE WHEN A FORM ROW IS REMOVED
        document.addEventListener('click', (event) => {
            if(event.target.matches('.removeWaterLoss')){
                const formItemID = event.target.id
                const descriptionIdToRemove = document.getElementById(`inputWaterLossID${formItemID}`).value

                var indexToRemove = waterLossID.indexOf(descriptionIdToRemove);
                if (indexToRemove !== -1) {
                    waterLossID.splice(indexToRemove, 1);
                }

                document.getElementById(`waterForm${formItemID}`).remove()
                handleWithSubvalues();
                functions.handleWithTotalValues()
            }
        })



        //UPDATE WATER LOSS SUBVALUE WHEN INPUT LOSS QUANTITY IS CHANGING
        document.addEventListener('change', (event) => {
            if (event.target.matches('#inputWaterLossQuantity')) {
                const quantity = event.target.value;
                const rowID = event.target.parentElement.parentElement.id;

                const currentPrice = $(`#${rowID} .col-md-4 #inputWaterLossPrice`).val()
                const setInputValue = currentPrice * quantity

                $(`#${rowID} .col-md-4 #inputWaterLossValue`).val(setInputValue.toFixed(2))
                handleWithSubvalues();
                functions.handleWithTotalValues()
            }
        });
    }
})
