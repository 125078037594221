tinymce.addI18n('pt_PT',{
"Redo": "Refazer",
"Undo": "Anular",
"Cut": "Cortar",
"Copy": "Copiar",
"Paste": "Colar",
"Select all": "Selecionar tudo",
"New document": "Novo documento",
"Ok": "Ok",
"Cancel": "Cancelar",
"Visual aids": "Ajuda visual",
"Bold": "Negrito",
"Italic": "It\u00e1lico",
"Underline": "Sublinhado",
"Strikethrough": "Rasurado",
"Superscript": "Superior \u00e0 linha",
"Subscript": "Inferior \u00e0 linha",
"Clear formatting": "Limpar formata\u00e7\u00e3o",
"Align left": "Alinhar \u00e0 esquerda",
"Align center": "Alinhar ao centro",
"Align right": "Alinhar \u00e0 direita",
"Justify": "Justificar",
"Bullet list": "Lista com marcas",
"Numbered list": "Lista numerada",
"Decrease indent": "Diminuir avan\u00e7o",
"Increase indent": "Aumentar avan\u00e7o",
"Close": "Fechar",
"Formats": "Formatos",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "O seu navegador n\u00e3o suporta acesso direto \u00e0 \u00e1rea de transfer\u00eancia. Por favor, use os atalhos Ctrl+X\/C\/V do seu teclado.",
"Headers": "Cabe\u00e7alhos",
"Header 1": "Cabe\u00e7alho 1",
"Header 2": "Cabe\u00e7alho 2",
"Header 3": "Cabe\u00e7alho 3",
"Header 4": "Cabe\u00e7alho 4",
"Header 5": "Cabe\u00e7alho 5",
"Header 6": "Cabe\u00e7alho 6",
"Headings": "T\u00edtulos",
"Heading 1": "T\u00edtulo 1",
"Heading 2": "T\u00edtulo 2",
"Heading 3": "T\u00edtulo 3",
"Heading 4": "T\u00edtulo 4",
"Heading 5": "T\u00edtulo 5",
"Heading 6": "T\u00edtulo 6",
"Preformatted": "Pr\u00e9-formatado",
"Div": "Div",
"Pre": "Pre",
"Code": "C\u00f3digo",
"Paragraph": "Par\u00e1grafo",
"Blockquote": "Blockquote",
"Inline": "Inline",
"Blocks": "Blocos",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "O comando colar est\u00e1 em modo de texto simples. O conte\u00fado ser\u00e1 colado como texto simples at\u00e9 desativar esta op\u00e7\u00e3o.",
"Fonts": "Tipos de letra",
"Font Sizes": "Tamanhos dos tipos de letra",
"Class": "Classe",
"Browse for an image": "Procurar uma imagem",
"OR": "OU",
"Drop an image here": "Largar aqui uma imagem",
"Upload": "Carregar",
"Block": "Bloco",
"Align": "Alinhar",
"Default": "Padr\u00e3o",
"Circle": "C\u00edrculo",
"Disc": "Disco",
"Square": "Quadrado",
"Lower Alpha": "a. b. c. ...",
"Lower Greek": "\\u03b1. \\u03b2. \\u03b3. ...",
"Lower Roman": "i. ii. iii. ...",
"Upper Alpha": "A. B. C. ...",
"Upper Roman": "I. II. III. ...",
"Anchor...": "\u00c2ncora...",
"Name": "Nome",
"Id": "ID",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "O ID deve come\u00e7ar com uma letra, seguido apenas por letras, n\u00fameros, pontos, dois pontos, tra\u00e7os ou sobtra\u00e7os.",
"You have unsaved changes are you sure you want to navigate away?": "Existem altera\u00e7\u00f5es que ainda n\u00e3o foram guardadas. Tem a certeza que pretende sair?",
"Restore last draft": "Restaurar o \u00faltimo rascunho",
"Special character...": "Car\u00e1ter especial...",
"Source code": "C\u00f3digo fonte",
"Insert\/Edit code sample": "Inserir\/editar amostra de c\u00f3digo",
"Language": "Idioma",
"Code sample...": "Amostra de c\u00f3digo...",
"Color Picker": "Seletor de cores",
"R": "R",
"G": "G",
"B": "B",
"Left to right": "Da esquerda para a direita",
"Right to left": "Da direita para a esquerda",
"Emoticons...": "\u00cdcones expressivos...",
"Metadata and Document Properties": "Metadados e propriedades do documento",
"Title": "T\u00edtulo",
"Keywords": "Palavras-chave",
"Description": "Descri\u00e7\u00e3o",
"Robots": "Rob\u00f4s",
"Author": "Autor",
"Encoding": "Codifica\u00e7\u00e3o",
"Fullscreen": "Ecr\u00e3 completo",
"Action": "A\u00e7\u00e3o",
"Shortcut": "Atalho",
"Help": "Ajuda",
"Address": "Endere\u00e7o",
"Focus to menubar": "Foco na barra de menu",
"Focus to toolbar": "Foco na barra de ferramentas",
"Focus to element path": "Foco no caminho do elemento",
"Focus to contextual toolbar": "Foco na barra de contexto",
"Insert link (if link plugin activated)": "Inserir hiperliga\u00e7\u00e3o (se o plugin de liga\u00e7\u00f5es estiver ativado)",
"Save (if save plugin activated)": "Guardar (se o plugin de guardar estiver ativado)",
"Find (if searchreplace plugin activated)": "Pesquisar (se o plugin pesquisar e substituir estiver ativado)",
"Plugins installed ({0}):": "Plugins instalados ({0}):",
"Premium plugins:": "Plugins comerciais:",
"Learn more...": "Saiba mais...",
"You are using {0}": "Est\u00e1 a usar {0}",
"Plugins": "Plugins",
"Handy Shortcuts": "Atalhos \u00fateis",
"Horizontal line": "Linha horizontal",
"Insert\/edit image": "Inserir\/editar imagem",
"Image description": "Descri\u00e7\u00e3o da imagem",
"Source": "Localiza\u00e7\u00e3o",
"Dimensions": "Dimens\u00f5es",
"Constrain proportions": "Manter propor\u00e7\u00f5es",
"General": "Geral",
"Advanced": "Avan\u00e7ado",
"Style": "Estilo",
"Vertical space": "Espa\u00e7amento vertical",
"Horizontal space": "Espa\u00e7amento horizontal",
"Border": "Contorno",
"Insert image": "Inserir imagem",
"Image...": "Imagem...",
"Image list": "Lista de imagens",
"Rotate counterclockwise": "Rota\u00e7\u00e3o anti-hor\u00e1ria",
"Rotate clockwise": "Rota\u00e7\u00e3o hor\u00e1ria",
"Flip vertically": "Inverter verticalmente",
"Flip horizontally": "Inverter horizontalmente",
"Edit image": "Editar imagem",
"Image options": "Op\u00e7\u00f5es de imagem",
"Zoom in": "Mais zoom",
"Zoom out": "Menos zoom",
"Crop": "Recortar",
"Resize": "Redimensionar",
"Orientation": "Orienta\u00e7\u00e3o",
"Brightness": "Brilho",
"Sharpen": "Mais nitidez",
"Contrast": "Contraste",
"Color levels": "N\u00edveis de cor",
"Gamma": "Gama",
"Invert": "Inverter",
"Apply": "Aplicar",
"Back": "Voltar",
"Insert date\/time": "Inserir data\/hora",
"Date\/time": "Data\/hora",
"Insert\/Edit Link": "Inserir\/editar liga\u00e7\u00e3o",
"Insert\/edit link": "Inserir\/editar liga\u00e7\u00e3o",
"Text to display": "Texto a exibir",
"Url": "URL",
"Open link in...": "Abrir liga\u00e7\u00e3o em...",
"Current window": "Janela atual",
"None": "Nenhum",
"New window": "Nova janela",
"Remove link": "Remover liga\u00e7\u00e3o",
"Anchors": "\u00c2ncora",
"Link...": "Liga\u00e7\u00e3o...",
"Paste or type a link": "Copiar ou escrever uma hiperliga\u00e7\u00e3o",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "O URL que indicou parece ser um endere\u00e7o de email. Quer adicionar o prefixo mailto: tal como necess\u00e1rio?",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "O URL que indicou parece ser um endere\u00e7o web. Quer adicionar o prefixo http:\/\/ tal como necess\u00e1rio?",
"Link list": "Lista de liga\u00e7\u00f5es",
"Insert video": "Inserir v\u00eddeo",
"Insert\/edit video": "Inserir\/editar v\u00eddeo",
"Insert\/edit media": "Inserir\/editar media",
"Alternative source": "Localiza\u00e7\u00e3o alternativa",
"Alternative source URL": "URL da origem alternativa",
"Media poster (Image URL)": "Publicador de media (URL da imagem)",
"Paste your embed code below:": "Colar c\u00f3digo para embeber:",
"Embed": "Embeber",
"Media...": "Media...",
"Nonbreaking space": "Espa\u00e7o n\u00e3o quebr\u00e1vel",
"Page break": "Quebra de p\u00e1gina",
"Paste as text": "Colar como texto",
"Preview": "Pr\u00e9-visualizar",
"Print...": "Imprimir...",
"Save": "Guardar",
"Find": "Pesquisar",
"Replace with": "Substituir por",
"Replace": "Substituir",
"Replace all": "Substituir tudo",
"Previous": "Anterior",
"Next": "Pr\u00f3ximo",
"Find and replace...": "Localizar e substituir...",
"Could not find the specified string.": "N\u00e3o foi poss\u00edvel localizar o termo especificado.",
"Match case": "Diferenciar mai\u00fasculas e min\u00fasculas",
"Find whole words only": "Localizar apenas palavras inteiras",
"Spell check": "Verifica\u00e7\u00e3o ortogr\u00e1fica",
"Ignore": "Ignorar",
"Ignore all": "Ignorar tudo",
"Finish": "Concluir",
"Add to Dictionary": "Adicionar ao dicion\u00e1rio",
"Insert table": "Inserir tabela",
"Table properties": "Propriedades da tabela",
"Delete table": "Eliminar tabela",
"Cell": "C\u00e9lula",
"Row": "Linha",
"Column": "Coluna",
"Cell properties": "Propriedades da c\u00e9lula",
"Merge cells": "Unir c\u00e9lulas",
"Split cell": "Dividir c\u00e9lula",
"Insert row before": "Inserir linha antes",
"Insert row after": "Inserir linha depois",
"Delete row": "Eliminar linha",
"Row properties": "Propriedades da linha",
"Cut row": "Cortar linha",
"Copy row": "Copiar linha",
"Paste row before": "Colar linha antes",
"Paste row after": "Colar linha depois",
"Insert column before": "Inserir coluna antes",
"Insert column after": "Inserir coluna depois",
"Delete column": "Eliminar coluna",
"Cols": "Colunas",
"Rows": "Linhas",
"Width": "Largura",
"Height": "Altura",
"Cell spacing": "Espa\u00e7amento entre c\u00e9lulas",
"Cell padding": "Espa\u00e7amento interno da c\u00e9lula",
"Show caption": "Mostrar legenda",
"Left": "Esquerda",
"Center": "Centro",
"Right": "Direita",
"Cell type": "Tipo de c\u00e9lula",
"Scope": "Escopo",
"Alignment": "Alinhamento",
"H Align": "Alinhamento H",
"V Align": "Alinhamento V",
"Top": "Superior",
"Middle": "Meio",
"Bottom": "Inferior",
"Header cell": "C\u00e9lula de cabe\u00e7alho",
"Row group": "Agrupar linha",
"Column group": "Agrupar coluna",
"Row type": "Tipo de linha",
"Header": "Cabe\u00e7alho",
"Body": "Corpo",
"Footer": "Rodap\u00e9",
"Border color": "Cor de contorno",
"Insert template...": "Inserir modelo...",
"Templates": "Modelos",
"Template": "Tema",
"Text color": "Cor do texto",
"Background color": "Cor de fundo",
"Custom...": "Personalizada...",
"Custom color": "Cor personalizada",
"No color": "Sem cor",
"Remove color": "Remover cor",
"Table of Contents": "\u00cdndice",
"Show blocks": "Mostrar blocos",
"Show invisible characters": "Mostrar caracteres invis\u00edveis",
"Word count": "Contagem de palavras",
"Count": "Contagem",
"Document": "Documento",
"Selection": "Sele\u00e7\u00e3o",
"Words": "Palavras",
"Words: {0}": "Palavras: {0}",
"{0} words": "{0} palavras",
"File": "Ficheiro",
"Edit": "Editar",
"Insert": "Inserir",
"View": "Ver",
"Format": "Formatar",
"Table": "Tabela",
"Tools": "Ferramentas",
"Powered by {0}": "Criado em {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Caixa de texto formatado. Pressione ALT-F9 para exibir o menu. Pressione ALT-F10 para exibir a barra de ferramentas. Pressione ALT-0 para exibir a ajuda",
"Image title": "T\u00edtulo da imagem",
"Border width": "Largura do limite",
"Border style": "Estilo do limite",
"Error": "Erro",
"Warn": "Aviso",
"Valid": "V\u00e1lido",
"To open the popup, press Shift+Enter": "Para abrir o pop-up, prima Shift+Enter",
"Rich Text Area. Press ALT-0 for help.": "\u00c1rea de texto formatado. Prima ALT-0 para exibir a ajuda.",
"System Font": "Tipo de letra do sistema",
"Failed to upload image: {0}": "Falha ao carregar imagem: {0}",
"Failed to load plugin: {0} from url {1}": "Falha ao carregar plugin: {0} do URL {1}",
"Failed to load plugin url: {0}": "Falha ao carregar o URL do plugin: {0}",
"Failed to initialize plugin: {0}": "Falha ao inicializar plugin: {0}",
"example": "exemplo",
"Search": "Pesquisar",
"All": "Tudo",
"Currency": "Moeda",
"Text": "Texto",
"Quotations": "Aspas",
"Mathematical": "Matem\u00e1tico",
"Extended Latin": "Carateres latinos estendidos",
"Symbols": "S\u00edmbolos",
"Arrows": "Setas",
"User Defined": "Definido pelo utilizador",
"dollar sign": "cifr\u00e3o",
"currency sign": "sinal monet\u00e1rio",
"euro-currency sign": "sinal monet\u00e1rio do euro",
"colon sign": "sinal de dois pontos",
"cruzeiro sign": "sinal de cruzeiro",
"french franc sign": "sinal de franco franc\u00eas",
"lira sign": "sinal de lira",
"mill sign": "sinal de por mil",
"naira sign": "sinal de naira",
"peseta sign": "sinal de peseta",
"rupee sign": "sinal de r\u00fapia",
"won sign": "sinal de won",
"new sheqel sign": "sinal de novo sheqel",
"dong sign": "sinal de dong",
"kip sign": "sinal kip",
"tugrik sign": "sinal tugrik",
"drachma sign": "sinal drachma",
"german penny symbol": "sinal de penny alem\u00e3o",
"peso sign": "sinal de peso",
"guarani sign": "sinal de guarani",
"austral sign": "sinal de austral",
"hryvnia sign": "sinal hryvnia",
"cedi sign": "sinal de cedi",
"livre tournois sign": "sinal de libra de tours",
"spesmilo sign": "sinal de spesmilo",
"tenge sign": "sinal de tengue",
"indian rupee sign": "sinal de rupia indiana",
"turkish lira sign": "sinal de lira turca",
"nordic mark sign": "sinal de marca n\u00f3rdica",
"manat sign": "sinal manat",
"ruble sign": "sinal de rublo",
"yen character": "sinal de iene",
"yuan character": "sinal de iuane",
"yuan character, in hong kong and taiwan": "sinal de iuane, em Hong Kong e Taiwan",
"yen\/yuan character variant one": "variante um de sinal de iene\/iuane",
"Loading emoticons...": "A carregar \u00edcones expressivos...",
"Could not load emoticons": "N\u00e3o foi poss\u00edvel carregar \u00edcones expressivos",
"People": "Pessoas",
"Animals and Nature": "Animais e natureza",
"Food and Drink": "Comida e bebida",
"Activity": "Atividade",
"Travel and Places": "Viagens e lugares",
"Objects": "Objetos",
"Flags": "Bandeiras",
"Characters": "Carateres",
"Characters (no spaces)": "Carateres (sem espa\u00e7os)",
"{0} characters": "{0} carateres",
"Error: Form submit field collision.": "Erro: conflito no campo de submiss\u00e3o de formul\u00e1rio.",
"Error: No form element found.": "Erro: nenhum elemento de formul\u00e1rio encontrado.",
"Update": "Atualizar",
"Color swatch": "Cole\u00e7\u00e3o de cores",
"Turquoise": "Turquesa",
"Green": "Verde",
"Blue": "Azul",
"Purple": "P\u00farpura",
"Navy Blue": "Azul-atl\u00e2ntico",
"Dark Turquoise": "Turquesa escuro",
"Dark Green": "Verde escuro",
"Medium Blue": "Azul interm\u00e9dio",
"Medium Purple": "P\u00farpura interm\u00e9dio",
"Midnight Blue": "Azul muito escuro",
"Yellow": "Amarelo",
"Orange": "Laranja",
"Red": "Vermelho",
"Light Gray": "Cinzento claro",
"Gray": "Cinzento",
"Dark Yellow": "Amarelo escuro",
"Dark Orange": "Laranja escuro",
"Dark Red": "Vermelho escuro",
"Medium Gray": "Cinzento m\u00e9dio",
"Dark Gray": "Cinzento escuro",
"Light Green": "Verde claro",
"Light Yellow": "Amarelo claro",
"Light Red": "Vermelho claro",
"Light Purple": "P\u00farpura claro",
"Light Blue": "Azul claro",
"Dark Purple": "P\u00farpura escuro",
"Dark Blue": "Azul escuro",
"Black": "Preto",
"White": "Branco",
"Switch to or from fullscreen mode": "Entrar ou sair do modo de ecr\u00e3 inteiro",
"Open help dialog": "Abrir caixa de di\u00e1logo Ajuda",
"history": "hist\u00f3rico",
"styles": "estilos",
"formatting": "formata\u00e7\u00e3o",
"alignment": "alinhamento",
"indentation": "avan\u00e7o",
"permanent pen": "caneta permanente",
"comments": "coment\u00e1rios",
"Format Painter": "Pincel de formata\u00e7\u00e3o",
"Insert\/edit iframe": "Inserir\/editar iframe",
"Capitalization": "Capitaliza\u00e7\u00e3o",
"lowercase": "min\u00fasculas",
"UPPERCASE": "MAI\u00daSCULAS",
"Title Case": "Iniciais mai\u00fasculas",
"Permanent Pen Properties": "Propriedades da Caneta Permanente",
"Permanent pen properties...": "Propriedades da caneta permanente...",
"Font": "Tipo de letra",
"Size": "Tamanho",
"More...": "Mais...",
"Spellcheck Language": "Idioma de verifica\u00e7\u00e3o lingu\u00edstica",
"Select...": "Selecionar...",
"Preferences": "Prefer\u00eancias",
"Yes": "Sim",
"No": "N\u00e3o",
"Keyboard Navigation": "Navega\u00e7\u00e3o com teclado",
"Version": "Vers\u00e3o",
"Anchor": "\u00c2ncora",
"Special character": "Car\u00e1cter especial",
"Code sample": "Amostra de c\u00f3digo",
"Color": "Cor",
"Emoticons": "Emo\u00e7\u00f5es",
"Document properties": "Propriedades do documento",
"Image": "Imagem",
"Insert link": "Inserir liga\u00e7\u00e3o",
"Target": "Alvo",
"Link": "Liga\u00e7\u00e3o",
"Poster": "Autor",
"Media": "Media",
"Print": "Imprimir",
"Prev": "Anterior",
"Find and replace": "Pesquisar e substituir",
"Whole words": "Palavras completas",
"Spellcheck": "Corretor ortogr\u00e1fico",
"Caption": "Legenda",
"Insert template": "Inserir modelo"
});