//const { ajax } = require("jquery");
//const { trim } = require("jquery");

$(() => {
    if($("#messagelistparams").length > 0) {
        $("#update").click((event) => {

            var dataForm = $('#messagelistparams').serializeArray();
            event.preventDefault();

            var payload = {}
            var count = 0

            $.each(dataForm, function() {
                payload[this.name] = this.value;
            });

            $.get('get-token', function(response) {
                $.ajax({
                    crossDomain: true,
                    url: '/sms/getsmsIDs',
                    type: 'GET',
                    contentType: 'json',

                    success: function(data){
                        data.forEach(element => {

                            const dataRequest = {
                                getin: payload.getin,
                                listingfor: payload.typeoflisting,
                                token: response[0].mimo_token,
                                message_id: element.message_id
                            };

                            $.ajax({
                                crossDomain: true,
                                url: '/sms/listsms',
                                data: dataRequest,
                                type: 'GET',
                                contentType: 'json',
                                success: function(mimoresponse){
                                    const sms =  JSON.parse(mimoresponse);

                                    $.ajax({
                                        crossDomain: true,
                                        url: '/sms/updateinvoicesdata',
                                        data: {phone: sms.recipients[0].phone, status: sms.recipients[0].status, messageID: sms.recipients[0].messageId,  id: sms.id},
                                        type: 'GET',
                                        contentType: 'json',
                                        success: function(updateted){
                                            count = count+1
                                            console.log(`${count} SMS atualizadas com sucesso.`);
                                        },
                                        error: function(xhr, status, error){
                                            /* console.log(status);
                                            console.log(error); */
                                        }
                                    });
                                },

                                error: function(xhr, status, error){
                                   /*  console.log(status);
                                    console.log(error); */
                                }
                            });
                       });
                    },

                    error: function(xhr, status, error) {
                       /*  console.log(status);
                        console.log(error); */
                    }
                });
            })
        });
    };
});
