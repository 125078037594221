$(() => {
    if ($("#sendsms").length > 0) {

        $("#send").click( (event) =>{
            event.preventDefault();

            var dataForm = $('#sendsms').serializeArray();
            const sendervalue = document.getElementById('sender')
            const recipientsvalue = document.getElementById('recipients')
            const textvalue = document.getElementById('text')
            const btnsend = document.getElementById("send")
            const feedback = document.getElementById("sendsmsalert")
            const loadClientData = document.getElementById("newSMS")
            const loading = document.getElementById('loading');

            var payload = {};

            $.each(dataForm, function() {
                payload[this.name] = this.value;
            });

            const data = {
                sender: payload.sender,
                recipients: payload.recipients,
                text: payload.text
            };

                    if (sendervalue.value ==="") {
                        sendervalue.setCustomValidity('Este campo é obrigatório.');
                    } else {
                        if(data.text.length < 3){
                            sendervalue.setCustomValidity('Remetente inválido.');
                        }
                    }

                    if (recipientsvalue.value ==="") {
                        recipientsvalue.setCustomValidity('Este campo é obrigatório.');
                    } else {
                        if(data.recipients.length != 9 || data.recipients[0] != 9 ){
                            recipientsvalue.setCustomValidity('Número de Telemóvel é inválido.');
                        }
                    }

                    if (textvalue.value ==="") {
                        textvalue.setCustomValidity('Este campo é obrigatório.');
                    } else {
                        if(data.text.length < 30 ){
                            textvalue.setCustomValidity('Texto é inválido.');
                        }
                    }

                    recipientsvalue.reportValidity();
                    sendervalue.reportValidity();
                    textvalue.reportValidity();

                    recipientsvalue.addEventListener('click', function() {
                        recipientsvalue.setCustomValidity('');
                        sendervalue.setCustomValidity('');
                        textvalue.setCustomValidity('');
                    });

                    sendervalue.addEventListener('click', function() {
                        recipientsvalue.setCustomValidity('');
                        sendervalue.setCustomValidity('');
                        textvalue.setCustomValidity('');
                    });

                    textvalue.addEventListener('click', function() {
                        recipientsvalue.setCustomValidity('');
                        sendervalue.setCustomValidity('');
                        textvalue.setCustomValidity('');
                    });

            if(data.sender.length > 3 && data.recipients.length == 9 && data.text.length > 30 && data.recipients[0] == 9){

                loading.style.display = "block"

                $.ajax({
                    crossDomain: true,
                    url: '/sms/sendsms',
                    type: "POST",
                    data: data,
                    success: function(response){
                        console.log(response)


                        btnsend.style.display = "none"
                        loading.style.display = "none"
                        feedback.style.display = "block"
                        $('#alertcontent').html(`Mensagem enviada com sucesso.`)
                        $('#icon_alert').html('<i class="fas fa-check-circle"></i>')
                        loadClientData.style.display = "block"

                        sendervalue.value = ""
                        recipientsvalue.value = ""
                        textvalue.value = ""
                        console.log(response);
                    },

                    error: function(xhr, status, error) {
                        console.log(xhr);
                        console.log(error);
                    }
                });
            }

            $("#yes_Send").click((event) =>{
                event.preventDefault();
                location.reload()
            })

            $("#not_send").click((event) =>{
                event.preventDefault();
                window.location.href = "/sms/listsms";
            })
        });
    };
});
