$( () => {
    if($('#monthlySMS_Sent').length > 0){

        const monthlySMS_SentCtx = document.getElementById('monthlySMS_Sent').getContext('2d');

        $.ajax({
            crossDomain: true,
            url:'/sms/monthlysms',
            type: 'GET',
            contentType: 'json',
            success: function(success){
                const chart = new Chart(monthlySMS_SentCtx, {
                    type: 'line',
                    data: {
                        labels: success[0],
                        datasets: [{
                            label: 'Dívidas periódicas',
                            backgroundColor: 'rgba(43, 132, 99, 0.2)',
                            borderColor: 'rgba(43, 132, 99, 1)',
                            borderWidth: 1,
                            data: success[1],
                        }]
                    },
                    options: {
                        plugins: {
                            title: {
                              display: true,
                              text: 'Chart.js Line Chart - Multi Axis'
                            }
                        },
                        scales: {
                            x: {
                              position: 'bottom'
                            }
                        }
                    }
                })
            },
            error(xhr, error){
        /*         console.log(xhr);
                console.log(error); */
            }
        })
    }

    //MONTHLY SMS STATUS
    if($('#monthlySMS_status').length > 0){

        const monthlySMS_StatusCtx = document.getElementById('monthlySMS_status').getContext('2d');
        $.ajax({
            crossDomain: true,
            url:'/sms/shipingstatus',
            type: 'GET',
            contentType: 'json',
            success: function(data){

                const chart = new Chart(monthlySMS_StatusCtx, {
                    type: 'line',
                    data: {
                        labels: data[0],
                        datasets: [
                            {
                                label: 'Mensagens enviadas',
                                backgroundColor: 'rgba(43, 132, 99, 0.2)',
                                borderColor: 'rgba(43, 132, 99, 1)',
                                borderWidth: 1,
                                data: data[5],
                                order: 0,
                                type: 'bar',
                            },
                        //     {
                        //     label: 'SMS Entregue',
                        //     backgroundColor: 'rgba(5, 173, 6, 0.2)',
                        //     borderColor: 'rgba(5, 173, 6, 085)',
                        //     borderWidth: 1,
                        //     data: data[1],
                        //     order: 1,
                        //     fill: false,
                        // }, {
                        //     label: 'SMS Não entregue',
                        //     backgroundColor: 'rgba(243, 0, 0, 0.2)',
                        //     borderColor: 'rgba(243, 0, 0, 1)',
                        //     borderWidth: 1,
                        //     data: data[2],
                        //     order: 2,
                        //     fill: false,
                        // },
                        //  {
                        //     label: 'Carregadas Sem Contacto',
                        //     backgroundColor: '',
                        //     backgroundColor: 'rgba(243, 124, 0, 0.2)',
                        //     borderColor: 'rgba(243, 124, 0, 1)',
                        //     borderWidth: 1,
                        //     data: data[3],
                        //     order: 3,
                        //     fill: false,
                        // },

                        // {
                        //     label: 'Carregadas Sem Contracto',
                        //     backgroundColor: '',
                        //     backgroundColor: 'rgba(5, 173, 255, 0.2)',
                        //     borderColor: 'rgba(5, 173, 255, 1)',
                        //     borderWidth: 1,
                        //     data: data[4],
                        //     order: 4,
                        //     fill: false,
                        // },

                        ],

                    },
                    options: {
                        plugins: {
                            title: {
                              display: true,
                              text: 'Chart.js Line Chart - Multi Axis'
                            }
                        },
                        scales: {
                            x: {
                              position: 'bottom'
                            }
                        },

                    },
                })
            },
            error(xhr, error){
               /*  console.log(xhr);
                console.log(error); */
            }
        })
    }
})
