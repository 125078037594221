const defaultUserOption = $('#manager-control-users').html();
const defaultSingleUserOption = $('#timesheetDownload #inputUser').html();

$(() => {

    if ($("#datatable-clockings").length > 0) {

        window.datatable_clockings = $("#datatable-clockings").DataTable({
            responsive: true,
            paging: false,
            order: [[ 0, "desc" ]],
            columnDefs: [
                {
                    targets: 'sorting-disabled',
                    orderable: false,
                },
                {
                    targets: 'text-center',
                    className: "text-center",
                },
                {
                    targets: 'limit-w-35',
                    width: '35%',
                },
                {
                    targets: 'limit-w-45',
                    width: '45%',
                },
                {
                    targets: 'limit-w-15',
                    width: '15%',
                },
                {
                    targets: 'limit-w-10',
                    width: '10%',
                },
                {
                    targets: 'limit-w-70px',
                    width: '70px',
                },
                {
                    targets: 'limit-w-40px',
                    width: '40px',
                },
                {
                    targets: 'limit-w-50px',
                    width: '50px',
                },
                {
                    targets: 'limit-w-120px',
                    width: '120px',
                },
                {
                    targets: 'invisible',
                    visible: false,
                },
            ],
            searching: false,
            bFilter: false,
            lengthChange: true,
            language: {
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                },
                sProcessing: loadingHTML,
                sEmptyTable: "No Records",
                url: "/config/dataTables/lang/" + window.lang + ".json"
            },
            autoWidth: false,
            processing: true,
            serverSide: true,
            // searchPanes: true,
            ajax: '/timereport',
            //ajax: {
            //    url: window.location.pathname
            //},
            // lengthMenu: [[10, 50, 100], [10, 50, 100]],
            // displayLength: 10,
            // pagingType: 'simple',
            columns: [
                {data: 'anomaly', name: 'anomaly', searchable: false, orderable: false, sortable: false},
                {data: 'date', name: 'date', searchable: true},
                {data: 'work_hours', name: 'work_hours', sortable: false},
                //{data: 'default_hours', name: 'default_hours', sortable: false},
                {data: 'comment_actions', name: 'comment_actions', sortable: false, orderable: false, searchable: false},
                {data: 'hour', name: 'hour', searchable: true, sortable: false},
                {data: 'lunchhour', name: 'lunchhour', sortable: false},
                {data: 'lunchexit', name: 'lunchexit', sortable: false},
                {data: 'exit', name: 'exit', sortable: false},
                {data: 'punchn5', name: 'punchn5', sortable: false},
                {data: 'punchn6', name: 'punchn6', sortable: false},
                {data: 'punchn7', name: 'punchn7', sortable: false},
                {data: 'punchn8', name: 'punchn8', sortable: false},

            ],
            drawCallback: (settings) => {

                // var data = this.api().ajax.json();

                // console.log('Settings: ', settings);
                // console.log('Api: ', this.api());
            },
        });

        let t = null;

        $('#datatable-clockings').find('thead .filter-col').each((i, el) => {
            $(el).on('change keyup', (evt) => {
                window.datatable_reports.column(i).search(($(el).is('select') ? $(el).find('option:selected').val() : el.value));
                console.log(($(el).is('select') ? $(el).find('option:selected').val() : el.value));
                clearTimeout(t);
                t = setTimeout(() => {
                    window.datatable_reports.draw();
                }, 500);
            });
        });
    }


    if ($("#timereport-clockings").length > 0) {

        function hideButtons() {
            $('form[id^="time_report_"]').find('button').addClass('d-none');
        }

        function showButtons() {
            $('form[id^="time_report_"]').find('button').removeClass('d-none');
        }

        $('form[id^="time_report_"]').find('button').on('click', function () {
            $(this).prop('disabled', true);
        });

        let navId;

        window.requestUserClockinCoords = $.ajax({
            url: '/user/clockin-coordinates',
            method: 'POST',
            success: (response) => {
                const coords = JSON.parse(response);

                // console.log('User Coordinate Constraints: ', coords);

                if (coords.lat !== null && coords.lon !== null) {
                    // console.log('we here')
                    navId = navigator.geolocation.watchPosition((pos) => {
                        // console.log('Device Current Coordinates: ', pos);
                        // console.log('Distance: ', getDistanceFromLatLonInM(pos.coords.latitude, pos.coords.longitude, coords.lat, coords.lon));
                        // console.log('Distance bigger than?: ', parseInt(getDistanceFromLatLonInM(pos.coords.latitude, pos.coords.longitude, coords.lat, coords.lon)) > coords.maxDistance)
                        const coordsToSend = {
                            lat: pos.coords.latitude,
                            lon: pos.coords.longitude,
                        };

                        $("input[id^='lat_lng']").val(JSON.stringify(coordsToSend))

                        if (parseInt(getDistanceFromLatLonInM(pos.coords.latitude, pos.coords.longitude, coords.lat, coords.lon)) > coords.maxDistance) {
                            hideButtons();
                        } else {
                            showButtons();
                        }
                    }, (err) => {
                        // console.log('Errored: ', err)
                        hideButtons();
                    });
                }
            }
        });
    }

    if ($("#has-clocking-comments").length > 0) {
        $("#modalApproveClockingComment, #modalNewClockingComment").find('form').on('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const submittedForm = $(e.currentTarget);
            const currentModal = $(submittedForm.parents('.modal')[0]);
            const formData = submittedForm.serializeObject();
            const data = {};

            if (currentModal[0].id != "modalApproveClockingComment") {
                data.forceapproval = (typeof formData.forceapproval !== 'undefined')?formData.forceapproval[0]:0;
                data.validation_id = formData.chooseValidation[0];
                data.schedule_id = (typeof formData.chooseSchedule !== 'undefined')?formData.chooseSchedule[0]:0;
                data.comment = formData.comment[0];
            }

            currentModal.find("#modal-spinner").removeClass('d-none');

            // console.log(data);

            $.ajax({
                url: submittedForm.attr('action'),
                data: data,
                method: submittedForm.attr('method'),
                dataType: 'json',
                success: (response) => {
                    currentModal.find("#modal-spinner").addClass('d-none');
                    currentModal.modal('hide');
                    window.datatable_clockings.ajax.reload(null, false);
                }
            });
        });

        $("#modalEditClockingComment").find('form').on('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const submittedForm = $(e.currentTarget);
            const currentModal = $(submittedForm.parents('.modal')[0]);
            const formData = submittedForm.serializeObject();
            const data = {};

            if (currentModal[0].id != "modalApproveClockingComment") {
                data.forceapproval = (typeof formData.forceapproval !== 'undefined')?formData.forceapproval[0]:0;
                data.validation_id = formData.editchooseValidation[0];
                data.schedule_id = (typeof formData.forceapproval !== 'undefined')?formData.editchooseSchedule[0]:0;
                data.comment = formData.comment[0];
            }

            currentModal.find("#modal-spinner").removeClass('d-none');

            // console.log(data);

            $.ajax({
                url: submittedForm.attr('action'),
                data: data,
                method: submittedForm.attr('method'),
                dataType: 'json',
                success: (response) => {
                    currentModal.find("#modal-spinner").addClass('d-none');
                    currentModal.modal('hide');
                    window.datatable_clockings.ajax.reload(null, false);
                }
            });
        });
    }

    if ($('#timereport-clockings').length > 0 || $('#datatable-clockings-management').length > 0) {

        $('#modalNewClockingComment').on('show.bs.modal', (event) => {
            var dataId = '';
            $.ajax({
                url: '/timereport/getValidations',
                data: "mode=" + document.URL.match(/management/),
                type: 'get',
                dataType: 'json',
                success: function(response){
                    $("#chooseValidation").find('option')
                            .remove()
                            .end()
                            .append('<option value="0">--Validação--</option>')
                            .val('0')
                        ;
                    var len = 0;
                    if(response['data'] != null){
                         len = response['data'].length;
                    }

                    if(len > 0){
                         // Read data and create <option >
                         for(var i=0; i<len; i++){

                              var id = response['data'][i].id;
                              var name = response['data'][i].name;

                              var option = "<option value='"+id+"'>"+name+"</option>";

                              $("#chooseValidation").append(option);
                         }
                    }

                }
            });
            if(document.URL.match(/management/)){
                let pat = /^.*manager-control-team=(.*)$/;
                let arr = pat.exec($(event.relatedTarget).data('url'));

                $.ajax({
                    url: '/timereport/getTeamSchedules/'+arr[1],
                    // data: "mode=" +
                    type: 'get',
                    dataType: 'json',
                    success: function(response){
                        $("#chooseSchedule").find('option')
                                .remove()
                                .end()
                                .append('<option value="0">--Alterar Horário--</option>')
                                .val('0')
                            ;
                        var len = 0;
                        if(response['data'] != null){
                             len = response['data'].length;
                        }

                        if(len > 0){
                             // Read data and create <option >
                             for(var i=0; i<len; i++){

                                  var id = response['data'][i].id;
                                  var name = response['data'][i].name;

                                  var option = "<option value='"+id+"'>"+name+"</option>";

                                  $("#chooseSchedule").append(option);
                             }
                        }

                    }
                });
            }


            if (typeof $(event.relatedTarget).data('id') !== 'undefined') {
                dataId = $(event.relatedTarget).data('id');
            }


            $(event.target).find('#content .body').html("");

            try{
                var pat = document.URL.match(/management/) ? /^.*-(.*){1}\?.*$/ : /^.*-(.*){1}.*$/ ;
                var arr = pat.exec($(event.relatedTarget).data('url'));
                $(event.target).find('#modalDate').html(arr[1].substr(0,4)+'-'+arr[1].substr(4,2)+'-'+arr[1].substr(6,2));
            } catch (e) {};
            $(event.target).find('#forceapproval').prop('checked', false);
            $(event.target).find('#modal-spinner').removeClass('d-none');
            $(event.target).find('form').attr('action', $(event.relatedTarget).data('url'));

        });

        $('#modalClockingComment, #modalApproveClockingComment').on('show.bs.modal', (event) => {
            var dataId = '';
            if (typeof $(event.relatedTarget).data('id') !== 'undefined') {
                dataId = $(event.relatedTarget).data('id');
            }
            $(event.target).find('#content .body').html("");
            $(event.target).find('#modal-spinner').removeClass('d-none');
            $(event.target).find('form').attr('action', $(event.relatedTarget).data('url'));
            $.ajax({
                method: 'POST',
                url: $(event.relatedTarget).data('view-url'),
                data: JSON.stringify({ id: dataId }),
                contentType: 'json',
                success: (response) => {
                    $(event.target).find('#content .body').html(response.content);
                    $(event.target).find('#modalDate').html(response.date);
                    $(event.target).find('#modal-spinner').addClass('d-none');
                },
                error: (jqXHR, status, error) => {
                    $(event.target).find('#modal-spinner').addClass('d-none');
                    alert(error);
                },
                complete: () => {
                    $(event.target).find('#modal-spinner').addClass('d-none');
                },
            });
        });

        $('#modalEditClockingComment').on('show.bs.modal', (event) => {
            var dataId = '';
            $.ajax({
                url: '/timereport/getValidations',
                data: "mode=" + document.URL.match(/management/),
                type: 'get',
                dataType: 'json',
                success: function(response){
                    $("#editchooseValidation").find('option')
                            .remove()
                            .end()
                            .append('<option value="0">--Validação--</option>')
                            .val('0')
                        ;
                    var len = 0;
                    if(response['data'] != null){
                         len = response['data'].length;
                    }

                    if(len > 0){
                         // Read data and create <option >
                         for(var i=0; i<len; i++){

                              var id = response['data'][i].id;
                              var name = response['data'][i].name;

                              var option = "<option value='"+id+"'>"+name+"</option>";

                              $("#editchooseValidation").append(option);
                         }
                    }

                }
            });
            if(document.URL.match(/management/)){
                let pat = /^.*manager-control-team=(.*)$/;
                let arr = pat.exec($(event.relatedTarget).data('url'));
                $.ajax({
                    url: '/timereport/getTeamSchedules/'+arr[1],
                    // data: "mode=" +
                    type: 'get',
                    dataType: 'json',
                    success: function(response){
                        $("#editchooseSchedule").find('option')
                                .remove()
                                .end()
                                .append('<option value="0">--Alterar Horário--</option>')
                                .val('0')
                            ;
                        var len = 0;
                        if(response['data'] != null){
                             len = response['data'].length;
                        }
                        if(len > 0){
                             // Read data and create <option >
                             for(var i=0; i<len; i++){

                                  var id = response['data'][i].id;
                                  var name = response['data'][i].name;

                                  var option = "<option value='"+id+"'>"+name+"</option>";

                                  $("#editchooseSchedule").append(option);
                             }
                        }

                    }
                });
            }
            if (typeof $(event.relatedTarget).data('id') !== 'undefined') {
                dataId = $(event.relatedTarget).data('id');
            }
            $(event.target).find('#content .body').html("");
            $(event.target).find('#forceapproval').prop('checked', false);
            $(event.target).find('#modal-spinner').removeClass('d-none');
            $(event.target).find('form').attr('action', $(event.relatedTarget).data('url'));
            $.ajax({
                method: 'POST',
                url: $(event.relatedTarget).data('view-url'),
                data: JSON.stringify({ id: dataId }),
                contentType: 'json',
                success: (response) => {
                    $("#editchooseValidation").val(response.validation);
                    $(event.target).find('#content .body').html(response.content);
                    $(event.target).find('#modalDate').html(response.date);
                    window.mce[0].editorManager.activeEditor.setContent("");
                },
                error: (jqXHR, status, error) => {
                    $(event.target).find('#modal-spinner').addClass('d-none');
                    alert(error);
                },
                complete: () => {
                    $(event.target).find('#modal-spinner').addClass('d-none');
                },
            });
        });
    }

    if ($("#datatable-clockings-management").length > 0) {

        $(document).on('submit','form#exportExcelForm', function(){
            $('#team').val($('#manager-control-team option:selected').val());
            $('#user').val($('#manager-control-users option:selected').val());
            $('#date').val($('#manager-control-date-field').val());
            e.preventDefault();
        });

        getUsersFromTeam($("#manager-control-team").val(), true, '/clockings/get-users').then((users) => {
            $('#manager-control-users').html(defaultUserOption);
            $('#timesheetDownload #inputUser').html(defaultSingleUserOption);

            users.forEach((user) => {
                $('#manager-control-users').append(`<option value="${user.IdEmployee}-${user.name}"${(user.selected ? ' selected' : '')}>${user.name}</option>`);
                $('#timesheetDownload #inputUser').append(`<option value="${user.id}"${(user.selected ? ' selected' : '')}>${user.name}</option>`);
            });
        });

        $('#modalEditClockingComment').find('.tox.tox-tinymce').addClass('d-none');

        if (typeof urlParams['manager-control-team'] != undefined && urlParams['manager-control-team'] != null) {
            $('#manager-control-team option').each(function () {
                this.value = urlParams['manager-control-team'];
            });
        }
        window.datatable_clockings = $("#datatable-clockings-management").DataTable({
            ajax: {
                url:'/timereport/management',
                data: function(d){
                    return $.extend({}, d, {
                        'team': $('#manager-control-team option:selected').val(),
                        'user' : $('#manager-control-users option:selected').val(),
                        'date' : $('#manager-control-date-field').val()
                    });
                },
                method: 'POST',
                dataType: 'json',

            },
            order: [[ 3, "desc" ]],
            columnDefs: [
                {
                    targets: 'sorting-disabled',
                    orderable: false,
                },
                {
                    targets: 'text-center',
                    className: "text-center",
                },
                {
                    targets: 'limit-w-35',
                    width: '35%',
                },
                {
                    targets: 'limit-w-45',
                    width: '45%',
                },
                {
                    targets: 'limit-w-15',
                    width: '15%',
                },
                {
                    targets: 'limit-w-10',
                    width: '10%',
                },
                {
                    targets: 'limit-w-70px',
                    width: '70px',
                },
                {
                    targets: 'limit-w-40px',
                    width: '40px',
                },
                {
                    targets: 'limit-w-20px',
                    width: '20px',
                },
                {
                    targets: 'limit-w-120px',
                    width: '120px',
                },
                {
                    targets: 'invisible',
                    visible: false,
                },
                //specific
                {
                    targets: 'total-time',
                    createdCell: function (td, cellData, rowData, row, col) {
                        $(td).addClass("total-time");
                    },
                },
                {
                    targets: 'start',
                    createdCell: function (td, cellData, rowData, row, col) {
                        $(td).addClass("start");
                    },
                },
            ],
            bFilter: true,
            bPaginate: false,
            processing: true,
            serverSide: true,
            searching: false,
            columns: [
                {data: 'anomaly', name: 'anomaly', searchable: false, orderable: false, sortable: false},
                {data: 'comment_actions', name: 'comment_actions', sortable: false, orderable: false, searchable: false},
                {data: 'team', name: 'team', searchable: true, sortable: true},
                {data: 'name', name: 'name', searchable: true, sortable: true},
                {data: 'date', name: 'date', searchable: true},
                {data: 'start', name: 'hour', searchable: true, sortable: false},
                {data: 'lunchhour', name: 'lunchhour', sortable: false},
                {data: 'lunchexit', name: 'lunchexit', sortable: false},
                {data: 'exit', name: 'exit', sortable: false},
                {data: 'punchn5', name: 'punchn5', sortable: false},
                {data: 'punchn6', name: 'punchn6', sortable: false},
                {data: 'punchn7', name: 'punchn7', sortable: false},
                {data: 'punchn8', name: 'punchn8', sortable: false},
                {data: 'hours_worked', name: 'hours_worked', sortable: false},
                {data: 'objective_hours', name: 'objective_hours', sortable: false},
                {data: 'schedule_name', name: 'schedule_name', sortable: false},
            ],

            drawCallback: function(settings){

            },
            fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
                if (aData.total_flag) {
                    $('td.total-time', nRow).css('background-color', 'rgb(255, 228, 217)');
                }
                if (aData.delayed_flag) {
                    $('td.start', nRow).css('background-color', 'rgb(255, 228, 217)');
                }
                if (aData.sunday) {
                    $('td', nRow).css('background-color', 'rgb(185, 185, 185)');
                } else if (aData.saturday) {
                    $('td', nRow).css('background-color', 'rgb(255, 245, 222)');
                }

            },
        });

        let t = null;

        $('#datatable-clockings-management').find('thead .filter-col').each((i, el) => {
            $(el).on('change keyup', (evt) => {
                window.datatable_clockings.column(i).search(($(el).is('select') ? $(el).find('option:selected').val() : el.value));
                clearTimeout(t);
                t = setTimeout(() => {
                    window.datatable_clockings.draw();

                }, 500);
            });
        });


    }


    $("#clear-date-field").on('click', function(){
        window.datatable_clockings.draw();
    });

    $('#manager-control-team').on('change', () => {
        getUsersFromTeam($("#manager-control-team").val(), true, '/clockings/get-users').then((users) => {
            $('#manager-control-users').html(defaultUserOption);
            $('#timesheetDownload #inputUser').html(defaultSingleUserOption);

            users.forEach((user) => {
                if(user.personnel_number){
                    $('#manager-control-users').append(`<option value="${user.IdEmployee}-${user.name}"${(user.selected ? ' selected' : '')}>${user.name}</option>`);
                    $('#timesheetDownload #inputUser').append(`<option value="${user.id}"${(user.selected ? ' selected' : '')}>${user.name}</option>`);
                };
            });
        });
    });

    $('#timesheetDownload #inputUser').on('change', (evt) => {
        $('#timesheetDownload').attr('action', $('#timesheetDownload').attr('action').replace(/\/[^\/]*$/, '/' + $(evt.target).val()));
    });
});
