const { ajax } = require("jquery");

$(()=>{
    $("#inputParishAutoComplete").on('input', function(event){
        event.preventDefault()
        
        
        let inputParishAutoComplete = document.getElementById('inputParishAutoComplete').value;
        let inputParishSearch = document.getElementById('InputPlaceholderShow');
        
        const inputParishId = inputParishSearch.value;
        console.log(inputParishId, inputParishAutoComplete);

        if (inputParishAutoComplete == "" || inputParishAutoComplete == null){
            document.getElementById('autocompleteResults').classList.add('invisible');
        }
        if(inputParishSearch) {
            $.ajax({
                url: '/addresses/autocomplete/parishes/'+ inputParishId,
                type: 'GET',
                dataType: 'json',
                success: function(data) {
                    document.getElementById('autocompleteResults').classList.remove('invisible');
                    const selectElement = document.getElementById('autocompleteResults');
                        selectElement.innerHTML = '';

                        // Populate the select element with new options
                        data.forEach(item => {
                            if (item.full_street_designation.includes(inputParishAutoComplete)){
                                const option = document.createElement('option');
                                option.value = item.ART_COD;
                                option.text = item.full_street_designation;
                                selectElement.appendChild(option);
                            }
                        });

                },
                error: function(xhr, status, error) {
                    console.error('Error:', error);
                }
            });
        }
    })
}); 