$( () => {
    if($('#allvehicles').length > 0){

        function generateRandomColors(numColors, alpha) {
            const colors = [];
            for (let i = 0; i < numColors; i++) {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                colors.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
            }
            return colors;
        }

        $.ajax({
            url: '/fleets/vehicle/perproperty',
            type:'GET',
            contentType: 'JSON',
            success: function(success){

                let data = new Array();
                let DashboardData = new Array();
                let labels = new Array();
                let total = 0

                //const backgroundColor =  ['rgba(43, 132, 99, 0.5)', 'rgba(243, 124, 0, 0.5)', 'rgba(255, 10, 255, 0.5)', 'rgba(0, 0, 255, 0.5)']
                //const borderColor = ['rgba(43, 132, 99, 1)', 'rgba(243, 124, 0, 1)', 'rgba(255, 10, 255, 0.3)', 'rgba(0, 0, 255, 1)']

                success.forEach((element, index) => {
                    !labels.includes(element.vehicle_property) ? labels[index] = element.vehicle_property : ''
                    data[index] = element.vehicles;
                    total = total + element.vehicles;
                });

                const numColors = data.length;
                const backgroundColor = generateRandomColors(numColors, 0.5);
                const borderColor = backgroundColor.alpha = 1

                data.forEach( (item, index) => {
                    DashboardData[index] = {
                                        label: labels[index],
                                        backgroundColor: backgroundColor,
                                        borderColor: borderColor,
                                        borderWidth: 1,
                                        /* data: [item], */
                                        order: Number(index),
                                        fill: false,
                                    }
                })


                document.getElementById('totalVehicles').innerHTML = total
                const ctx = document.getElementById('allvehicles');
                new Chart(ctx, {
                    type: 'bar',
                    data: {
                      labels: labels,
                      datasets: [{
                        label: '',
                        data: data,
                        backgroundColor: backgroundColor,
                        borderColor: backgroundColor.alpha = 1,
                        hoverOffset: 4,
                      }]
                    },
                    options: {
                      scales: {
                        y: {
                          beginAtZero: true
                        }
                      }
                    }
                  });
            },
            error: function(xhr){
                //console.log(xhr);
            }
        })
    }
})
