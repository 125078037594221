$( () => {

    if($('#equipmentForm').length > 0){




        const inputContracts = document.getElementById('contract')




        $('#inputPropriety').on('change', function(event){

            event.preventDefault()

            if(this.checked) {

                if(inputContracts.style.display == "block"){

                    inputContracts.value = ""

                    inputContracts.style.display = "none"




                    console.log(inputContracts.value);

                }

            }




             if(!this.checked) {
                if(inputContracts.style.display == "none"){
                    inputContracts.value = ""
                    inputContracts.style.display = "block"
                }

            }

        })

    }

})
