
  $( () => {

        $('.custom-editor').on('keyup', (event) => {
            alert('Aqui');
            event.preventDefault()
            const editor =  document.getElementsByClassName('.custom-editor')
            console.log('editor');
        })

/*         $('#text').keyup(function() {
            var keyed = $(this).val().replace(/\n/g, '<br/>');
            $("#target").html(keyed);
        }); */
  })
