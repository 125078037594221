$( () => {
    if($('#updateUser').length > 0){

        const inputDriverLicence= document.getElementById('DriverLicence')
        const inputDriverLicenceValidate= document.getElementById('DriverLicenceValidate')
        const inputVehicle= document.getElementById('DriverVehicle')
        const inpuDriver = $("#inpuDriver").attr("checked") ? 1 : 0;

        if (inpuDriver == '1')
        {
            if(inputDriverLicence.style.display == "none"){
                inputDriverLicence.value = ""
                inputDriverLicence.style.display = "block"
                inputDriverLicenceValidate.value = ""
                inputDriverLicenceValidate.style.display = "block"
                inputVehicle.value = ""
                inputVehicle.style.display = "block"
            }
        } else{

            if(inputDriverLicence.style.display == "block"){
                inputDriverLicence.value = ""
                inputDriverLicence.style.display = "none"
                inputDriverLicenceValidate.value = ""
                inputDriverLicenceValidate.style.display = "none"
                inputVehicle.value = ""
                inputVehicle.style.display = "none"
            }
        }

        $('#inpuDriver').on('change', function(event){
            event.preventDefault()

            if(this.checked) {
                if(inputDriverLicence.style.display == "none"){
                    inputDriverLicence.value = ""
                    inputDriverLicence.style.display = "block"
                    inputDriverLicenceValidate.value = ""
                    inputDriverLicenceValidate.style.display = "block"
                    inputVehicle.value = ""
                    inputVehicle.style.display = "block"
                }
            }

            if(!this.checked) {
                if(inputDriverLicence.style.display == "block"){
                    inputDriverLicence.value = ""
                    inputDriverLicence.style.display = "none"
                    inputDriverLicenceValidate.value = ""
                    inputDriverLicenceValidate.style.display = "none"
                    inputVehicle.value = ""
                    inputVehicle.style.display = "none"
                }
            }
        })
    }
})
