$( () => {

    $('#openLegaModal').on('click', (event) => {
        event.preventDefault()
        $.ajax({
            url: '/ilicits/occurrencestatus/getStatus/',
            type: 'GET',
            data: {to_use_in: 'legal validation'},
            enctype: 'json',
            success: (success) => {
                let option = `<option value="">Selecionar</option>`
                success.forEach(element => {
                    option += `<option value="${element.id}">${element.occurrence_status_designation}</option>`
                });

                $('#inputValidationStatus').html(option)
            },
            error: (xhr) => {

            }
        })
    })



    $('#envolveprocess').on('click', (event) => {
        event.preventDefault()
        const criminalprocess  = $('#criminalcomplaint').prop('checked')
        const misdemeanor  = $('#misdemeanor').prop('checked')
        const occurrenceID = document.getElementById('occurrence').value

        if(criminalprocess || misdemeanor){
            $.ajax({
                url: '/ilicits/occurrences/classify/',
                type: 'GET',
                data: { occurrenceID: occurrenceID, criminalprocess: criminalprocess, misdemeanor: misdemeanor},
                enctype: 'json',
                success: (success) => {
                    document.getElementById('errorMessage').style.display = 'none'
                    document.getElementById('sucessMessage').style.display = 'block'
                    setTimeout(function() {
                        document.getElementById('sucessMessage').style.display = 'none';
                    }, 3000);

                    location.reload()
                },
                error: (xhr) => {

                }
            })
        } else{
            document.getElementById('errorMessage').style.display = 'block'
            document.getElementById('sucessMessage').style.display = 'none'

            setTimeout(function() {
                document.getElementById('errorMessage').style.display = 'none';
            }, 10000);
        }
    })


    $('#editLegaModal').on('click', (event) => {
        event.preventDefault()
        const occurrenceID = document.getElementById('occurrence').value
        let communicationNumber = document.getElementById('communicationNumber')
        let indentificationCode = document.getElementById('indentificationCode')
        let authorityCode = document.getElementById('authorityCode')
        let communicatedOn = document.getElementById('communicatedOn')
        let legalProcessNote = document.getElementById('legalprocessNote')

        $.ajax({
            url: `/ilicits/legalprocess/getByOccurence/`,
            type: 'GET',
            data: { occurrence: occurrenceID},
            enctype: 'json',
            success: (success) => {

                let option = `<option value="" selected>Selecional</option>`
                success[1].forEach(item => {
                    option += `<option value="${item.id}">${item.legal_entity_designation}</option>`
                })

                let processStatus = ""

                setTimeout( () => {
                    success[0].forEach(item => {
                        indentificationCode.value = item.identification_code
                        authorityCode.value = item.communication_to_the_entity
                        communicationNumber.value = item.communication_number
                        communicatedOn.value = item.communicated_on
                        legalProcessNote.innerHTML  = item.process_note
                        item.legal_entity_id != null ? option += `<option value="${item.legal_entity_id}" selected>${item.legal_entity_designation}</option>` : null
                        item.occurrence_status_designation != null ? processStatus = `<option value="${item.validation_status}" selected>${item.occurrence_status_designation}</option>` : null
                        $('#legalEntity').html(option)
                        processStatus != '' ? $('#validationStatus').html(processStatus) : ''
                    }),
                500})

            },
            error: (xhr) => {

            }
        })
    })
})
