


const timeOut = 60000*5;

$( () => {

    if($('#createManual').length > 0 ){
        $('#btnSaveDraft').on('click', (event) => {
            event.preventDefault()
            document.getElementById('createManual').submit()
        })

        $('#btnNoSaveDraft').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                $('#draftSaveModal').modal('show')
            }, timeOut)
        })
    }
})


export function saveDraft(content){
    handleData(content)
}


function handleData(content){
    if(content.length > 2000){
        $('#draftSaveModal').modal('show')
    }
}

