$( () => {

    $('#occurenceBudget').on('change', (event) => {
        event.preventDefault();
        const occurenceBudget = document.getElementById(`occurenceBudget`).value
        if(occurenceBudget){
            $.ajax({
                url:`/ilicits/occurrences/show/${occurenceBudget}`,
                type: 'GET',
                enctype: 'json',
                success: (success) => {

                    const road = document.getElementById(`inputRoad`)
                    const parish = document.getElementById(`inputParish`)
                    const inputService = document.getElementById(`inputService`)

                    if(road){

                        road ? road.value = success.occurrence_location : ''
                        road ? parish.value = success.parish : ''
                        road ? inputService.value = success.type_designation : ''

                        $( "#inputRoad" ).prop( "disabled", true );
                        $( "#inputParish" ).prop( "disabled", true );
                        $( "#inputService" ).prop( "disabled", true );
                    }
                },
                error: (xhr) => {

                }
            })
        }
    })

})
