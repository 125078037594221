//const { isEmpty } = require("lodash");

$(() => {
    if($('#loadinvoicesdata').length > 0){
        $("#loadinvoices").click( (event) =>{

            event.preventDefault();

            const fileInput = document.getElementById('monthlyInvoice');
            const shoWContentMessage = document.getElementById('messagesToSend');
            const feedback = document.getElementById('feedback');
            const loading = document.getElementById('loading');
            const newInovoinces = document.getElementById("newUploadFile")
            const loadinvoices = document.getElementById('loadinvoices')

            loading.style.display = "block"

            const formData = new FormData();

            formData.append("monthlyInvoice", fileInput.files[0]);
            loadinvoices.disabled = "true";

            $.ajax({
                url: '/sms/loadmonthlyinvoice',
                method: "POST",
                data: formData,
                processData: false,
                contentType: false,

                success: function(data){

                    if(shoWContentMessage.style.display === "none"){

                        shoWContentMessage.style.display = "block"
                        $('#titleMessagesToSend').html(`Total de Faturas: ${ data[2] }`)

                        if(data[3] > 0 && data[3] < data[2]){

                            if(feedback.classList.contains('alert-success'))
                                feedback.classList.toggle('alert-warning')
                            feedback.style.display = "block"
                            loading.style.display = "none"
                            $('#successeToregisted').html(`${data[3]}  de ${data[2]} mensagens não serão enviadas por contactos inválidos.`)
                            $('#iconFeedback').html('<i class="fas fa-exclamation-triangle"></i>')
                            newInovoinces.style.display = "block"
                        }

                        if(data[1] == data[2] ){
                            feedback.style.display = "block"
                            loading.style.display = "none"

                            if(data[1] == 1){
                                $('#successeToregisted').html(`${data[1]} Fatura será enviada`)
                                $('#iconFeedback').html('<i class="fas fa-check-circle"></i>')
                                newInovoinces.style.display = "block"

                            } else{
                                $('#successeToregisted').html(`${data[1]} Faturas serão enviadas`)
                                $('#iconFeedback').html('<i class="fas fa-check-circle"></i>')
                                newInovoinces.style.display = "block"
                            }
                        }


                        if((data[1] == 0 && data[2] == 0) || (data[1] == 0 && data[2] > 0)){
                            if(feedback.classList.contains('alert-success'))
                                feedback.classList.toggle('alert-danger')
                            feedback.style.display = "block"
                            loading.style.display = "none"
                            $('#successeToregisted').html(`Nenhuma Fatura foi carregada no sistema. Certifique-se de estar a carregar novas Faturas.`)
                            $('#iconFeedback').html('<i class="fas fa-times-circle"></i>')
                            newInovoinces.style.display = "block"
                        }

                    }
                },
                error: function(xhr, status, error) {
                    var responseText = xhr.responseText;
                    if (responseText) { // Verifica se há uma resposta do servidor
                      try {
                        var response = JSON.parse(responseText); // Tenta fazer o parse da resposta como JSON
                        if (shoWContentMessage.style.display === "none") {
                            shoWContentMessage.style.display = "block";
                            if (feedback.classList.contains('alert-success')) {
                                feedback.classList.toggle('alert-danger');
                          }
                          feedback.style.display = "block";
                          loading.style.display = "none";
                          $('#titleMessagesToSend').html(`Erro ao tentar carregar o ficheiro!`);
                          $('#successeToregisted').html(JSON.parse(xhr.responseText).message);
                          $('#iconFeedback').html('<i class="fas fa-times-circle"></i>');
                          newInovoinces.style.display = "block"
                        }
                      } catch (error) {
                        /* console.log("Erro ao fazer o parse da resposta JSON:", error); */
                      }
                    } else {
                        /* console.log("Resposta vazia do servidor"); */
                        if (shoWContentMessage.style.display === "none") {
                                shoWContentMessage.style.display = "block";
                            if (feedback.classList.contains('alert-success')) {
                                feedback.classList.toggle('alert-danger');
                            }
                            feedback.style.display = "block";
                            loading.style.display = "none";
                            $('#titleMessagesToSend').html(`Erro ao tentar carregar o ficheiro!`);
                            $('#successeToregisted').html("Erro no sistema. Por favor, tente mais tarde ou contacte a linha de Suporte");
                            $('#iconFeedback').html('<i class="fas fa-times-circle"></i>');
                            newInovoinces.style.display = "block"
                        }
                    }
                    /* console.log(JSON.parse(xhr.responseText).message); */
                  }
            });

            $("#yes_Send").click((event) =>{
                event.preventDefault();
                location.reload()
            })

            $("#not_send").click((event) =>{
                event.preventDefault();
                window.location.href = "/sms/listsms";
            })
        });
    }
});
