$(() => {
    if($("#asset-create, #asset-edit").length > 0){
        // const picturesTemplate = $("#pictures").find("#template").clone(true, true).attr("id", null);

        $("#inputDelegation").on("change", function () {
            let delegation_id = $(this).val();

            $.ajax({
                url: "/delegations/users/" + delegation_id + "?accountable=true&null_option=true",
                method: "get",
                success: function (data) {
                    $("#inputAccountableUser").html(data.content);
                }
            });

            $.ajax({
                url: "/delegations/users/" + delegation_id,
                method: "get",
                success: function (data) {
                    $("#inputAssignee").html(data.content);
                }
            });
        });

        if ($("#inputHasWarranty")[0].checked) {
            $("#inputWarrantyStart, #inputWarrantyDuration").parents('.form-group').removeClass('invisible');
        } else {
            $("#inputWarrantyStart, #inputWarrantyDuration").parents('.form-group').addClass('invisible');
        }

        $('#inputHasWarranty').on('change', (e) => {
            if (e.target.checked) {
                $("#inputWarrantyStart, #inputWarrantyDuration").parents('.form-group').removeClass('invisible');
            } else {
                $("#inputWarrantyStart, #inputWarrantyDuration").parents('.form-group').addClass('invisible');
            }
        });


        //}

        // $("#addPicture").on('click', (e) => {
        //     e.preventDefault();
        //     $("#pictures").append(picturesTemplate);
        //     $("#removePicture").on('click', function(e) {
        //         e.preventDefault();
        //         $(this).parent('div').remove();
        //     });
        // });

        // $("#removePicture").on('click', (e) {
        //     e.preventDefault();
        //     $(e.target).parent('div').remove();
        // });
    }

    if($("#asset-print-label").length > 0){
        //console.log('printing');
        window.addEventListener('load', function () {
            window.print();
        })
    }

    if($("#asset-edit").length > 0){
        const floatingdiv = document.getElementById('asset-accounting-info');
        const asset_id = /[^/]*$/.exec(window.location.href)[0];
        // alert(window.location.href);
        $.ajax({
            url: "/assets/refresh/" + asset_id,
            method: "get",
            success: function (data) {
                floatingdiv.innerHTML = data;
                //console.log(data);
            }
        });

        $("#asset-tag-print").on("click", function (e) {
            //console.log(e.currentTarget);
            $.ajax({
                type: "GET",
                url: "/assets/print/"+ asset_id,
                //data: { "asset_id": asset_id },
                dataType: "html",
                success: function (html) {
                    w = window.open(window.location.href,"_blank");
                    w.document.open();
                    w.document.write(html);
                    w.document.close();
                    // w.window.addEventListener('load', function () {
                    //     w.window.print();
                    //   })
                },
                error: function (data) {
                    console.log("Error:", data);
                }
            });
        });

        $("#asset-clone").on("click", function (e) {
            //console.log(e.currentTarget);
            $.ajax({
                type: "GET",
                url: "/assets/clone/"+ asset_id,
                //data: { "asset_id": asset_id },
                dataType: "html",
                success: function (data){
                    window.location.href = "/assets/edit/" + data;
                },
                error: function (data) {
                    console.log("Error:", data);
                }
            });
        });

        $("#asset-assignment-document").on("click", function (e) {
            //console.log(e.currentTarget);
            $.ajax({
                type: "GET",
                url: "/assets/pdfassignment/"+ asset_id,
                //data: { "asset_id": asset_id },
                // dataType: "html",
                // success: function (html) {
                //     w = window.open(window.location.href,"_blank");
                //     w.document.open();
                //     w.document.write(html);
                //     w.document.close();
                //     // w.window.addEventListener('load', function () {
                //     //     w.window.print();
                //     //   })
                // },
                xhrFields: {
                    responseType: 'blob'
                },
                success: function(response){
                    var blob = new Blob([response]);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "IM103 - Recepcao de equipamentos e materiais_Ed_02-" + (new Date()).toISOString().split('T')[0] + ".pdf";
                    link.click();
                },
                error: function(blob){
                    console.log(blob);
                }
            });
        });

        $("#asset-return-document").on("click", function (e) {
            //console.log(e.currentTarget);
            $.ajax({
                type: "GET",
                url: "/assets/pdfreturn/"+ asset_id,
                //data: { "asset_id": asset_id },
                dataType: "html",
                success: function (html) {
                    w = window.open(window.location.href,"_blank");
                    w.document.open();
                    w.document.write(html);
                    w.document.close();
                    // w.window.addEventListener('load', function () {
                    //     w.window.print();
                    //   })
                },
                error: function (data) {
                    console.log("Error:", data);
                }
            });
        });

        // const picturesTemplate = $("#pictures").find("#template").clone(true, true).attr("id", null);
        $("#asset-accounting-refresh").on('mouseenter', floatingdiv, function() {
            floatingdiv.style.display = "block";
        }).on('mouseleave', floatingdiv, function() {
            floatingdiv.style.display = "none";
        });
        $("#asset-generate-password").on("click", function(){
            let asset_id = /[^/]*$/.exec(window.location.href)[0];
            // alert(window.location.href);

            $.ajax({
                url: "/assets/generatepassword/" + asset_id,
                method: "get",
                success: function (data) {
                    $('#inputHDpassword').val(data);
                }
            });
        });
    }

    if($('#AssetsObsoleteByContract').length > 0){
        //alert('ola');
        function generateRandomColors(numColors, alpha) {
            const colors = [];
            for (let i = 0; i < numColors; i++) {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                colors.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
            }
            return colors;
        }

        $.ajax({
            url: '/assets/get-assets-obsolete-by-contract',
            type:'GET',
            contentType: 'JSON',
            success: function(chartData){

                var datasets = [];
                var colors = [];
                var labels = chartData[1];

                for (delegation in chartData[0]) {
                    //console.log(delegation);
                    let color = null;

                    do {
                        color = getRandomColor()
                    } while(colors.includes(color))

                    colors.push(color);

                    datasets.push({
                        fill: false,
                        type: 'line',
                        borderWidth: 1,
                        backgroundColor: color,
                        borderColor: color,
                        label: delegation,
                        data: chartData[0][delegation],
                    });


                }

                do {
                    color = getRandomColor()
                } while(colors.includes(color))
                colors.push(color);

                datasets.push({
                    borderWidth: 1,
                    backgroundColor: color,
                    borderColor: color,
                    label: 'Total',
                    data: chartData[2],
                })
                console.log(datasets);

                document.getElementById('AssetsObsoleteTotal').innerHTML = chartData[3];
                const ctx = document.getElementById('AssetsObsoleteByContract');
                const chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: datasets,
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                },
                            }],
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'black',
                                anchor: 'end',
                                align: 'top'
                            }
                        }
                    },

                });


            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    }

    if($('#AssetsStatusByContract').length > 0){
        //alert('ola');
        function generateRandomColors(numColors, alpha) {
            const colors = [];
            for (let i = 0; i < numColors; i++) {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                colors.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
            }
            return colors;
        }

        $.ajax({
            url: '/assets/get-assets-status-by-contract',
            type:'GET',
            contentType: 'JSON',
            success: function(chartData){

                var datasets = [];
                var colors = [];
                var labels = chartData[1];

                for (delegation in chartData[0]) {
                    //console.log(delegation);
                    let color = null;

                    do {
                        color = getRandomColor()
                    } while(colors.includes(color))

                    colors.push(color);

                    datasets.push({
                        fill: false,
                        type: 'line',
                        borderWidth: 1,
                        backgroundColor: color,
                        borderColor: color,
                        label: delegation,
                        data: chartData[0][delegation],
                    });


                }

                do {
                    color = getRandomColor()
                } while(colors.includes(color))
                colors.push(color);

                datasets.push({
                    borderWidth: 1,
                    backgroundColor: color,
                    borderColor: color,
                    label: 'Total',
                    data: chartData[2],
                })
                console.log(datasets);

                document.getElementById('AssetsStatusTotal').innerHTML = chartData[3];
                const ctx = document.getElementById('AssetsStatusByContract');
                const chart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: datasets,
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                },
                            }],
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'black',
                                anchor: 'end',
                                align: 'top'
                            }
                        }
                    },

                });


            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    }

});
