$( () => {
    if($('.modal-body').length > 0){

        if(('#icilitprocess').length > 0){

            $(document).on("change", '#inputBillingStates', function(event) {
                event.preventDefault()
                const invoicepayments = document.getElementById('invoicepayments')
                const settlementDate = document.getElementById('settlementDate')

                if($('#inputBillingStates option:selected').text() == 'Pago por fracção em acordo'){
                    invoicepayments.style.display = 'block'
                    settlementDate.style.display = 'none'
                } else{
                    invoicepayments.style.display  = 'none'
                    settlementDate.style.display = 'block'
                }
            })

            $('#createInvoice').on('click', (event) => {
                event.preventDefault()
                $('#createPaymentModal').modal('show');
            })
        }


        $(document).on("change", '#inputBillingStates', function(event){
            event.preventDefault()
            const billingstate = document.getElementById('inputBillingStates').value;
            if($('#inputBillingStates option:selected').text() == 'Fatura liquidada'){
                data.stepChaged = Number(billingstate)
            } else{
                data.stepChaged = Number(document.querySelector('.currentstep').id) + 1
            }
        })


        //HANDLE WITH BILLING INPUT
        $(document).on("change", '#inputBillingStates', function(event) {
            event.preventDefault()
            const billingStatus = document.getElementById('inputBillingStates')
            const billingErrorMessage = document.getElementById('billingErrorMessage')

            billingStatus.value == '' ? billingErrorMessage.innerHTML = 'Este campo é obrigatório' : billingErrorMessage.innerHTML = ''

            if(billingStatus == ''){
                document.getElementById('checkErrors').style.display = 'block'
                document.getElementById('validate').style.display = 'none'
            } else{
                document.getElementById('checkErrors').style.display = 'none'
                document.getElementById('validate').style.display = 'block'
            }
        })

    }
})
