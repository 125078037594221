$( () => {

    $(document).on("click", '#autorForm', function(event){
        event.preventDefault()

        const authorID = document.getElementById('authorID').value

        $.ajax({
            url:`/ilicits/author/getAll`,
            type: 'GET',
            enctype: 'json',
            success: (success) => {

                if (success) {
                    $('#recurrence').prop('checked', true);
                    let option = "<option value=''>Selecionar</option>";
                    success.forEach((element) =>{
                        option += `<option value=${element.id} ${authorID == element.id ? 'Selected' : ''}>${element.author_fullname}</option>`
                    })

                    $('#altereauthorName').html(option)

                } else {
                    $('#recurrence').prop('checked', false);
                    document.getElementById("altinstalationNumber").value = ""
                    document.getElementById("altNIF").value = ""
                    document.getElementById("altPolice").value = ""
                    document.getElementById("altAuthorcomment").value = ""
                }
            },
            error: (xhr) => {

            }
        })
    })





    $('#altereauthorName').on("change", function(event){
        event.preventDefault()

        const authorID = document.getElementById('altereauthorName').value

        $.ajax({
            url:`/ilicits/author/show/${authorID}`,
            type: 'GET',
            enctype: 'json',
            success: (success) => {

                if (success) {
                    $('#recurrence').prop('checked', true);
                    document.getElementById("altinstalationNumber").value = success.instalation_number
                    document.getElementById("altNIF").value = success.author_NIF
                    document.getElementById("altPolicy").value = success.author_policy
                    document.getElementById("altAuthorcomment").value = success.additional_informations
                } else {
                    $('#recurrence').prop('checked', false);
                    document.getElementById("altinstalationNumber").value = ""
                    document.getElementById("altNIF").value = ""
                    document.getElementById("altPolicy").value = ""
                    document.getElementById("altAuthorcomment").value = ""
                }
            },
            error: (xhr) => {

            }
        })
    })
})
