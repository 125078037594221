const { error } = require("jquery");

$(() => {
    $(document).on('click', '#sortUp', function(event) {
        event.preventDefault();
        const sortUp = this.closest('#sortUp').querySelector('input');
        const sortID = $(this).closest('tr').find('.edit').attr('id');

            if (sortUp.value > 1) {            
                const previousOrder = parseInt(sortUp.value) - 1;

                /* alert(previousOrder); */
                
                $.ajax({
                    url: '/timereport/validations/getID',
                    type: 'GET',
                    data: { 'order': previousOrder },
                    contentType: 'json',
    
                    success: function(response) {
                        /* console.log('ID Anterior: ' + response[0]);
                        console.log('ID Atual: ' + sortID); */

                        const data = {
                            currentID: Number(sortID),
                            currentOrder: sortUp.value - 1,
                            changedOrder: previousOrder + 1,
                            changedOrderID: response[0]
                        }

                        console.log(data);

                        $.ajax({
                            crossDomain: true,
                            url: '/timereport/validations/updateOrderValidation',
                            type: 'POST',
                            data: data,
                            success: function(response) {
                                location.reload();
                                /* console.log(response); */
                            },
                            error: function(xhr, status, error) {
                                console.log('Erro: ', error)
                            }
                        });
                    }, 
                    
                    error: function(xhr, status, error) {
                        console.log('Erro: ', error);
                    }
                });
            }
    });

    $(document).on('click', '#sortDown', function(event) {
        event.preventDefault();
        const sortDown = this.closest('#sortDown').querySelector('input');
        const sortID = $(this).closest('tr').find('.edit').attr('id');

        $.get('/timereport/validations/getAllOrderID', function(response) {
     
            if(sortDown.value < response[response.length - 1])
            {
                const nextOrder = parseInt(sortDown.value) + 1;

                $.ajax({
                    url: '/timereport/validations/getID',
                    type: 'GET',
                    data: { 'order': nextOrder },
                    contentType: 'json',

                    success: function(response) {
                        /* console.log('ID atual: ' + sortID);
                        console.log('ID seguinte: ' + response[0]); */

                        const data = {
                            currentID: Number(sortID),
                            currentOrder: Number(sortDown.value) + 1,
                            changedOrder: nextOrder - 1,
                            changedOrderID: response[0]
                            
                        }

                        console.log(data);

                        $.ajax({
                            crossDomain: true,
                            url: '/timereport/validations/updateOrderValidation',
                            type: 'POST',
                            data: data,
                            success: function(response) {
                                location.reload();
                                /* console.log(response); */
                            },
                            error: function(xhr, status, error) {
                                    console.log('Erro: ', error)
                            }
                        });
                    },

                    error: function(xhr, status, error) {
                        console.log('Erro: ', error);
                    }
                });

                
            }
        });
        
    });
});
