
$(() => {
  if ($('#rentalCost').length > 0) {

    const canvas = document.getElementById('rentalCost');
    let chart = ""

    $.ajax({
      url: `/fleets/rentalcost/`,
      type: 'GET',
      contentType: 'JSON',
      success: function (success) {

        const annualCost = new Array();
        const monthlyCost = new Array();
        const contract = new Array();

        let totalAnnualCost = 0;

        success.forEach((item, index) => {

          monthlyCost[index] = item.annula_value;
          annualCost[index] = item.annula_value * 12;
          contract[index] = item.contract;

          totalAnnualCost = totalAnnualCost + item.annula_value * 12
        });


        document.getElementById('totalrentaCost').innerHTML = Math.round(totalAnnualCost);
        const ctx = canvas.getContext('2d');
        chart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: contract,
            datasets: [{
              label: 'Valor mensal por Contrato',
              data: monthlyCost,
              backgroundColor: 'rgba(39, 123, 245, 0.3)',
              borderColor: 'rgba(39, 123, 245, 1)',
              borderWidth: 1
            },
            {
              type: 'bar',
              label: 'Valor anual por Contrato',
              data: annualCost,
              fill: true,
              backgroundColor: 'rgba(43, 132, 99, 0.1)',
              borderColor: 'rgb(43, 132, 99, 1)',
              borderWidth: 1
            },
            ]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      },
      error: function (xhr) {
        console.log(xhr);
      }
    })

    canvas.onclick = (evt) => {
      const res = chart.getElementAtEvent(evt);
      if (res.length === 0) {
        return;
      }
      alert('You clicked on ' + chart.data.labels[res[0]._index]);
    };

  }
})


