
 $( () => {

    $("#optionsDlegationUsers").on('change', function(event){
        event.preventDefault()
        const accountableID = document.getElementById('optionsDlegationUsers').value;
        const delegationID= document.getElementById('inputDelegation').value;
        const costCenterAccountable = document.getElementById('inputCostCenters');

        const alertCostCenter = document.getElementById('alertCostCenter');
        const alertNoCostCenter = document.getElementById('alertNoCostCenter')
        const alertNoUsers = document.getElementById('alertNoUsers')
        const alertAllCostCenterAccountable = document.getElementById('alertAllCostCenterAccountable')

        const delegationDesignation =  $('#inputDelegation option:selected').text()
        const accountableName =  $('#optionsDlegationUsers option:selected').text()

        alertCostCenter.style.display == 'block' ? alertCostCenter.style.display = 'none' : ''

        if(alertNoUsers){
            alertNoUsers.style.display == 'inline-block' ?  alertNoUsers.style.display == 'none' : ''
        }

        if(alertNoCostCenter){
            alertNoCostCenter.style.display == 'inline-block' ?  alertNoCostCenter.style.display == 'none' : ''
        }

        if(alertAllCostCenterAccountable){
            alertAllCostCenterAccountable.style.display == 'inline-block' ?  alertAllCostCenterAccountable.style.display == 'none' : ''
        }

        $.ajax({
            url: `/costCenters/accountableCostCenters/${accountableID}`,
            type: 'GET',
            data: {delegationID: delegationID},
            success:function(data){

                let costCenters = "";
                let countCostCenter = 0

                if(data[0].length > 0){

                    data[0].forEach( (item) => {
                        let iscurrentAccountables = false
                        if(data[1]){
                            data[1].forEach( (element) => {
                                element.cost_center_id == item.id ? iscurrentAccountables = true : null
                            })
                        }

                        if(iscurrentAccountables==false){
                            countCostCenter = countCostCenter + 1
                            costCenters += `
                                            <div class="form-check costom-inputs">
                                                <input class="form-check-input" type="checkbox" value="${ item.id }" name="inputCostCenters[]" id="inputCostCenters">
                                                <label class="form-check-label" for="defaultCheck1">${ item.cost_center_designation }</label>
                                            </div>
                                        `
                        }
                    })

                    if(countCostCenter == 0){
                        alertCostCenter.style.display = 'block'
                        alertAllCostCenterAccountable.style.display = 'inline-block'
                        alertNoCostCenter.style.display = 'none'
                        alertNoUsers.style.display = 'none'
                        $('#accountableName').html(accountableName)
                        $('#delegationName').html(delegationDesignation)
                    }

                } else {
                    alertCostCenter ? alertCostCenter.style.display = 'block' : ''
                    alertNoCostCenter.style.display = 'inline-block'
                    alertAllCostCenterAccountable ? alertAllCostCenterAccountable.style.display = 'none' : ''
                    alertNoUsers ? alertNoUsers.style.display = 'none' : ''
                    $('#delegationName').html(delegationDesignation)
                }

                costCenterAccountable.innerHTML = costCenters;
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    })
 })
