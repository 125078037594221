
$( () => {
    if($('#vehicleDasboard').length > 0){


        function generateRandomColors(numColors, alpha) {
            const colors = [];
            for (let i = 0; i < numColors; i++) {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                colors.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
            }
            return colors;
        }

        const backgroundColor =  ['rgba(43, 132, 99, 0.5)', 'rgba(243, 124, 0, 0.5)', 'rgba(255, 10, 255, 0.5)', 'rgba(0, 0, 255, 0.2)']
        const borderColor = ['rgba(43, 132, 99, 1)', 'rgba(243, 124, 0, 1)', 'rgba(255, 10, 255, 0.3)', 'rgba(0, 0, 255, 1)']

        const vehicle = document.getElementById('inpuVehicle').value;
        $.ajax({
            url: `/fleets/vehicle/getKms/${vehicle}`,
            type:'GET',
            contentType: 'JSON',
            success: function(success){

                const data = [success[1].km_contracted, success[0].hits_predicted, success[0].km_accomplished];
                const desvietionData = [success[0].deviations];

                success[1].km_contracted == null ? document.getElementById('kmCompletedDasboard').style.display = "none" : ''
                success[0].deviations == null ? document.getElementById('desvietionDataDasboard').style.display = "none" : ''

                const ctx = document.getElementById('contractedVsCompleeted');
                new Chart(ctx, {
                  type: 'doughnut',
                  data: {
                    labels: ['Contratados', 'Esperados', 'Realizados'],
                    datasets: [{
                      label: 'Custo por mês',
                      data: data,
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)'
                      ],
                      hoverOffset: 4,
                    }]
                  },
                  options: {
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }
                });


                const desvietion = document.getElementById('desvietionData').getContext('2d');

                desvietion.height = 100;
                /* desvietion.style.height = '200px'; */

                const plugin = {
                  id: 'desvietionData',
                  beforeDraw: (chart, args, options) => {
                    const {ctx} = chart;
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = options.color || '#99ffff';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                  }
                };


              const result = new Chart(desvietion, {
                  type: 'horizontalBar',
                  data: {
                      labels: ['Desvio'],
                      datasets: [{
                          label: `${desvietionData}%`,
                          data: desvietionData,
                          backgroundColor: 'rgba(54, 162, 235)',
                          borderColor: 'rgba(54, 162, 235, 1)',
                          borderWidth: 1
                      }]
                  },
                  options: {
                    scales: {
                      xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: -100,
                            max: 100
                        }
                      }]
                    }
                  },

                  /* plugins: [plugin], */
              });



                let montlyCost = new Array();
                let totalPrice = new Array();
                let labels = new Array();
                let costData = new Array();

                success[2].length < 1 ? document.getElementById('interventionDataDasboard').style.display = "none" : ''

                success[2].forEach((element, index) => {
                    labels[index] = element.month
                    montlyCost[index] = element.totalPerMonth

                    totalPrice = Number(totalPrice) + Number(element.totalPerMonth)

     /*                costData[index] = {
                                        label: labels[index],
                                        backgroundColor: backgroundColor[index],
                                        borderColor:  borderColor[index],
                                        borderWidth: 1,
                                        data: montlyCost[index],
                                        order: Number(index)+1,
                                        type: 'bar',
                                    } */

                });


                const intervention = document.getElementById('interventionData').getContext('2d');

                const numColors = success[2].length;
                const backgroundColor = generateRandomColors(numColors, 0.5);


                const newIntervention = new Chart(intervention, {
                    type: 'bar',
                    data: {
                      labels: labels,
                      datasets: [{
                        label: labels,
                        data: montlyCost,
                        backgroundColor: backgroundColor,
                        borderColor: backgroundColor,
                        hoverOffset: 4,
                        borderWidth: 1,
                        order: 0,

                      },
                    ],

                    },
                    options: {
                        plugins: {
                            title: {
                              display: true,
                              text: 'Chart.js Line Chart - Multi Axis'
                            }
                        },
                        scales: {
                            x: {
                              position: 'bottom'
                            }
                        },

                    },
                });
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    }
})


