$( () => {
    if($('#carLocation').length > 0){
        $('#showLessInsurances').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessInsurances').style.display = 'none';
                document.getElementById('showMoreInsurances').style.display = 'block';
                document.getElementById('contentInsurances').style.display = 'none';
            }, 100)
        })

        $('#showMoreInsurances').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessInsurances').style.display = 'block';
                document.getElementById('showMoreInsurances').style.display = 'none';
                document.getElementById('contentInsurances').style.display = 'block';
            }, 100)
        })
    }

    if($('#historic').length > 0){
        if($('#showInsuranceHistory').is(':checked')){
            setTimeout( () => {
                document.getElementById('insuranceHistory').style.display = 'block';
            }, 100)
        }
    }

    $('#showInsuranceHistory').on('change', (event) => {
        event.preventDefault()
        if($('#showInsuranceHistory').is(':checked')){
            document.getElementById('insuranceHistory').style.display = 'block';
        } else{
            document.getElementById('insuranceHistory').style.display = 'none';
        }
    })

})
