const { forEach } = require("lodash");

$( () => {
    if($('#vehicleDasboard').length > 0){

        const vehicle = document.getElementById('inpuVehicle').value;

        $.ajax({
            url: `/fleets/vehicle/greenWayConsumption/${vehicle}`,
            type:'GET',
            contentType: 'JSON',
            success: function(success){

                const months = new Array();
                const monthlyCost = new Array();

                success[0].length < 1 ? document.getElementById('greenwayDasboard').style.display = "none" : ''

                success[0].forEach( (item, index) => {
                    monthlyCost[index] = item.monthly_cost;
                    months[index] = `${item.consuption_month.substring(0, 3)}/${item.consumption_year}`;
                });

                const ctx = document.getElementById('greenwayData');

                new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: months,
                    datasets: [{
                      label: 'Custo por mês',
                      data: monthlyCost,
                      backgroundColor: 'rgba(43, 132, 99, 0.3)',
                      borderColor: 'rgba(43, 132, 99, 1)',
                      borderWidth: 1
                    }]
                  },
                  options: {
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }
                });
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    }
})


