$( () => {
    if($('#vehicleContract').length > 0){
        $('#showLessContract').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessContract').style.display = 'none';
                document.getElementById('showMoreContract').style.display = 'block';
                document.getElementById('contentContract').style.display = 'none';
            }, 100)
        })

        $('#showMoreContract').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessContract').style.display = 'block';
                document.getElementById('showMoreContract').style.display = 'none';
                document.getElementById('contentContract').style.display = 'block';
            }, 100)
        })


        if($('#historic').length > 0){
            if($('#showContractHistory').is(':checked')){
                setTimeout( () => {
                    document.getElementById('contractHistoryContent').style.display = 'block';
                }, 100)
            }
        }

        $('#showContractHistory').on('change', (event) => {
            event.preventDefault()
            if($('#showContractHistory').is(':checked')){
                document.getElementById('contractHistoryContent').style.display = 'block';
            } else{
                document.getElementById('contractHistoryContent').style.display = 'none';
            }
        })
    }
})
