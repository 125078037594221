$( () => {

    if(($("#editEnterventionForm").length || $("#interventionForm").length ) > 0){
        $('#inputInterventionType, #inputVehicle, #inputInterventionDate').on('change', (event) => {
            event.preventDefault();

            const interventionTypeSelected = $('#inputInterventionType option:selected').text()
            const vehicle = $('#inputVehicle option:selected').val()
            const interventionDate = document.getElementById('inputInterventionDate')

            if(interventionTypeSelected == 'IPO' && vehicle != "Selecionar" && interventionDate.value){

                document.getElementById('inputSituation').value = 1

                $.ajax({
                    url: `/fleets/interventions/nextVehicleIPO`,
                    type: 'GET',
                    data: { vehicleID: vehicle, interventionDate: interventionDate.value },
                    enctype: 'JSON',
                    success: (data) => {
                        const nextInterventionDate = document.getElementById('inputNextInterventionDate');
                        nextInterventionDate.value = data
                    },
                    error: (xhr) => {
                        console.log(xhr);
                    }
                })
            }
        })
    }
})
