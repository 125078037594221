
$( () => {

    let imageID = "";
    let fileID = "";

    const handleRemovalFile = (id)=>{

        $.ajax({
            url: `/ilicits/occurrences/deleteFile/`,
            method: 'GET',
            data: {id: id },
            success: () => {
                location.reload()
            },
            error: function(xhr, status, error) {
                //
            }
        });
    }


    document.addEventListener('click', function(event) {
        if (event.target.matches('.ilicitimg')){
            this.getElementById('featured').style.display = 'block'
            var image = event.target.parentNode.querySelector('.ilicitimg');
            this.getElementById('featuredImage').src = image.src
            $('#featuredNumber').html(image.id)

            imageID = document.getElementById(`pictureID${image.id}`).value

            const imageName = document.getElementById(`pictureName${image.id}`).value
            document.getElementById('showFileName').innerHTML = imageName
        }
    })


    $('#deleteImage').on('click',  (event) => {
        event.preventDefault()
        $('#generalAlertModal').modal('show')
        document.getElementById('imageRemovalAlert').style.display = 'block'
        document.getElementById('fileRemovalAlert').style.display = 'none'

        document.getElementById('btnRemoveImage').style.display = 'block'
        document.getElementById('btnRemoveFile').style.display = 'none'
    })



    $('#btnRemoveImage').on('click', (event) => {
        event.preventDefault()
        handleRemovalFile(imageID)
    })


    $('#closeFeatured').on('click',  (event) => {
        event.preventDefault()
        document.getElementById('featured').style.display = 'none'
    })


    const fileItems = document.querySelectorAll('.fileitem');

    fileItems.forEach((fileItem) => {
        fileItem.addEventListener('click', (event) => {
            const fileIndex = event.currentTarget.id;
            const file = document.getElementById(`filePath${fileIndex}`).value
            const fileName = document.getElementById(`fileName${fileIndex}`).value
            var patt1 =/\.([0-9a-z]+)$/i;
            const fileNameExtension = file.match(patt1)[1];
            console.log(fileNameExtension);
            fileID = document.getElementById(`fileID${fileIndex}`).value

            $.ajax({
                url: `/ilicits/occurrences/getFile/`,
                method: 'GET',
                data: {url: file},
                dataType: 'html',
                success: function(response) {
                    var res = JSON.parse(response);
                    if(['pdf'].includes(fileNameExtension)) {
                        document.getElementById('cardpdf').style.display = 'block'
                        document.getElementById('cardpdf').style.backgroundColor = 'white'
                        const content = `<embed src="/ilicits/occurrences/viewFile/?url=${file}" type="application/pdf" id="viewFile"/>`

                        $('#loadPDF').html(content);
                        document.getElementById('loadPDFName').innerHTML = fileName.length > 100 ? `${fileName.slice(0, 100)}...` : fileName
                        document.getElementById('actionButtonsForPDF').style.display = 'block'
                    }
                    else if(['jpg','jpeg','gif','png'].includes(fileNameExtension)){
                        document.getElementById('cardpdf').style.display = 'block'
                        document.getElementById('cardpdf').style.backgroundColor = 'grey'
                        const content = `<img src="data:image/${fileNameExtension};base64, ${res['base64Content']}" style="width:100%" id="viewFile"/>`

                        $('#loadPDF').html(content);
                        document.getElementById('loadPDFName').innerHTML = fileName.length > 100 ? `${fileName.slice(0, 100)}...` : fileName
                        document.getElementById('actionButtonsForPDF').style.display = 'block'
                    }
                    else {
                        alert('ficheiro não suportado');
                    }

                },

                error: function(xhr, status, error) {
                    document.getElementById('permissionfile').style.display = 'block'
                    console.error('Erro na requisição!');
                }
            });
        });
    });

    //DELETE FILE
    $('#deleteFile').on('click', (event) => {
        event.preventDefault()
        $('#generalAlertModal').modal('show')
        document.getElementById('fileRemovalAlert').style.display = 'block'
        document.getElementById('imageRemovalAlert').style.display = 'none'

        document.getElementById('btnRemoveImage').style.display = 'none'
        document.getElementById('btnRemoveFile').style.display = 'block'
    })

    $('#btnRemoveFile').on('click', (event) => {
        event.preventDefault()
        alert('Arquivo removido com sucesso')
        handleRemovalFile(imageID)
    })


    $('#alertFileClosed').on('click', function (e) {
        e.preventDefault()
        document.getElementById('permissionfile').style.display = 'none'
    });


    $('#closepdf').on('click', (event) => {
        event.preventDefault()
        document.getElementById('cardpdf').style.display = 'none'
        document.getElementById('actionButtonsForPDF').style.display = 'none'
    })
})
