const { forEach } = require("lodash");

$( () => {
    if($('#vehicleDasboard').length > 0){

        const vehicle = document.getElementById('inpuVehicle').value;

        $.ajax({
            url: `/fleets/vehicle/fuelconsuptions/${vehicle}`,
            type:'GET',
            contentType: 'JSON',
            success: function(success){

                const months = new Array();
                const monthlyCost = new Array();

                success[0].length < 1 ? document.getElementById('fuelCardDasboard').style.display = "none" : ''
                success[0].forEach( (item, index) => {

                    monthlyCost[index] = item.net_value;
                    months[index] = item.month;
                });

                const ctx = document.getElementById('fuelConsuptions');


                new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: months,
                    datasets: [{
                      label: 'Valor líquido mensal',
                      data: monthlyCost,
                      backgroundColor: 'rgba(39, 123, 245, 0.3)',
                      borderColor: 'rgba(39, 123, 245, 1)',
                      borderWidth: 1
                    }]
                  },
                  options: {
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }
                });
            },
            error: function(xhr){
                console.log(xhr);
            }
        })
    }
})


