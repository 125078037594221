
    $(() => {
        if($('#loadinvoicesdata').length > 0){

            $("#send").click( function(){
                const feedback = document.getElementById('feedback');
                const alertbeforesend = document.getElementById('alertbeforesend');

                if(feedback.style.display === "block"){
                    feedback.style.display = "none"
                }

                $.get('get-token', function(response) {
                    const token = response[0].mimo_token

                    const data = {token: token,  sender: 'EASBie'}
                    $.ajax({
                        url: '/sms/sendinvoices',
                        type: "POST",
                        data: data,
                        dataType: 'json',

                        success: function(response){
                            console.log(response);

                            if(response[1] === 404){
                                if(feedback.style.display === "none"){
                                    if(feedback.classList.contains('alert-success'))
                                        feedback.classList.toggle('alert-warning')
                                    feedback.style.display = "block"
                                    $('#successeToregisted').html(response[0])
                                    $('#iconFeedback').html('<i class="fas fa-exclamation-triangle"></i>')
                                }
                            }
                        },

                        error: function(xhr, status, error) {

                            if(JSON.parse(xhr.responseText).message && feedback.style.display === "none"){
                                if(feedback.classList.contains('alert-success'))
                                    feedback.classList.toggle('alert-danger')
                                feedback.style.display = "block"
                                $('#successeToregisted').html(JSON.parse(xhr.responseText).message)
                                $('#iconFeedback').html('<i class="fas fa-times-circle"></i>')
                            }
                        /*     getError = 1
                            console.log(status);
                            console.log(error); */
                        }
                    });

                });
            })

        }
    });
