
$( () => {
    if($('#historic').length > 0){
        if($('#showHistoric').is(':checked')){
            setTimeout( () => {
                document.getElementById('historicContent').style.display = 'block';
            }, 100)
        }
    }

    $('#showHistoric').on('change', (event) => {
        event.preventDefault()
        if($('#showHistoric').is(':checked')){
            document.getElementById('historicContent').style.display = 'block';
        } else{
            document.getElementById('historicContent').style.display = 'none';
        }
    })
})

