$( () => {

    var vehiclesSelectd = new Array()
    var count = 0;

    if($("#VehicleInsuranceEditForm").length > 0)
    {
        const checkedBox = document.getElementById("inputOwnDamage");

        checkedBox.addEventListener('change', function() {
            if (checkedBox.checked == true)
            {
                document.getElementById("DamageCapital").style.display = 'block';
            }
            else if (checkedBox.checked == false)
            {
                document.getElementById("DamageCapital").style.display = 'none';
            }
        })
    }



    if($('#VehicleInsuranceCreateForm').length > 0){


         $('#inputInsuranceType').on('change', (event) => {
            event.preventDefault()
            const insuranceType = document.getElementById("inputInsuranceType").value;
/*             const formVehicleInsurance = document.getElementById("form-VehicleInsurence")
            const formLandTransport = document.getElementById("form-LandsTransport") */

            const insuranceCapital = document.getElementById("insuranceCapital")
            const ownDamageCapital = document.getElementById("ownDamageCapital")

            document.getElementById("vehicleSelectd").innerHTML = '';

            if(insuranceType == 'Seguro Automóvel'){
/*                 formVehicleInsurance ? formVehicleInsurance.style.display = 'block' : ''
                formLandTransport ? formLandTransport.style.display = 'none' : '' */
                insuranceCapital ? insuranceCapital.style.display = 'none' : ''
                ownDamageCapital ? ownDamageCapital.style.display = 'block' : ''
            }

            if(insuranceType == 'Transportes terrestres'){
/*                 formVehicleInsurance ? formVehicleInsurance.style.display = 'none' : ''
                formLandTransport ? formLandTransport.style.display = 'block' : '' */
                insuranceCapital ? insuranceCapital.style.display = 'block' : ''
                ownDamageCapital ? ownDamageCapital.style.display = 'none' : ''
             }
        })

        $('#multipleSelect').on('change', (event) => {
            event.preventDefault()

            const vehicle = document.getElementById("multipleSelect");

            const formVehicleInsurance = document.getElementById("formVehicleInsurance");
            //const inputInsuranceType = document.getElementById("inputInsuranceType").value;
            //const checkbodexs =  document.getElementById("apolicesCheckboxes");

            const noVehicle = document.getElementById("noVehicles");

            const registration = vehicle.options[vehicle.selectedIndex].innerHTML;

            let card = `<div class="col-md-12"><div id="cardRegister">${registration} <span class="remove" title="Remover" id="${registration}"><i class="fas fa-times-circle remove"></i></span><br></div></div>`
            let registrationcalue = `<div class="col-md-12"><hr class="mt-2"><input hidden class="form-control" type="text"  id="inputVehicleID" name="inputVehicleID[]" value="${vehicle.value}"/></div>`

            let content = "";

            content = card + registrationcalue + formVehicleInsurance.innerHTML;

            count = count + 1
            count > 0 ? noVehicle.style.display = 'none': ''

            const rowForm = `<div class="card newInsuranceForm mb-3" id="rowForm${registration}"><div class="card-body"><div class="row">${content}</div></div></div>`
            $('#vehicleSelectd').append(rowForm);

        })
    }



    document.addEventListener('click', function(event) {
        if (event.target.matches('.remove')) {
          var registration = event.target.parentNode.id;
          const noVehicle = document.getElementById("noVehicles");
          const vehicleSelectd = document.getElementById("vehicleSelectd");

          count = count - 1
          if(count < 1){
            noVehicle.style.display = 'block'
          }

          var indexToRemove = vehiclesSelectd.indexOf(noVehicle);
          if (indexToRemove !== -1) {
            vehiclesSelectd.splice(indexToRemove, 1);
          }

          document.getElementById(`rowForm${registration}`).remove()
        }
    });
})


