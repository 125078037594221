$( () => {
    if($('#carLocation').length > 0){
        $('#showLessSinisters').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessSinisters').style.display = 'none';
                document.getElementById('showMoreSinisters').style.display = 'block';
                document.getElementById('contentSinisters').style.display = 'none';
            }, 100)
        })

        $('#showMoreSinisters').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessSinisters').style.display = 'block';
                document.getElementById('showMoreSinisters').style.display = 'none';
                document.getElementById('contentSinisters').style.display = 'block';
            }, 100)
        })
    }


    if($('#historic').length > 0){
        if($('#showSinisterHistory').is(':checked')){
            setTimeout( () => {
                document.getElementById('sinisterHistory').style.display = 'block';
            }, 100)
        }
    }

    $('#showSinisterHistory').on('change', (event) => {
        event.preventDefault()
        if($('#showSinisterHistory').is(':checked')){
            document.getElementById('sinisterHistory').style.display = 'block';
        } else{
            document.getElementById('sinisterHistory').style.display = 'none';
        }
    })
})
