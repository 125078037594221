

let oldContent = "";
let lastUpdateDate = "";
const timeOut = 60000 * 5;

$( () => {

    const manualID = window.location.href.slice(-1)

    if($('#EditorForm').length > 0 ){
        $.ajax({
            url: `/manuals/show/${manualID}`,
            type: 'GET',
            success: (data) => {
                oldContent = data.manual_content
                lastUpdateDate = data.updated_at
            },
            error: (xhr) => {
                //console.log(xhr);
            }
        })


        $('#btnViewManual').on('click', (event) => {
            event.preventDefault()
            const input = `<input type="text" class="form-control" name="redirectToview" value="true">`
            $('#EditorForm').append(input)
            document.getElementById('EditorForm').submit();
        })
    }

})


export default function autoSave(content, visibilitychange){
    handleData(content, visibilitychange)
}


function handleData(content, visibilitychange){
    const wordDiff = content.length - oldContent.length

    const manualDesignation = document.getElementById('inputManualTitle');
    const manualVisualization = $('#inputVisualization option:selected').text();
    const manualDelegation = $('#inputDelegation option:selected').val();
    const manualcategory = document.getElementById('inputCategory');

    const baseURL = window.location.href
    const manualID = baseURL.slice(-1)

    const data = {
        'inputManualTitle': manualDesignation.value,
        'inputVisualization': manualVisualization,
        'inputDelegation': manualDelegation,
        'inputCategory': manualcategory.value,
        'inputManualContent': content,
        'id': manualID
    }

    if(wordDiff > 200 && oldContent.length > 0){
        oldContent = "";
        setTimeout( () => {
            handleAutosave(data, manualID)
        }, timeOut)
    }

    if(lastUpdateDate){
        const objectDate = new Date()
        const olDate = new Date(lastUpdateDate)
        const diffMS= olDate - objectDate
        let diffTime = Math.abs(diffMS / 60000)

        if(Math.trunc(diffTime) >= 5){
            handleAutosave(data, manualID)
            diffTime = 0;
        }
    }


    if(visibilitychange){
        if(data.inputManualContent.length != oldContent.length){
            setTimeout( () => {
                handleAutosave(data, manualID)
            }, 60000 * 2)
        }
    }
}


function handleAutosave(data, manualID){

    $.ajax({
        url: `/manuals/update/${manualID}`,
        type: 'POST',
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json',
        success: (success) => {

                if(success){
                    oldContent = success.manual_content
                    lastUpdateDate = success.updated_at
                    document.getElementById('lastUpdate').innerHTML = lastUpdateDate;

                    const lastUpdate = document.getElementById('contentUpdatedAt');
                    lastUpdate.classList.remove('text-secondary')
                    lastUpdate.classList.add('text-primary')

                    setTimeout( () => {
                        lastUpdate.classList.remove('text-primary')
                        lastUpdate.classList.add('text-secondary')
                    }, 5000)
                }
        },
        error: (xhr) => {
            //console.log(xhr);
        }
    })
}
