$( () => {

/*     document.addEventListener('click', function(event) {
        if (event.target.matches('.fa-edit')){
            const item = event.target.parentNode.querySelector('.fa-edit');
            document.getElementById('titlemodal').innerHTML = "Editar item"
            $('#detailsModal').modal('show')

            $('#detailsModal').modal('show')
        }
    }) */

    if($('#vehicleItems').length > 0){
        $('#showLessItems').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessItems').style.display = 'none';
                document.getElementById('showMoreItems').style.display = 'block';
                document.getElementById('displayVehicleItems').style.display = 'none';
            }, 100)
        })

        $('#showMoreItems').on('click', (event) => {
            event.preventDefault()
            setTimeout( () => {
                document.getElementById('showLessItems').style.display = 'block';
                document.getElementById('showMoreItems').style.display = 'none';
                document.getElementById('displayVehicleItems').style.display = 'block';
            }, 100)
        })
    }
})
