$( () => {

    if($('#vehicleForms').length > 0){
        let aux = 0;

        const vehiclesID = new Array();
        const arrayForms = new Array();
        const functions = require('./functions');

        function handleWithSubvalues(){
            const vehicleValues = document.querySelectorAll('[name="inputVehicleValue[]"]');
            const subtotalValue = document.getElementById('subTotalvalue')

            let total = 0;
            vehicleValues.forEach((input) => {
                if(Number(input.value)){
                    total += Number(input.value)
                }
            })

            subtotalValue.innerHTML = total
            const vehiclesSutotal = document.getElementById('inputvehicleSubTotal')
            vehiclesSutotal.value = total;
        }



        $('#inputVehicleDescription').on('change', (event) => {
            event.preventDefault();
            aux += 1

            const vehicleForm = new Array();

            const description = $('#inputVehicleDescription option:selected').text()
            const descriptionID = document.getElementById('inputVehicleDescription').value;

            const newDescripon = description.includes('Passageiro') ? 'Veículo Ligeiro' : 'Veículo Pesado/Máquina';

            $.ajax({
                url:`/budgets/getByDescriptiom`,
                type: 'GET',
                data: {description: newDescripon},
                enctype: 'json',
                success: (success) => {
                    if(success){

                        const template = document.getElementById('vehiclesTemplate').innerHTML;

                        const iconRemove = `<div class="col-md-12 mb-1 mt-2"><span class="float-right text-danger" title="Remover"><i class="fas fa-minus-circle removeVehicle" id="${aux}" style="cursor:pointer"></i></span></div>`

                        const inputDescription = `  <div class="col-md-12 mb-3">
                                                        <input type="text" class="form-control" value="${description}" disabled>
                                                        <input hidden type="number" name="inputVehicleID[]" value="${descriptionID}" class="form-control" id= "inputVehicleID${aux}">
                                                    </div>
                                                `

                        $('#vehicleTitle').innerHTML = inputDescription

                        const idIsIncluded = vehiclesID.includes(descriptionID);

                        idIsIncluded == false ? vehiclesID.push(descriptionID) : ''

                        idIsIncluded == true ? vehicleForm : vehicleForm.push(`<div class="row newWitnessForm mb-3" id="vehicleForm${aux}">${iconRemove + inputDescription + template}</div>`)
                        idIsIncluded == true ? arrayForms : arrayForms.push(`<div class="row newWitnessForm mb-3" id="vehicleForm${aux}">${iconRemove + inputDescription + template}</div>`)

                        $('#vehicleSelected').append(vehicleForm)

                        $(`#vehicleForm${aux} .col-md-4 #inputVehicleKm`).val('1')
                        $(`#vehicleForm${aux} .col-md-4 #inputUnitPrice`).val(success.subitem_amount)
                        $(`#vehicleForm${aux} .col-md-4 #unitPrice`).val(success.subitem_amount)
                        $(`#vehicleForm${aux} .col-md-4 #inputVehicleValue`).val(success.subitem_amount)
                        $(`#vehicleForm${aux} .col-md-4 #vehicleValue`).val(success.subitem_amount)
                        $(`#vehicleForm${aux} .col-md-12 #subitemVehicles`).val(Number(success.id))
                        $(`#vehicleForm${aux} .col-md-12 #inputVehiclesDescription`).val(description)

                        handleWithSubvalues();
                        functions.handleWithTotalValues();

                        $("#inputVehicleDescription").val("");

                        document.getElementById('NoVehicleSelected').style.display = 'none';

                    }
                },
                error: (xhr) => {

                }
            })
        })


        //UPDATE USED VEHICLES SUBTOTAL VALUES WHEN A FORM ROW IS REMOVED
        document.addEventListener('click', (event) => {
            if(event.target.matches('.removeVehicle')){
                const formItemID = event.target.id
                const descriptionIdToRemove = document.getElementById(`inputVehicleID${formItemID}`).value

                var indexToRemove = vehiclesID.indexOf(descriptionIdToRemove);
                if (indexToRemove !== -1) {
                    vehiclesID.splice(indexToRemove, 1);
                }

                document.getElementById(`vehicleForm${formItemID}`).remove()
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        })


        //UPDATE USED VEHICLE VALUES WHEN INPUT KMS IS CHANGING
        document.addEventListener('change', (event) => {
            if (event.target.matches('#inputVehicleKm')) {
                const entryKms = event.target.value;
                const rowID = event.target.parentElement.parentElement.id;

                const currentHourlyCost = $(`#${rowID} .col-md-4 #inputUnitPrice`).val()

                const setInputValue = currentHourlyCost * entryKms

                $(`#${rowID} .col-md-4 #inputVehicleValue`).val(setInputValue.toFixed(2))
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        });


        //UPDATE USED VEHICLES VALUE WHEN INPUT HOURLY COST IS CHANGING
        document.addEventListener('change', (event) => {
            if (event.target.matches('#inputUnitPrice')) {
                const entryHourlyCost = event.target.value;
                const rowID = event.target.parentElement.parentElement.id;

                const currentKms = $(`#vehicleForm${aux} .col-md-4 #inputVehicleKm`).val()
                const setInputValue = currentKms * entryHourlyCost

                $(`#${rowID} .col-md-4 #inputVehicleValue`).val(setInputValue.toFixed(2))
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        });
    }
})
