$( () => {
    if($('#allvehicles').length > 0){

        const canvas = document.getElementById('vehiclesKm');
        let chart = ""

        $.ajax({
            url: '/fleets/vehicle/kmDataPerVehicle',
            type:'GET',
            contentType: 'JSON',
            success: function(success){

               var data = [];
                var labels = [];

                success.forEach((element, idex) => {
                    labels[idex] = element.vehicle_registration;
                    data[idex] = element.deviations;
                });

                const ctx = canvas.getContext('2d');
                chart = new Chart(ctx, {
                    type: 'bar',
                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text: 'Desvio de Kms por Veículos',
                                position: 'top',
                                align: 'center',
                                font: {
                                   weight: 'bold'
                                },
                            },
                            responsive: true,
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    Max: 50
                                }
                            },
                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                        label: 'Desvio de Kms por Veículos  (%)',
                        backgroundColor: 'rgba(43, 132, 99, 0.2)',
                        borderColor: 'rgba(43, 132, 99, 1)',
                        data: data,
                        borderWidth: 1
                        }]
                    },
                });
            },
            error: function(xhr){
                console.log(xhr);
            }
        })

        canvas.onclick = (evt) => {
            const res = chart.getElementAtEvent(evt);
            if (res.length === 0) {
              return;
            }

            $.ajax({
                url: `/fleets/vehicle/vehicleByRegistration/`,
                type: 'GET',
                data: {registration : chart.data.labels[res[0]._index]},
                contentType: 'htnl',
                success: function (success) {
                    if(success){
                        window.location.replace(`/fleets/vehicle/view/${success.id}`);
                    } else{
                        //
                    }
                },
                error: function(xhr){
                    //console.log(xhr);
                }
            })
        };
    }
})
