$(() => {

    $('#modalTeamUsers').on('show.bs.modal', (event) => {
        var dataId = '';

        if (typeof $(event.relatedTarget).data('id') !== 'undefined') {
            dataId = $(event.relatedTarget).data('id');
        }

        $(event.target).find('#content .body').html("");
        $(event.target).find('#modal-spinner').removeClass('d-none');

        getUsersFromTeam(dataId, false).then((users) => {
            console.log('Users: ', users);
            $(event.target).find('#content .body').html(users);
            $(event.target).find('#modal-spinner').addClass('d-none');
        }).catch((err) => {
            $(event.target).find('#modal-spinner').addClass('d-none');
            alert(error);
        });

        // $currAjax = $.ajax({
        //     method: 'POST',
        //     url: '/teams/get-users',
        //     data: JSON.stringify({ id: dataId, raw: false }),
        //     contentType: 'json',
        //     success: (response) => {
        //         response = JSON.parse(response);

        //     },
        //     error: (jqXHR, status, error) => {
        //         $(event.target).find('#modal-spinner').addClass('d-none');
        //     },
        //     complete: () => {
        //         $(event.target).find('#modal-spinner').addClass('d-none');
        //     },
        // });
    });

    if($('#teams-colorpicker').find('input').val() !== '') {
        $('#teams-colorpicker').colorpicker({});
    } else {
        $('#teams-colorpicker').colorpicker({
            color: getRandomVibrantColor(20)
        });
    }
});
