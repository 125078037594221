$( () => {
    if($('#laborForms').length > 0){
        let aux = 0;

        const functions = require('./functions');

        const laborID = new Array();
        const arrayForms = new Array();

        function handleWithSubvalues(){
            const vehicleValues = document.querySelectorAll('[name="inputlaborValue[]"]');
            const subtotalValue = document.getElementById('subTotalLaborvalue')

            let total = 0;
            vehicleValues.forEach((input) => {
                if(Number(input.value)){
                    total += Number(input.value)
                }
            })

            subtotalValue.innerHTML = total
            const laborsSutotal = document.getElementById('inputLaborSubTotal')
            laborsSutotal.value = total
        }

        $('#inputLaborDescription').on('change', (event) => {
            event.preventDefault();
            aux += 1

            const laborForm = new Array();

            const description = $('#inputLaborDescription option:selected').text()

            $.ajax({
                url:`/budgets/getByDescriptiom`,
                type: 'GET',
                data: {description: description},
                enctype: 'json',
                success: (success) => {
                    if(success){

                        const descriptionID = document.getElementById('inputLaborDescription').value;

                        const template = document.getElementById('LaborTemplate').innerHTML;

                        const iconRemove = `<div class="col-md-12 mb-1 mt-2"><span class="float-right text-danger" title="Remover"><i class="fas fa-minus-circle removeLabor" id="${aux}" style="cursor:pointer"></i></span></div>`

                        const inputDescription = `  <div class="col-md-12 mb-3">
                                                        <input type="text" class="form-control" value="${description}" disabled>
                                                        <input hidden type="number" name="inputLaborID[]" value="${descriptionID}" class="form-control" id= "inputLaborID${aux}">
                                                    </div>
                                                `

                        $('#laborTitle').innerHTML = inputDescription

                        const idIsIncluded = laborID.includes(descriptionID);

                        idIsIncluded == false ? laborID.push(descriptionID) : ''

                        idIsIncluded == true ? laborForm : laborForm.push(`<div class="row newWitnessForm mb-3" id="laborForm${aux}">${iconRemove + inputDescription + template}</div>`)
                        idIsIncluded == true ? arrayForms : arrayForms.push(`<div class="row newWitnessForm mb-3" id="laborForm${aux}">${iconRemove + inputDescription + template}</div>`)

                        $('#laborSelected').append(laborForm)
                        $(`#laborForm${aux} .col-md-4 #inputlaborQuantity`).val('1')
                        $(`#laborForm${aux} .col-md-4 #inputHourlyCost`).val(success.subitem_amount)
                        $(`#laborForm${aux} .col-md-4 #inputlaborValue`).val(success.subitem_amount)
                        $(`#laborForm${aux} .col-md-4 #laborValue`).val(success.subitem_amount)
                        $(`#laborForm${aux} .col-md-12 #subitemLabor`).val(Number(success.id))

                        handleWithSubvalues();

                        $("#inputLaborDescription").val("");

                        document.getElementById('NoServiceSelected').style.display = 'none';
                    }
                },
                error: (xhr) => { }
            })
        })


        //UPDATE LABOR SUBTOTAL VALUES WHEN A FORM ROW IS REMOVED
        document.addEventListener('click', (event) => {
            if(event.target.matches('.removeLabor')){
                const formItemID = event.target.id
                const descriptionIdToRemove = document.getElementById(`inputLaborID${formItemID}`).value

                var indexToRemove = laborID.indexOf(descriptionIdToRemove);
                if (indexToRemove !== -1) {
                    laborID.splice(indexToRemove, 1);
                }

                document.getElementById(`laborForm${formItemID}`).remove()
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        })



        //UPDATE LABOR SUBVALUE WHEN INPUT LABOR QUANTITY IS CHANGING
        document.addEventListener('change', (event) => {
            if (event.target.matches('#inputlaborQuantity')) {
                const entryKms = event.target.value;
                const rowID = event.target.parentElement.parentElement.id;

                const currentHourlyCost = $(`#${rowID} .col-md-4 #inputHourlyCost`).val()

                const setInputValue = currentHourlyCost * entryKms

                $(`#${rowID} .col-md-4 #inputlaborValue`).val(setInputValue.toFixed(2))
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        });


        //UPDATE LABOR SUBVALUE WHEN INPUT LABOR QUANTITY IS CHANGING
/*         document.addEventListener('change', (event) => {
            if (event.target.matches('#inputHourlyCost')) {
                const entryHourlyCost = event.target.value;
                const rowID = event.target.parentElement.parentElement.id;

                const currentKms = $(`#laborForm${aux} .col-md-4 #inputlaborQuantity`).val()
                const setInputValue = currentKms * entryHourlyCost

                $(`#${rowID} .col-md-4 #inputlaborValue`).val(setInputValue.toFixed(2))
                handleWithSubvalues();
                functions.handleWithTotalValues();
            }
        }); */
    }
})
