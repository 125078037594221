$(() => {
    const fields = {
        text: $('#fieldText'),
        select: $('#fieldSelect'),
        textarea: $('#fieldTextarea'),
        file: $('#fieldFile'),
        checkbox: $('#fieldMultiChoice'),
        radio: $('#fieldUniqueChoice'),
    }

    if ($('#create-custom-form').length > 0) {

        /**
         * Block to add a field
         *
         * User clicks on the field type he wants to add and this block appends a clone of the template to
         */
        $('#add-field a').on('click', (event) => {
            const link = $(event.currentTarget);
            $('#field-container').append(fields[link.attr('data-type')].clone(true));
        });

        /**
         * Block to remove a field
         *
         * User clicks on the cross and field configuration is removed
         */
        $('[id^="field"] a#remove-field').on('click', (event) => {
            const link = $(event.currentTarget);
            link.parents('.field').remove();
        });

        /**
         * Block to insert options in select item list
         *
         * User writes as many options as he/she wants, block separates them by comma (,) and adds each to the select element.
         *
         * @todo Add ability to remove items.
         */
        $('[id^="field"] a#insert-option').on('click', (event) => {
            const link = $(event.currentTarget);
            const options = link.siblings('input').val().split(',')

            $(options).each((i, item) => {
                link.siblings('select').append('<option value="' + link.siblings('select').find('option').length + '">' + item.trim() + '</option>');
            });
            $(event.currentTarget).siblings('input').val('').trigger('focus');
        })

        /**
         * Block to capture the form submit and process the data
         */
        $('#create-form').on('submit', (event) => {
            event.preventDefault();

            const form = event.currentTarget;
            const jQForm = $(event.currentTarget);

            jQForm.find('.select-field').each((i, select) => {
                const input = $(select).parents('.field').find('input[name="options[]"]')
                console.log('Index: ', i);
                input.val('');
                $(select).find('option').each((i, item) => {
                    console.log('Options: ', $(select).find('option'))
                    console.log('Length: ', $(select).find('option').length)
                    console.log(i === $(select).find('option').length - 1)
                    if (i == $(select).find('option').length - 1) {
                        input.val(input.val() + $(item).text() + ':' + item.value)
                    } else {
                        input.val(input.val() + $(item).text() + ':' + item.value + ',')
                    }
                });
            });

            const formData = jQForm.serializeObject();

            $.ajax({
                method: 'POST',
                url: '/test',
                data: JSON.stringify(formData),
                beforeSend: function(request) {
                    request.setRequestHeader("Content-type", 'application/json');
                },
            });

            console.log(formData);
        });
    }

    if ($('#form-updateuser-details').length > 0) {
        // get the div element
        const myDiv = document.querySelector('#togglepassword');
        const myInput = document.getElementById("psw");
        // add a click event listener to the div
        myDiv.addEventListener('click', function() {
            // specify the action to take when the div is clicked
            myInput.type = (myInput.type === 'password') ? 'text' : 'password';
            myDiv.innerHTML = lang.get((myInput.type === 'password') ? 'showpassword' : 'hidepassword');
        });

        $('#psw').on('keypress', (event) => {
            var checksave = '';
            var savebutton = document.getElementById("savepassword");
            var letter = document.getElementById("letter");
            var capital = document.getElementById("capital");
            var number = document.getElementById("number");
            var length = document.getElementById("length");
            var special = document.getElementById("special");
            var charinputsequence = document.getElementById("charinputsequence");



            // When the user starts to type something inside the password field
            myInput.onkeyup = function() {
                // Validate lowercase letters
                var lowerCaseLetters = /[a-z]/g;
                if(myInput.value.match(lowerCaseLetters)) {
                    letter.classList.remove("text-danger");
                    letter.classList.add("text-success");
                } else {
                    letter.classList.remove("text-success");
                    letter.classList.add("text-danger");
                }

                // Validate capital letters
                var upperCaseLetters = /[A-Z]/g;
                if(myInput.value.match(upperCaseLetters)) {
                    capital.classList.remove("text-danger");
                    capital.classList.add("text-success");
                } else {
                    capital.classList.remove("text-success");
                    capital.classList.add("text-danger");
                }

                // Validate numbers
                var numbers = /[0-9]/g;
                if(myInput.value.match(numbers)) {
                    number.classList.remove("text-danger");
                    number.classList.add("text-success");
                } else {
                    number.classList.remove("text-success");
                    number.classList.add("text-danger");
                }

                // Validate length
                if(myInput.value.length >= 12) {
                    length.classList.remove("text-danger");
                    length.classList.add("text-success");
                } else {
                    length.classList.remove("text-success");
                    length.classList.add("text-danger");
                }

                //Validate special characters
                var specialcharacters = /(?=.*?[#?!@$%^&*-])/g;
                if(myInput.value.match(specialcharacters)) {
                    special.classList.remove("text-danger");
                    special.classList.add("text-success");
                } else {
                    special.classList.remove("text-success");
                    special.classList.add("text-danger");
                }

                // Validade sequential
                var charsequence = /(?=.*((11)|(22)|(33)|(44)|(55)|(66)|(77)|(88)|(99)|(00)))|(?=.*((123)|(234)|(345)|(456)|(567)|(678)|(789)|(901)|(012)))|(?=.*((AA)|(BB)|(CC)|(DD)|(EE)|(FF)|(GG)|(HH)|(II)|(JJ)|(KK)|(LL)|(MM)|(NN)|(OO)|(PP)|(QQ)|(RR)|(SS)|(TT)|(UU)|(VV)|(WW)|(XX)|(YY)|(ZZ)))|(?=.*((ABC)|(BCD)|(CDE)|(EFG)|(FGH)|(GHI)|(HIJ)|(IJK)|(JKL)|(KLM)|(LMN)|(MNO)|(NOP)|(OPQ)|(PQR)|(QRS)|(RST)|(STU)|(TUV)|(UVW)|(VWX)|(WXY)|(XYZ)|(YZA)))|(?=.*((qwe)|(wer)|(ert)|(rty)|(tyu)|(yui)|(uio)|(iop)|(asd)|(sdf)|(dfg)|(fgh)|(ghj)|(hjk)|(jkl)|(klç)|(lçº)|(zxc)|(xcv)|(cvb)|(bvn)|(bnm)|(nm,)|(m,.)|(,.-)|(147)|(258)|(369)))/gi;
                if(myInput.value.match(charsequence)) {
                    charinputsequence.classList.remove("text-success");
                    charinputsequence.classList.add("text-danger");
                } else {
                    charinputsequence.classList.remove("text-danger");
                    charinputsequence.classList.add("text-success");
                }

                checksave = letter.classList.value + capital.classList.value + number.classList.value + length.classList.value + special.classList.value + charinputsequence.classList.value;
                if(checksave.includes("text-danger")) {
                    savebutton.disabled = true;
                    savebutton.classList.remove("btn-primary");
                    savebutton.classList.add("btn-secondary");
                } else {
                    savebutton.disabled = false;
                    savebutton.classList.remove("btn-secondary");
                    savebutton.classList.add("btn-primary");
                }
            }
        });
    }
});
