export  function handleWithTotalValues(){
                                            const materialsSutotal = document.getElementById('inputMaterialSutotal')
                                            const vehiclesSutotal = document.getElementById('inputvehicleSubTotal')
                                            const laborsSutotal = document.getElementById('inputLaborSubTotal')
                                            const waterLossSutotal = document.getElementById('inputLosesSubTotal')

                                            const chargeValues = document.querySelectorAll('[name="inputChargeValue[]"]');


                                            let totalChargesValues = 0;
                                            chargeValues.forEach((input) => {
                                                if(Number(input.value)){
                                                    totalChargesValues += Number(input.value)
                                                }
                                            })

                                            const totalToInvoiced = Number(materialsSutotal.value) +
                                                                    Number(vehiclesSutotal.value) +
                                                                    Number(laborsSutotal.value) +
                                                                    Number(waterLossSutotal.value) +
                                                                    Number(totalChargesValues)

                                            document.getElementById('totalToInvoiced').innerHTML = totalToInvoiced.toFixed(2)
                                            document.getElementById('inputTotalToInvoiceValue').value = totalToInvoiced.toFixed(2)
}
