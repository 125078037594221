$( () => {
    $(document).on('click', '#assigned', function(event){
        event.preventDefault()

        const feedback = document.getElementById('feedback');
        const userID = $(this).closest('tr').find('#driverID').attr('value');
        const userName = $(this).closest('tr').find('#driverName').attr('value');
        const vehcle = $(this).closest('tr').find('#registration').attr('value');
        const vehcleID = $(this).closest('tr').find('.viewVehicle').attr('id');
        const buttonYes = document.getElementById('buttonYes')
        const buttonUnassing = document.getElementById('buttonUnassing')
        let unassign = false

        var alertcontent = `Tem certeza que pretende dessasociar o Veículo <strong> ${vehcle} </strong> do Condutor <strong> ${userName} </strong>?`
        $('#alertContent').html(alertcontent)
        $("#unassignModal").modal("show");

        $('#unassignModal').on('click', '#buttonYes', function(event){
            event.preventDefault()

            $.ajax({
                url: '/fleets/vehicleDriver/checkregistersKms',
                data: { userID: userID, vehcleID: vehcleID },
                type: 'GET',
                dataType: 'json',
                success: (response) => {

                    if(response[0] == 'success'){
                        $("#unassignModal").modal("hide");
                        feedback.style.display = "block"
                        $('#alertSuccess').html('Operação Realizada com sucesso')
                        $('#icon_alert').html('<i class="fas fa-check-circle"></i>')

                    } else{

                        $('#alertContent').html(response[0])
                        buttonYes.style.display = "none"
                        buttonUnassing.style.display = "block"

                    }

                },
                error: (xhr) => {
                    console.log(xhr);
                }
            })

/*
            $('#unassignModal').on('click', '#buttonNo', function(event){
                event.preventDefault()
                location.reload()
            }) */
        })

        $('#unassignModal').on('click', '#buttonUnassing', function(event){
            event.preventDefault()

            unassign  = true

            $.ajax({
                url: '/fleets/vehicleDriver/unassign',
                data: { userID: userID, vehcleID: vehcleID, unassign: unassign},
                type: 'GET',
                dataType: 'json',
                success: (newResponse) => {

                    if(newResponse == 'success'){
                        $("#unassignModal").modal("hide");
                        /* location.reload() */
                        newResponse = 'success'
                        feedback.style.display = "block"
                        $('#alertSuccess').html('Operação Realizada com sucesso')
                        $('#icon_alert').html('<i class="fas fa-check-circle"></i>')
                    }

                    else{
                        if(feedback.style.display === "none"){
                                if(feedback.classList.contains('alert-success')){
                                    feedback.classList.toggle('alert-danger')
                                }
                            $('#alertcontent').html(newResponse)
                            $('#icon_alert').html('<i class="fas fa-exclamation-triangle"></i>')
                        }
                    }
                },
                error: (xhr) => {
                    console.log(xhr);
                }
            })
        })

        $('#unassignModal').on('click', '#alertClose', function(event){
            event.preventDefault()
            location.reload()
        })

        $('#unassignModal').on('click', '#buttonNo', function(event){
            location.reload();
        })
    })
})
