$(()=>{

    const handleWithInputs = (interventionTypeSelected) => {

        //const situation = document.getElementById('interventionSituation')
        const startImobilizationDate = document.getElementById('startImobilizationDate')
        const endImobilizationDate = document.getElementById('endImobilizationDate')
        const interventionDate = document.getElementById('interventionDate')
        const contractSelect = document.getElementById('contractSelect')
        const tireInputs = document.getElementById('tireInputs')
        const nextInterventionDate = document.getElementById('nextInterventionDate')
        const nextInterventionKm = document.getElementById('nextInterventionKm')
        const sinisterSelect = document.getElementById('sinisterSelect');

        const labelIntervationDate = document.getElementById('labelIntervationDate')
        const labelInspectionDate = document.getElementById('labelInspectionDate')
        //const labelMaintenanceDate = document.getElementById('labelMaintenanceDate')
        const labelNextMaintenanceDate = document.getElementById('labelNextMaintenanceDate')
        const labelNextInspectionDate = document.getElementById('labelNextInspectionDate')

/*         if(interventionTypeSelected == '0'){
            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            labelInspectionDate ? labelInspectionDate.style.display = 'none' : null
        } */

        if(!(interventionTypeSelected == 'Pneus' || interventionTypeSelected == 'Pneu')){

            //labelInspectionDate ? labelInspectionDate.style.display = 'block' : ''
            //labelIntervationDate ? labelIntervationDate.style.display = 'none' : ''

            tireInputs ? tireInputs.style.display = 'none' : ''
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : ''
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : ''
        }

        if(interventionTypeSelected == 'Pneus' || interventionTypeSelected == 'Pneu'){

            if(labelNextMaintenanceDate){
                labelNextMaintenanceDate.style.display = 'none';
            }

            tireInputs ? tireInputs.style.display = 'block' : ''
            labelIntervationDate ? labelIntervationDate.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'block' : ''

            labelInspectionDate ? labelInspectionDate.style.display = 'none' : ''

        }

        if(interventionTypeSelected.toLowerCase() == 'manutenção programada'){

            nextInterventionDate ? nextInterventionDate.style.display = 'block' : ''
            nextInterventionKm ? nextInterventionKm.style.display = 'block' : ''
            //labelMaintenanceDate ? labelMaintenanceDate.style.display = 'block' : ''
            labelNextMaintenanceDate ? labelNextMaintenanceDate.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'block' : ''

            labelIntervationDate ? labelIntervationDate.style.display = 'block' : ''
            labelInspectionDate ? labelInspectionDate.style.display = 'none' : ''
            labelNextInspectionDate ? labelNextInspectionDate.style.display = 'none' : ''

        } else{
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : ''
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : ''
        }


        if((interventionTypeSelected == 'Inspeção' || interventionTypeSelected == 'IPO')){

            nextInterventionDate ? nextInterventionDate.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'block' : ''
            labelInspectionDate ? labelInspectionDate.style.display = 'block' : ''
            labelNextInspectionDate ? labelNextInspectionDate.style.display = 'block' : ''

            labelNextMaintenanceDate ? labelNextMaintenanceDate.style.display = 'none' : ''
            labelIntervationDate ? labelIntervationDate.style.display = 'none' : ''
            //labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : ''
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : ''
            startImobilizationDate ? startImobilizationDate.style.display = 'none' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'none' : null

        }

        if(interventionTypeSelected == 'Recondicionamento'){

            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            interventionDate ? interventionDate.style.display = 'block' : null
            //labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : null
        }

        if( interventionTypeSelected == 'A Definir' ||
            interventionTypeSelected == 'Acerto IUC' ||
            interventionTypeSelected == 'Acerto Kms' ||
            interventionTypeSelected == 'Recondicionamento'
        ){

            nextInterventionKm ? nextInterventionKm.style.display = 'none' : null
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : null
            // ? situation.style.display = 'none' : ''
            startImobilizationDate ? startImobilizationDate.style.display = 'none' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'none' : null
        }

        if( interventionTypeSelected == 'Acerto IUC' || interventionTypeSelected == 'Acerto Kms'){

            contractSelect ? contractSelect.style.display = 'block' : ''
            interventionDate ? interventionDate.style.display = 'none' : null

        } else {
            contractSelect ? contractSelect.style.display = 'none' : ''
        }

        if(interventionTypeSelected == 'Manutenção corretiva' || interventionTypeSelected == 'Reparação sinistros'){
            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            interventionDate ? interventionDate.style.display = 'block' : null
            startImobilizationDate ? startImobilizationDate.style.display = 'block' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'block' : null

            labelInspectionDate ? labelInspectionDate.style.display = 'none' : null
            //labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : null
        }

        if(interventionTypeSelected == 'Reparação de sinistros' && sinisterSelect){

            sinisterSelect.style.display = 'block'
            labelIntervationDate ? labelIntervationDate.style.display = 'block' : null
            startImobilizationDate ? startImobilizationDate.style.display = 'block' : null
            endImobilizationDate ? endImobilizationDate.style.display = 'block' : null

            //labelMaintenanceDate ? labelMaintenanceDate.style.display = 'none' : null
            interventionDate ? interventionDate.style.display = 'block' : null
            nextInterventionKm ? nextInterventionKm.style.display = 'none' : null
            nextInterventionDate ? nextInterventionDate.style.display = 'none' : null


        } else{
            sinisterSelect ? sinisterSelect.style.display = 'none' : ''
        }
    }


    if(($("#editEnterventionForm").length || $("#interventionForm").length ) > 0){
            const interventionTypeSelected = $('#inputInterventionType option:selected').text()
            handleWithInputs(interventionTypeSelected);

            const inputInterventionDate = document.getElementById('inputInterventionDate')
            const date = inputInterventionDate ? inputInterventionDate.value : null
            const situation = document.getElementById('inputSituation')
            situation && date ? situation.value = 1 : null

        $('#inputInterventionType').on('change', (event) => {
            event.preventDefault();
            const interventionTypeSelected = $('#inputInterventionType option:selected').text()
            handleWithInputs(interventionTypeSelected);
        })

        $('#inputInterventionDate').on('change', (event) => {
            event.preventDefault();
            const inputInterventionDate = document.getElementById('inputInterventionDate').value
            const situation = document.getElementById('inputSituation')
            situation && inputInterventionDate ? situation.value = 1 : null
        })
    }

    if(($("#listAllInterventions").length ) > 0){

        $('#pendingInterventions-Datatable').DataTable({
            autoWidth: true,
            responsive: true,
            // order: [[3, 'asc']],
            searching: true,
            language: {
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                },
                sProcessing: loadingHTML,
                sEmptyTable: "No Records",
                url: "/config/dataTables/lang/" + window.lang + ".json"
            } ,
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: {
                url: document.URL,
                data: function data(d) {
                    d.older = $('#older').prop('checked');
                },
                createdRow: function(row, data, dataIndex) {

                    $(row).find('td:first').addClass('actions-column');
                }
            }
        });

        $('#older').on('change', function () {
            $('#pendingInterventions-Datatable').DataTable().draw();
        });

    }
});
