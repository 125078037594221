$( () => {

    if($('#formsIncluded').length > 0){

        const invoiceFormDisplay = document.getElementById('invoiceForm');
        //HANDLE WITH INVOICES INPUT
        if(invoiceFormDisplay.style.display == 'block'){

            const indvoiceNumber = document.getElementById('inputIndvoiceNumber').value
            const invoiceValue = document.getElementById('inputInvoiceValue').value
            const invoiceDate = document.getElementById('inputInvoiceDate').value

            $(document).on("click", '#checkErrors', function(event) {
                event.preventDefault()

                var indvoiceNumberErrorMessage =  document.getElementById('indvoiceNumberErrorMessage');
                var invoiceValueErrorMessage =  document.getElementById('indvoiceNumberErrorMessage');
                var invoiceDateErrorMessage =  document.getElementById('indvoiceNumberErrorMessage');

                indvoiceNumber.value == '' ? indvoiceNumberErrorMessage.innerHTML = 'Este campo é obrigatório' : billingErrorMessage.innerHTML = ''
                invoiceValue.value == '' ? invoiceValueErrorMessage.innerHTML = 'Este campo é obrigatório' : billingErrorMessage.innerHTML = ''
                invoiceDate.value == '' ? invoiceDateErrorMessage.innerHTML = 'Este campo é obrigatório' : billingErrorMessage.innerHTML = ''

                if(indvoiceNumber == '' || invoiceValue == '' || invoiceDate == ''){
                    document.getElementById('checkErrors').style.display = 'block'
                    document.getElementById('validate').style.display = 'none'
                } else{
                    document.getElementById('checkErrors').style.display = 'none'
                    document.getElementById('validate').style.display = 'block'
                }
            })
        }



/*
        $(document).on("change", '#billingStatus', function(event) {
            event.preventDefault()

            billingStatus.value == '' ? billingErrorMessage.innerHTML = 'Este campo é obrigatório' : billingErrorMessage.innerHTML = ''
            billingStatus.innerHTML == 'Fatura liquidada' ? settlementDate.style = 'block' : settlementDate.style = 'none'

            if(billingStatus == '' || billingErrorMessage == '' || settlementDate == ''){
                document.getElementById('checkErrors').style.display = 'block'
                document.getElementById('validate').style.display = 'none'
            } else{
                document.getElementById('checkErrors').style.display = 'none'
                document.getElementById('validate').style.display = 'block'
            }
        })
 */



        $(document).on("blur", '#inputInvoiceNumber, #inputInvoiceValue, #inputInvoiceDate', function(event) {
            event.preventDefault()
            const invoiceNumber = document.getElementById('inputInvoiceNumber').value
            const invoiceValue = document.getElementById('inputInvoiceValue').value
            const invoiceDate = document.getElementById('inputInvoiceDate').value

            if(invoiceNumber == '' || invoiceValue == '' || invoiceDate == ''){
                document.getElementById('checkErrors').style.display = 'block'
                document.getElementById('validate').style.display = 'none'
            } else{
                document.getElementById('checkErrors').style.display = 'none'
                document.getElementById('validate').style.display = 'block'
            }
        })


        $(document).on("blur", '#inputInvoiceNumber', function(event) {
            event.preventDefault()
            const invoiceNumber = document.getElementById('inputInvoiceNumber').value
            var invoiceNumberErrorMessage =  document.getElementById('invoiceNumberErrorMessage');
            invoiceNumber == '' ? invoiceNumberErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceNumberErrorMessage.innerHTML = ''
        })

        $(document).on("blur", '#inputInvoiceValue', function(event) {
            event.preventDefault()
            const invoiceValue = document.getElementById('inputInvoiceValue').value
            var invoiceValueErrorMessage =  document.getElementById('invoiceValueErrorMessage');
            invoiceValue == '' ? invoiceValueErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceValueErrorMessage.innerHTML = ''
        })

        $(document).on("blur", '#inputInvoiceDate', function(event) {
            event.preventDefault()
            const invoiceDate = document.getElementById('inputInvoiceDate').value
            var invoiceDateErrorMessage =  document.getElementById('invoiceDateErrorMessage');
            invoiceDate == '' ? invoiceDateErrorMessage.innerHTML = 'Este campo é obrigatório' : invoiceDateErrorMessage.innerHTML = ''
        })
    }
})
