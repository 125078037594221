$(()=>{

    if($('#form_address_create').length > 0){
        //Delegation input
        $('#addressInputDelegation').on('change', (event) => {
            console.log("data: "+ event.target.value);
            $.ajax({
                url: '/addresses/get-parishes',
                data: {'delegation': event.target.value},
                method: 'POST',
                dataType: 'json',
                success: (response) => {
                    console.log(response.id);
                    if(response.status == 200){
                        $('#addressInputParish').find('option').remove();
                        response.parishes.forEach((parish)=>{
                            //console.log("id= " + id);
                            console.log("parish" + parish);
                            $('#addressInputParish').append(`<option value="${parish.code}">${parish.designation}</option>`);
                        })
                    }
                }
            });
        });
        //Parishes input
        $('#addressInputParish').on('change', (event) => {
            console.log("data: "+ event.target.value);
            $.ajax({
                url: '/addresses/get-localities',
                data: {'parish': event.target.value},
                method: 'POST',
                dataType: 'json',
                success: (response) => {
                    if(response.status == 200){
                        $('#addressInputLocalities').find('option').remove();
                        response.localities.forEach((locality)=>{
                            $('#addressInputLocalities').append(`<option value="${locality.LLLL}">${locality.designation}</option>`);
                        })
                    }
                }
            });
        });

    }
    //Check input

});
