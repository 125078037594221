$( () => {
    if($('#fineForm').length > 0){

/*         drivers = document.getElementById('inputDriver');

        $('#inputVehicle').on('change', (event) => {
            event.preventDefault();

            vehicle = document.getElementById('inputVehicle');
            fineDate = document.getElementById('inputFineDate');

            $.ajax({
                url: '/fleets/vehicleDriver/assignedOnDate',

                data: { vehicleID: vehicle.value, givenDate: fineDate.value },
                type: 'GET',
                contentType: 'json',
                success: (data) => {
                    var options = ""
                    data.forEach( (item) => {
                        options += '<option value="' + item.id + '">' + item.name + '</option>';
                    })

                    $('#inputDriverID').html(options);
                },
                error: (xhr) => {
                    //console.log(xhr);
                }
            })
        }) */

    }

/*     $(document).on('click', '#unsolved', function(event){
        event.preventDefault();
        const toogle = $(this).closest('tr').find('.edit').attr('id');

        $.ajax({
            url: `/fleets/fines/solve/${toogle}`,
            type: 'POST',
            success: (data) => {
                location.reload()
            },
            error: (xhr) => {
                console.log(xhr);
            }
        })
    }) */
})
