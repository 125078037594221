
 $( () => {

    if($('#costCenterForm').length > 0){

        const costCenterID = document.getElementById('inputCostCenterID')

        function hanlleRequest(delegation, accountable){
            $.ajax({
                url: `/delegations/usersDelegation/${delegation}`,
                type: 'GET',
                data: { costCenterID: costCenterID ? costCenterID.value : 0},
                success:function(data){

                    if(data[0].length > 0){
                        user = "";
                        data[0].forEach( (item) => {

                            let iscurrentAccountables = false
                            if(data[1]){
                                data[1].forEach( (element) => {
                                    element.id == item.id ? iscurrentAccountables = true : null
                                })
                            }

                            user += `
                                        <div class="form-check costom-inputs">
                                            <input class="form-check-input" type="checkbox" value="${ item.id }" name="inputAccountables[]" id="inputAccountables" ${iscurrentAccountables ? 'checked' : ''}>
                                            <label class="form-check-label" for="defaultCheck1">${ item.name }</label>
                                        </div>
                                    `
                        })
                        accountable.innerHTML = user;
                    } else{
                        accountable.innerHTML = '';
                    }
                },
                error: function(xhr){
                    console.log(xhr);
                }
            })
        }


        const delegation = document.getElementById('inputDelegation').value;
        const costCenterAccountable = document.getElementById('costCenterAccountable');

        if(delegation){
            hanlleRequest(delegation, costCenterAccountable);
        }


        $("#inputDelegation").on('change', function(event){
            event.preventDefault()
            const inputDelegation = document.getElementById('inputDelegation').value;
            const accountable = document.getElementById('costCenterAccountable');
            hanlleRequest(inputDelegation, accountable);
        })
    }

    $("#closeAlertCostCenter").on('click', (event) => {
        event.preventDefault()
        location.reload()
    })
 })
