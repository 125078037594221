$( () => {
    if($("#fuelCardForm").length > 0)
    {
        $("#inputDelegation").on('change', function(event){
            event.preventDefault()

            const delegation = document.getElementById('inputDelegation');

            $.ajax({
                url: `/fleets/vehicle/delegationVehicles/${delegation.value}`,
                type: 'GET',
                success:function(data){
                    var options = '<option value "" selected>Selecionar</option>';
                    if(data.length > 0){

                        options += '<option value = "Não aplicável">Não aplicável</option>';
                        data.forEach( (item) => {
                            options += '<option value="' + item.id + '">' + item.vehicle_registration + '</option>';
                        })

                        $('#inputVehicle').html(options);
                    }
                    $('#inputTeam').html(options);
                },
                error: function(xhr){
                    //console.log(xhr);
                }
            })
        })


        $('#inputVehicle').on('change', (event) => {
            event.preventDefault();
            const vehiclesID = document.getElementById('inputVehicle').value;

            if(vehiclesID != Number(0)){

                document.getElementById('costCenterSelect').style.display = 'block';

                $.ajax({
                    url: `/fleets/vehicle/vehicleCostCenters/${vehiclesID}`,
                    type: 'GET',
                    success:function(data){
                        var options = '<option value = "" selected>Sem Centro de Custos</option>';
                        data.forEach( (item) => {
                            options += '<option value="' + item.id + '" selected>' + item.cost_center_designation + '</option>';
                        })
                        $('#inputCardCostCenter').html(options);
                    },
                    error: function(xhr){
                        //console.log(xhr);
                    }
                })
            } else{
                document.getElementById('costCenterSelect').style.display = 'none';
            }
        })
    }
})
