import autoSave from '../manuals/autosave';
import *  as draft from '../manuals/drafts'

$(() => {

    window.mce = [];

    if ($('textarea.text-editor').length > 0) {
        let editor_config = {
            path_absolute : "/",
            selector: "textarea.text-editor",
            language: 'pt_PT',
            menubar:false,
            statusbar: false,
            plugins: [
                "advlist autolink lists link charmap print preview hr anchor pagebreak",
                "searchreplace visualblocks visualchars code",
                "insertdatetime nonbreaking table directionality",
                "emoticons template paste textpattern"
            ],
            // media image
            toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link", // image media",
            relative_urls: false,
            file_browser_callback : function(field_name, url, type, win) {
                var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
                var y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;

                // var cmsURL = editor_config.path_absolute + 'laravel-filemanager?field_name=' + field_name;
                // if (type == 'image') {
                //     cmsURL = cmsURL + "&type=Images";
                // } else {
                //     cmsURL = cmsURL + "&type=Files";
                // }

                // tinyMCE.activeEditor.windowManager.open({
                //     file : cmsURL,
                //     title : 'Filemanager',
                //     width : x * 0.8,
                //     height : y * 0.8,
                //     resizable : "yes",
                //     close_previous : "no"
                // });
            },
            id: $('textarea.text-editor').data('mce-id')
        };

        tinyMCE.init(editor_config).then((mce) => {
            window.mce = mce;
        });



        // $('#formNextStatus').on('sumbit', () => {
        //     $('#inputComment').val(quill.root.innerHTML);
        // });
    }





    /**
     * DOCUMENT EDITOR
     */
    if ($('textarea.document-editor').length > 0) {
          /* tinymce.init({
            selector: 'textarea.document-editor',
            path_absolute : "/",
            language: 'pt_PT',
            menubar:true,
            statusbar: true,
            automatic_uploads: true,
            plugins: [
            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
            'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
            'table', 'emoticons', 'help'
            ],
            toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
            'forecolor backcolor emoticons | help',
            menu: {
            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
            },
            menubar: 'favs file edit view insert format tools table help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
            setup: function(editor) {
                var inp = $('<input id="tinymce-uploader" type="file" name="pic" accept="image/*" style="display:none">');
                $(editor.getElement()).parent().append(inp);

                inp.on("change",function(){
                    var input = inp.get(0);
                    var file = input.files[0];
                    var fr = new FileReader();
                    fr.onload = function() {
                        var img = new Image();
                        img.src = fr.result;
                        editor.insertContent('<img src="'+img.src+'"/>');
                        inp.val('');
                    }
                    fr.readAsDataURL(file);
                });

                editor.addButton( 'imageupload', {
                    text:"IMAGE",
                    icon: false,
                    onclick: function(e) {
                        inp.trigger('click');
                    }
                });
            }
        }).then((mce) => {
            window.mce = mce;
        }); */

        tinymce.init({
            selector: 'textarea.document-editor',
            path_absolute: "/",
            language: 'pt_PT',
            menubar: true,
            statusbar: true,
            automatic_uploads: true,
            plugins: [
                'advlist autolink link image lists charmap preview anchor pagebreak',
                'searchreplace wordcount visualblocks code fullscreen insertdatetime media',
                'table emoticons help'
            ],
            toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
                     'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                     'forecolor backcolor emoticons | help',
            menu: {
                favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
            },
            menubar: 'favs file edit view insert format tools table help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
            setup: function(editor) {
                var inp = $('<input id="tinymce-uploader" type="file" name="pic" accept="image/*>');
                $(editor.getElement()).parent().append(inp);

                inp.on("change", function() {
                    var input = inp.get(0);
                    var file = input.files;
                    var fr = new FileReader();
                    fr.onload = function() {
                        var img = new Image();
                        img.src = fr.result;
                        editor.insertContent('<img src="' + img.src + '"/>');
                        inp.val('');
                    };
                    fr.readAsDataURL(file);
                });

                editor.ui.registry.addButton('imageupload', {
                    text: 'IMAGE',
                    icon: false,
                    onAction: function() {
                        inp.trigger('click');
                    }
                });

                editor.on('input', function (e){
                    e.preventDefault();

                    const currentBudget = window.location.href
                    const content = editor.getContent()

                    if(currentBudget.includes('/manuals/create')){
                        draft.saveDraft(content)
                    } else{
                        autoSave(content, false)
                    }
                });

                document.addEventListener('visibilitychange', function (e){
                    const content = editor.getContent()
                    autoSave(content, true)
                });
            }
        });

    }
});


