$( () => {

    $('#datatable-Validations').DataTable({
     
        responsive: true,
        order: [[1, 'asc']],
        searching: true,
        language: {
            paginate: {
                previous: '<i class="fa fa-angle-left"></i>',
                next: '<i class="fa fa-angle-right"></i>'
            },
            sProcessing: loadingHTML,
            sEmptyTable: "No Records",
            url: "/config/dataTables/lang/" + window.lang + ".json"
        } ,
        autoWidth: false,
        processing: true,
        serverSide: true,
        ajax: {
            url: '/timereport/validations',
            createdRow: function(row, data, dataIndex) {
             
                $(row).find('td:first').addClass('actions-column');
            }
        }
    });
})
